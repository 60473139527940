import * as customerRequestsStore from '@store/customerRequests/customerRequestsStore';
import * as DictionariesStore from '@store/admin/dictionaries/DictionariesStore';
import * as customerApplicationsStore from '@store/customerApplications/customerApplicationsStore';
import * as customerApplicationsHistoryStore from '@store/customerApplications/customerApplicationsHistoryStore';
import * as notificationsSettingsStore from '@store/admin/notificationsSettingsStore';
import * as customerRequestsHistoryStore from '@store/customerRequests/customerRequestsHistoryStore';
import * as currencyStore from '@store/currencyStore';
import * as airwaybillsStore from '@store/airwaybills/airwaybillsStore';
import * as houseAirwaybillsStore from '@store/airwaybills/houseAirwaybillsStore';
import * as airlinesStore from '@store/airlinesStore';
import * as settingsStore from '@store/settingsStore';
import * as languagesStore from '@store/languagesStore';
import * as contactsStore from '@store/contactsStore';
import * as accountsStore from '@store/accountsStore';
import * as tariffsStore from '@store/tariffsStore';
import * as routesStore from '@store/routesStore';
import * as organizationsStore from '@store/organizationsStore';
import * as organizationSettingsStore from '@store/organizationSettingsStore';
import * as salesStore from '@store/salesStore';
import * as specialHandlingCodesStore from '@store/cargoes/specialHandlingCodesStore';
import * as natureOfCargoStore from '@store/cargoes/natureOfCargoStore';
import * as customerApplicationEventLog from '@store/customerApplications/customerApplicationEventLogStore';
import * as bookingConfirmationStore from '@store/customerApplications/bookingConfirmationStore';
import * as reviewStore from '@store/reviewStore';
import * as demoBookingServiceStore from '@store/demoBooking/demoBookingServiceStore';
import * as staffStore from '@store/demoBooking/staffStore';
import * as timeslotStore from '@store/demoBooking/timeslotStore';
import * as countryStore from '@store/countryStore';
import * as addressDataStore from '@store/addressDataStore';
import * as senderEmailStore from '@store/senderEmails';
import * as trackAndTrace from '@store/trackAndTrace';
import { localSettingsReducer } from '@store/localSettings';

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

export const reducers = {
	customerRequests: customerRequestsStore.reducer,
	routes: routesStore.reducer,
	dictionaries: DictionariesStore.reducer,
	customerApplications: customerApplicationsStore.reducer,
	customerApplicationsHistory: customerApplicationsHistoryStore.reducer,
	customerRequestsHistory: customerRequestsHistoryStore.reducer,
	currencies: currencyStore.reducer,
	airwaybills: airwaybillsStore.reducer,
	houseAirwaybills: houseAirwaybillsStore.reducer,
	airlines: airlinesStore.reducer,
	settings: settingsStore.reducer,
	languages: languagesStore.reducer,
	contacts: contactsStore.reducer,
	accounts: accountsStore.reducer,
	tariffs: tariffsStore.reducer,
	organizations: organizationsStore.reducer,
	organizationSettingsStore: organizationSettingsStore.reducer,
	sales: salesStore.reducer,
	specialHandlingCodes: specialHandlingCodesStore.reducer,
	nateruOfCargo: natureOfCargoStore.reducer,
	customerApplicationEventLog: customerApplicationEventLog.reducer,
	bookingConfirmation: bookingConfirmationStore.reducer,
	notificationsSettings: notificationsSettingsStore.reducer,
	latestReviews: reviewStore.reducer,
	demoBookingService: demoBookingServiceStore.reducer,
	staffMap: staffStore.reducer,
	timeslotMap: timeslotStore.reducer,
	allCountries: countryStore.reducer,
	addressData: addressDataStore.reducer,
	senderEmails: senderEmailStore.reducer,
	tracking: trackAndTrace.reducer,
    localSettings: localSettingsReducer
};

const getRootReducer = (browserHistory) => {
	const rootReducerFn = combineReducers({
		...reducers,
		router: connectRouter(browserHistory),
	});

	return (state: Parameters<typeof rootReducerFn>[0], action: Parameters<typeof rootReducerFn>[1]) =>
		rootReducerFn(action.type === 'RESET' ? undefined : state, action);
};

export default getRootReducer;