import React from 'react';
import { useTranslation } from 'react-i18next';

import { SaleStatus } from '@models/customerRequests/customerRequestModels';

type Props = {
    saleStatus: SaleStatus;
};

const SalesStatusComponent = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    switch (props.saleStatus) {
        case SaleStatus.Available:
            return <span>{t('requestSearchResults.available')}</span>;
        case SaleStatus.UponRequest:
            return <span>{t('requestSearchResults.uponRequest')}</span>;
        case SaleStatus.Embargo:
            return <span>{t('requestSearchResults.embargo')}</span>;
        case SaleStatus.Restrictions:
            return <span>{t('requestSearchResults.restrictions')}</span>;
        case SaleStatus.AdHoc:
            return <span>{t('requestSearchResults.adHoc')}</span>;
        case SaleStatus.Promo:
            return <span>{t('requestSearchResults.promo')}</span>;
        case SaleStatus.NotAvailable:
            return <span>{t('requestSearchResults.notAvailable')}</span>;
        default:
            return <span>{t('requestSearchResults.uponRequest')}</span>;
    }
};

export default SalesStatusComponent;
