import { CacheNames, Settings } from '@root/constants/localSettings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SettingsType = { page: number; amount: number };
export type SettingsState = { [key in Settings]: SettingsType };

const defaultSettings: SettingsType = { page: 0, amount: 5 };
const initialState: SettingsState = {
    applications: defaultSettings,
    requests: defaultSettings,
};

const memorizedState: SettingsState = JSON.parse(
    window.localStorage.getItem(CacheNames.SETTINGS) || 'false',
);

if (!memorizedState) window.localStorage.setItem(CacheNames.SETTINGS, JSON.stringify(initialState));

const updateMemorySettings = (key: Settings, value: SettingsType) => {
    const settings: SettingsState =
        JSON.parse(window.localStorage.getItem(CacheNames.SETTINGS)) || initialState;
    window.localStorage.setItem(CacheNames.SETTINGS, JSON.stringify({ ...settings, [key]: value }));
};

export const localSettingsSlice = createSlice({
    name: 'localSettings',
    initialState: memorizedState || initialState,
    reducers: {
        updateSettingsPage(state, { payload }: PayloadAction<{ key: Settings; page: number }>) {
            const settings: SettingsType = { ...state[payload.key], page: payload.page };
            updateMemorySettings(payload.key, settings);
            state[payload.key] = settings;
        },
        updateSettingsAmount(state, { payload }: PayloadAction<{ key: Settings; amount: number }>) {
            const settings: SettingsType = { ...state[payload.key], amount: payload.amount };
            updateMemorySettings(payload.key, settings);
            state[payload.key] = settings;
        },
    },
});

export const {updateSettingsAmount, updateSettingsPage} = localSettingsSlice.actions;
export const localSettingsReducer = localSettingsSlice.reducer;