import React, { useEffect } from 'react';
import { FormikModel } from '@models/customerRequests/formModels';
import { onClickParseXlsx, recalculateCargoDetails } from '@helpers/customerSearchFormHelpers';
import { CargoDimensionDto, SpecialHandlingCode } from '@models/customerRequests/customerRequestModels';
import { Field, FieldProps, FormikProps } from 'formik';
import { Container, Row, Col, FormGroup, Input, Label, ButtonGroup, Button } from 'reactstrap';
import { GetSpecialHandlingCodeDto, GetNatureOfCargoDto } from '@models/cargoes';
import { useTranslation } from 'react-i18next';
import CargoDimensions from './CargoDimensions';
import FieldError from './FieldError';
import nameof from 'ts-nameof.macro';
import styles from './CargoDetails.module.scss';
import i18n from '@i18n';
import clsx from 'clsx';
import StaticSelect from '@components/select/StaticSelect';
import Switcher from '@components/Switcher';
import AppModal from '@components/AppModal';
import DimensionsService from '@services/DimensionsService';
import MultiSwitch from '@components/MultiSwitch';

const { PUBLIC_URL } = process.env;

type CargoDetailsProps = {
	formikProps: FormikProps<FormikModel>;
	selectedCodes: SpecialHandlingCode[];
	onChangeCodes: (codes: SpecialHandlingCode[]) => void;
	availableCodes: SpecialHandlingCode[];
	availableNatures: GetNatureOfCargoDto[];
};

const CargoDetails = (props: CargoDetailsProps): JSX.Element => {
	const { formikProps } = props;

	const fileRef = React.createRef<HTMLInputElement>();

	const [isParseModalOpen, toggleParseModalOpen] = React.useState(false);

	const [dimensionsAsText, setDimensionsAsText] = React.useState('');
	const [unitOfMeasure, setUnitOfMeasure] = React.useState('cm');

	const { t } = useTranslation();

	useEffect(() => {
		recalculateCargoDetails(formikProps);
	}, [formikProps.values.hasCargoDimensions, formikProps.values.cargoDimensions]);

	const uploadExcel = (): void => {
		if (fileRef.current !== null && fileRef.current.click) {
			fileRef.current.click();
		}
	};

	const parseDimensions = async (e): Promise<void> => {
		e.preventDefault();

		const rows = await new DimensionsService().parse(dimensionsAsText);
		const dtos: CargoDimensionDto[] = rows.data
			.map((r) => ({...r, amount: r.pieces}));

		if (unitOfMeasure === 'mm') {
			dtos.forEach((r) => {
				r.height = (r.height || 0) / 10;
				r.length = (r.length || 0) / 10;
				r.width = (r.width || 0) / 10;
			});
		} else if (unitOfMeasure === 'm') {
			dtos.forEach((r) => {
				r.height = (r.height || 0) * 100;
				r.length = (r.length || 0) * 100;
				r.width = (r.width || 0) * 100;
			});
		}

		dtos.forEach((r) => {
			r.height = Math.round(r.height);
			r.length = Math.round(r.length);
			r.width =  Math.round(r.width);
		});

		if (rows?.data?.length) {
			formikProps.setFieldValue(
				nameof.full<FormikModel>(x => x.cargoDimensions), dtos, true);
			formikProps.setFieldValue(
				nameof.full<FormikModel>(x => x.hasCargoDimensions), true);
			formikProps.setFieldValue(
				nameof.full<FormikModel>(x => x.useTotalWeight),
				dtos.reduce((r, x) => r + x.weight, 0) <= 0
			);
		}

		// clear field
		setDimensionsAsText('');
		// close modal
		toggleParseModalOpen(false);
	};

	return (
		<>
			<h4 className={clsx(styles.title, 'mb-3', 'mt-5')}>{t('request.cargoDetails')}</h4>

			<Container className="pl-0 pr-0">
				<Row form className="mb-2">
					<Col md={6} className={styles.alignBottom}>
						<div className={styles.selectControl}>
							<>
								<Label>{t('request.natureOfCargo')}</Label>
								<StaticSelect<GetNatureOfCargoDto>
									name={nameof.full<FormikModel>((x) => x.natureOfCargo)}
									isMulti={false}
									value={props.availableNatures.filter((x) => x.id === formikProps.values.natureOfCargo)}  // To-Do :: set correct Initial value
									onChange={(v) => {
										// сохраняем значение в текущем поле
										formikProps.setFieldValue(
											nameof.full<FormikModel>((x) => x.natureOfCargo),
											(v && v.length ? v[0].id : undefined)
										);
										// заполняем Goods description
										formikProps.setFieldValue(
											nameof.full<FormikModel>((x) => x.goodsDescription),
											(v && v.length ? v[0].description : undefined)
										);
										// обновляем SHC
										if (v && v.length && v[0].shc_Id) {
											props.onChangeCodes([{ id: v[0].shc_Id, name: v[0].shc_Name} ]);
										}
									}}
									filterPropertyName={'name'}
									selectedLabel={(x) => <>{x?.name}</>}
									availableOptions={props.availableNatures}
									placeholder={t('request.natureOfCargo_placeholder')}
									formatOptionLabel={(option) => (
											<div style={{ display: "flex", justifyContent: 'space-between' }}>
												<div style={{ flex: 1 }}>
													{option.name}
												</div>
												<div style={{ paddingLeft: "10px", color: "#ccc", flexBasis: "40px" }}>
													{option.shc_Name ? option.shc_Name.split('-')[0].trim() : ""}
												</div>
											</div>
									  	)}
								/>
								<FieldError
									error={formikProps.errors['natureOfCargo'] as string}
									isTouched={formikProps.touched['natureOfCargo'] as boolean}
								/>
							</>
						</div>
					</Col>
					<Col className={styles.alignBottom}>
						<Field name={nameof.full<FormikModel>((x) => x.goodsDescription)}>
							{({ field, form }: FieldProps): JSX.Element => (
								<div className='inputControl'>
									<Label for={field.name}>{t('request.goodsDescription')}</Label>
									<Input
										placeholder={t('request.goodsDescription_placeholder')}
										className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
										{...field}
										value={field.value || ''}
									/>
									<FieldError
										error={form.errors[field.name] as string}
										isTouched={form.touched[field.name] as boolean}
									/>
								</div>
							)}
						</Field>
					</Col>
				</Row>

				<Row form className="mb-2">
					<Col md={6} className={styles.alignBottom}>
						<div className={styles.selectControl}>
							<>
								<Label>{t('shc.shc')}</Label>
								<StaticSelect<GetSpecialHandlingCodeDto>
									name={nameof.full<FormikModel>((x) => x.specialHandlingCodeIds)}
									isMulti
									value={props.selectedCodes}
									onChange={props.onChangeCodes}
									filterPropertyName={'name'}
									optionLabel={(x) => <>{x?.name}</>}
									selectedLabel={(x) => <>{x?.name.split('-')[0].trim()}</>}
									placeholder={t('shc.chooseShc')}
									availableOptions={props.availableCodes}
									checkboxes={true}
								/>
								<FieldError
									error={formikProps.errors['specialHandlingCodeIds'] as string}
									isTouched={formikProps.touched['specialHandlingCodeIds'] as boolean}
								/>
							</>
						</div>
					</Col>
					<Col className={styles.alignBottom}>
						<Field name={nameof.full<FormikModel>((x) => x.totalWeight)}>
							{({ field, form }: FieldProps): JSX.Element => (
								<div className='inputControl'>
									<Label for={field.name}>{t('request.cargoWeightTotal')}</Label>
									<Input
										placeholder={t('units.kg')}
										disabled={formikProps.values.hasCargoDimensions && !formikProps.values.useTotalWeight}
										className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
										{...field}
										value={field.value || ''}
									/>
									<FieldError
										error={form.errors[field.name] as string}
										isTouched={form.touched[field.name] as boolean}
									/>
								</div>
							)}
						</Field>
					</Col>
					<Col className={styles.alignBottom}>
						<Field name={nameof.full<FormikModel>((x) => x.totalVolume)}>
							{({ field, form }: FieldProps): JSX.Element => (
								<div className='inputControl'>
									<Label for={field.name}>{t('request.cargoVolume')}</Label>
									<Input
										placeholder={t('units.m3')}
										disabled={formikProps.values.hasCargoDimensions}
										className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
										{...field}
										value={field.value || ''}
									/>
									<FieldError
										error={form.errors[field.name] as string}
										isTouched={form.touched[field.name] as boolean}
									/>
								</div>
							)}
						</Field>
					</Col>
					<Col className={styles.alignBottom}>
						<Field name={nameof.full<FormikModel>((x) => x.totalPlaces)}>
							{({ field, form }: FieldProps): JSX.Element => (
								<div className='inputControl'>
									<Label for={field.name}>{t('request.packagePlaces')}</Label>
									<Input
										placeholder=''
										disabled={formikProps.values.hasCargoDimensions}
										className={form.errors[field.name] && form.touched[field.name] ? 'inputControlError' : ''}
										{...field}
										value={field.value || ''}
									/>
									<FieldError
										error={form.errors[field.name] as string}
										isTouched={form.touched[field.name] as boolean}
									/>
								</div>
							)}
						</Field>
					</Col>
				</Row>

				<Row className="my-3">
					<Col>
						<div className="form-check">
							<Field name={nameof.full<FormikModel>((x) => x.hasCargoDimensions)}>
								{({ field, form }: FieldProps): JSX.Element => (
									<Input
										{...field}
										id={nameof.full<FormikModel>((x) => x.hasCargoDimensions)}
										type="checkbox"
										placeholder="totalPlaces"
										checked={field.value}
										onChange={(x) => {
											field.onChange(x);
											if (x.target.checked) {
												const cargoDimensions =
													form.values.cargoDimensions != null
														? [...form.values.cargoDimensions]
														: [];
												if (cargoDimensions.length === 0) {
													cargoDimensions.push({ amount: 1, length: 0, weight: 0, width: 0, height: 0} as CargoDimensionDto);
													form.setFieldValue(
														nameof.full<FormikModel>((x) => x.cargoDimensions),
														cargoDimensions
													);
												}
											}
										}}
									/>
								)}
							</Field>
							<Label for={nameof.full<FormikModel>((x) => x.hasCargoDimensions)}>
								{t('request.dimOfEach')}
							</Label>
						</div>
					</Col>
				</Row>
				{formikProps.values.hasCargoDimensions && (
					<>
						<Row className="mb-3">
							<Col>
								<Field name={nameof.full<FormikModel>((x) => x.useTotalWeight)}>
									{({ field, form }: FieldProps): JSX.Element => (
										<ButtonGroup size="lg">
											<Button
												color={field.value === true ? 'primary' : 'secondary'}
												style={{fontSize: '1.1rem'}}
												onClick={() => {
													form.setFieldValue(
														nameof.full<FormikModel>((x) => x.cargoDimensions),
														(formikProps.values.cargoDimensions || []).map((d) => {
															return {...d, weight: 0};
														})
													);
													form.setFieldValue(field.name, true);
												}}
											>{t("request.useTotalWeight")}</Button>
											<Button
												color={field.value !== true ? 'primary' : 'secondary'}
												style={{fontSize: '1.1rem'}}
												onClick={() => {
													form.setFieldValue(field.name, false);
													recalculateCargoDetails(formikProps, true);
												}}
											>{t("request.useWeightPerPiece")}</Button>
										</ButtonGroup>
									)}
								</Field>
							</Col>
							<Col>
								<div className={styles.turnableCell}>
									<Label for={nameof.full<FormikModel>((x) => x.isTurnable)}>
										{t('request.isTurnable')}
									</Label>
									<Field name={nameof.full<FormikModel>((x) => x.isTurnable)}>
										{({ field }: FieldProps): JSX.Element => (
											<Switcher
												{...field}
												id={nameof.full<FormikModel>((x) => x.isTurnable)}
												checked={field.value}
												onTextRes="admin.settings.yes"
												offTextRes="admin.settings.no"
											/>
										)}
									</Field>
								</div>
								<div className={styles.turnableCell}>
									<Label for={nameof.full<FormikModel>((x) => x.isCargoStacked)}>
										{t('request.isCargoStacked')}
									</Label>
									<Field name={nameof.full<FormikModel>((x) => x.isCargoStacked)}>
										{({ field }: FieldProps): JSX.Element => (
											<Switcher
												{...field}
												id={nameof.full<FormikModel>((x) => x.isCargoStacked)}
												checked={field.value}
												onTextRes="admin.settings.yes"
												offTextRes="admin.settings.no"
											/>
										)}
									</Field>
								</div>
							</Col>
						</Row>
						<Field name="file">
							{({ field, form }: FieldProps): JSX.Element => (
								<Input
									name={field.name}
									type="file"
									className="d-none"
									innerRef={fileRef}
									onChange={(e) => onClickParseXlsx(e, form)}
								/>
							)}
						</Field>
						<Row>
							<Col md={4} sm={4}>
								<a href="#" className="btn p-0"
									onClick={(e) => {
										e.preventDefault();
										toggleParseModalOpen(true);
									}}
								>
									<i className="icon-file-excel" style={{ color: 'orange' }} /> {t('request.parseDims')}
								</a>
							</Col>
							<Col md={4} sm={4}>
								<a href="#" className="btn p-0"
									onClick={(e) => {
										e.preventDefault();
										uploadExcel();
									}}
								>
									<i className="icon-file-excel" style={{ color: 'green' }} /> {t('request.excel')}
								</a>
							</Col>
							<Col md={4} sm={4}>
								<a href={`${PUBLIC_URL}/dimensions_${i18n.language}.xlsx`} download="dimensions.xlsx">
									<i className="icon-document" /> {t('request.excelTemplate')}
								</a>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup className="mt-3">
									<CargoDimensions formikProps={formikProps} />
								</FormGroup>
							</Col>
						</Row>
					</>
				)}

				<AppModal
					isOpen={isParseModalOpen}
					onClickCloseButton={() => toggleParseModalOpen(false)}
					body={
						<>
							<textarea rows={10} style={{width: '100%'}}
								value={dimensionsAsText}
								onChange={e => setDimensionsAsText(e.target.value)} />
							<br/>
							<MultiSwitch
								onChange={(v: string) => setUnitOfMeasure(v)}
								defaultValue={unitOfMeasure}
								options={[
									{ labelRes: 'units.full.m',  value: 'm'  },
									{ labelRes: 'units.full.cm', value: 'cm' },
									{ labelRes: 'units.full.mm', value: 'mm' }
								]}
							/>
						</>
					}
					footer={
						<div className="text-right w-100">
							<a className="btn btn-primary" onClick={parseDimensions}>
								{t('request.parse')}
							</a>
							{'   '}
							<a className="btn btn-secondary"
								onClick={(e) => {
									e.preventDefault();
									toggleParseModalOpen(false);
									setUnitOfMeasure('cm');
								}}
							>
								{t('admin.settings.cancel')}
							</a>
						</div>
					}
				/>
			</Container>
		</>
	);
};

export default CargoDetails;