import { CargoTypeDto } from '@models/customerRequests/customerRequestModels';
import { HttpError } from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { GetSpecialHandlingCodeDto, GetNatureOfCargoDto } from '@models/cargoes';

export default class CargoesService {

	public getTypes = async (): Promise<CargoTypeDto[] & HttpError> => {
		return (await AuthorizedHttpClient.get<CargoTypeDto[] & HttpError>('/api/cargoes/all')).data;
	};

	public getCodes = async (nav: StandardNavigation) => {
		return await AuthorizedHttpClient.post<IPagingWrapper<GetSpecialHandlingCodeDto>>('/api/cargoes/codes', nav);
	};

	public getNatureOfCargoList = async (nav: StandardNavigation) => {
		return await AuthorizedHttpClient.post<IPagingWrapper<GetNatureOfCargoDto>>('/api/cargoes/list_nature_of_cargo', nav);
	};

}