import { createAppThunk } from '@helpers/reduxHelpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerApplicationsService from '@services/CustomerApplicationsService';
import { BookingConfirmationDto } from '@models/customerApplications/customerApplicationModels';
import CustomerRequestService from '@services/CustomerRequestsService';
import SalesService from '@services/SalesService';
import ContactsService from '@services/ContactsService';
import { ContactDto } from '@models/contacts';
import { GetSaleDto } from '@models/customerRequests/customerRequestModels';

export type State = {
	isFetching: boolean;
	bookingConfirmation: BookingConfirmationDto;
	sale: GetSaleDto;
};

export const loadBookingConfirmation = createAppThunk(
	'bookingConfirmation/loadBookingConfirmationByApplicationId',
	async (arg: { customerApplicationId: string }) => {
		const { data } = await new CustomerApplicationsService()
			.getBookingConfirmation(arg.customerApplicationId);

		return data;
	}
);

export const fetchSale = createAsyncThunk(
	'bookingConfirmation/fetchSale',
	async (arg: { saleId: string, currencyCode: string }) => {
		const response = await new SalesService().getSale(arg.saleId, arg.currencyCode);
		return response.data;
	},
);

const slice = createSlice({
	name: 'bookingConfirmation',
	initialState: {
		isFetching: false,
		bookingConfirmation: null,
		sale: null,
	} as State,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loadBookingConfirmation.pending, (state) => {
			state.isFetching = true;
		});
		builder.addCase(loadBookingConfirmation.rejected, (state) => {
			state.isFetching = false;
			state.bookingConfirmation = null;
		});
		builder.addCase(loadBookingConfirmation.fulfilled, (state, action) => {
			state.isFetching = false;
			state.bookingConfirmation = action.payload;
		});

		builder.addCase(fetchSale.fulfilled, (state, action) => {
			state.sale = action.payload;
		});

		builder.addCase(fetchSale.rejected, (state) => {
			state.sale = null;
		});
	},
});

export const { reducer } = slice;
export const { actions } = slice;
