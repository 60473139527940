import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SessionManager from '@root/SessionManager';
import { toast } from 'react-toastify';
import AccreditationListEditor from '@scenes/accreditationList/components/AccreditationListEditor';
import { useAppDispatch, useAppSelector } from '@root/store';
import { GetContacts } from '@models/contacts';
import { fetchContactPager, fetchInteractionTypes } from '@store/contactsStore';
import { fetchOrganization } from '@store/organizationsStore';
import { actions } from '@store/organizationSettingsStore';
import { AccreditationModel, ContactInfoDto, UpdateAccreditationList } from '@models/organizations/accreditationModels';
import { Loader } from '@components/index';
import { useHistory } from 'react-router';
import OrganizationsService from '@services/OrganizationsService';
import { OrganizationAccessLevel, OrganizationDto } from '@models/organizations';
import { HttpError } from '@models/shared';
import nameof from 'ts-nameof.macro';
import { interactionTypesOrderedArray } from '@helpers/accreditationHelpers';
import { InteractionType } from '@models/contacts/InteractionType';

const CountdownTimer = (props: { onFinish: () => void }) => {
    const [timeLeft, setTimeLeft] = useState(30);
    const { t } = useTranslation();
    const { organization } = useAppSelector(state => state.organizations);

    useEffect(() => {
        let isCurrent = true;
        const checkOrganization = () => {
            new OrganizationsService().fetchOne(organization.id)
                .then(item => {
                    const data = item.data as OrganizationDto;
                    if (data.isSynchronized == true) {
                        setTimeLeft(0);
                    }
                })
        };
        const intervalId = setInterval(() => {
            if (timeLeft > 0) {
                checkOrganization();
                setTimeLeft(timeLeft - 1);
            } else {
                clearInterval(intervalId);
                props.onFinish();
            }
        }, 1000);

        return () => {
            isCurrent = false;
            clearInterval(intervalId);
        };

    }, [timeLeft]);

    return <Row>
        <Col>{t('accreditation.timeoutSplash', { timeLeft })}</Col>
    </Row>;
};
const AccreditationListStatus = (props: { accessLevel: OrganizationAccessLevel }): JSX.Element => {
    const { accessLevel } = props;
    const history = useHistory();
    const { t } = useTranslation();

    if (!accessLevel)
        return null;

    return (
        <Row>
            <Col>
                <Row className={'mb-4 mt-4 text-center'}>
                    <Col style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                        {t(`accreditation.${OrganizationAccessLevel[accessLevel]}`)}
                    </Col>
                </Row>
                {accessLevel === OrganizationAccessLevel.Granted && (
                    <Row className={'text-center'}>
                        <Col style={{ whiteSpace: 'pre-line' }}>
                            <button
                                className={'btn btn-primary'}
                                onClick={() => {
                                    history.push('/request');
                                }}
                            >
                                {t('start')}
                            </button>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};

const AccreditationListPage = (): JSX.Element => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const {
        contacts: contactsPager,
        isContactPagerFetching,
        interactionTypes
    } = useAppSelector((x) => x.contacts);

    const { organization, isOrganizationFetching } = useAppSelector((x) => x.organizations);
    const { isOrganizationSettingsFetching, organizationSettings } = useAppSelector((x) => x.organizationSettingsStore);
    const [isUpdating, setUpdating] = useState(false);
    const [interactionMap, setInteractionMap] = useState<Record<string, string[]>>({});
    const [contactItems, setContacts] = useState<ContactInfoDto[]>([]);
    const [accreditationData, setAccreditationData] = useState<AccreditationModel>(null);
    const [showCountDown, setShowCountDown] = useState(false);

    const { items: contacts } = contactsPager;

    const getDefaultInteractionMap = () => {
        const defaultInteractionMap: Record<string, string[]> = {};

        for (const interactionType of interactionTypesOrderedArray) {
            const typeKey = InteractionType[interactionType];
            defaultInteractionMap[typeKey] = [];
        }

        return defaultInteractionMap;
    };

    useEffect(() => {
        const map = getDefaultInteractionMap();

        if (contacts == null || contacts.length === 0) {
            setInteractionMap(map);
            return;
        }

        for (const contact of contacts) {
            for (const interactionType of contact.interactionTypes) {
                const typeKey = InteractionType[interactionType.value];
                map[typeKey] = [...(map[typeKey] || []).filter(id => id != ''), contact.id];
            }
        }

        setInteractionMap(map);

        const contactItems: ContactInfoDto[] = (contacts || [])
            .map(c => ({
                ...c,
                interactionTypes: c.interactionTypes.map(t => t.value)
            }));
        setContacts(contactItems);
    }, [contacts]);

    useEffect(() => {
        if (contacts != null || isContactPagerFetching == true) {
            return;
        }

        const q = new GetContacts();
        q.organizationId = SessionManager.user.organizationId;
        q.pagingFilter.pageSize = 100;

        dispatch(fetchContactPager(q));
    }, [contacts, isContactPagerFetching]);

    useEffect(() => {
        if (organization != null || isOrganizationFetching == true) {
            return;
        }
        dispatch(fetchOrganization({ organizationId: SessionManager.user.organizationId }));
    }, [organization, isOrganizationFetching]);

    useEffect(() => {
        if (interactionTypes?.length == 0) {
            dispatch(fetchInteractionTypes());
        }
    }, [interactionTypes]);

    useEffect(() => {
        setAccreditationData({
            organization: organization,
            interactionMap: interactionMap,
            contacts: contactItems
        });

        if (organization == null || showCountDown == null || organizationSettings != null && organizationSettings.accessLevel != OrganizationAccessLevel.NotProvided) {
            return;
        }

        if (!organization.isSynchronized) {
            setShowCountDown(true);
        }
    }, [organization, interactionMap, contactItems]);

    const onSubmitEdit = async (model: AccreditationModel): Promise<HttpError | null> => {
        setUpdating(true);
        const contacts: ContactInfoDto[] = [];
        const newContacts: Record<string, ContactInfoDto> = {};
        for (const interactionType of Object.keys(model.interactionMap)) {
            const contactIds = model.interactionMap[interactionType].filter(id => id != '');
            for (const id of contactIds) {
                const contactInfoDto = contacts.find(c => c.id == id);
                const contact = contactInfoDto || { ...model.contacts.find(c => c.id == id), interactionTypes: [] };
                if (contact.id.startsWith('new-contact-') && newContacts[contact.id] == null) {
                    newContacts[contact.id] = contact;
                }

                contact.interactionTypes.push(InteractionType[interactionType]);
                if (contactInfoDto == null) {
                    contacts.push(contact);
                }
            }
        }

        for (const id in newContacts) {
            newContacts[id].id = '00000000-0000-0000-0000-000000000000';
        }

        const value: UpdateAccreditationList = {
            organization: model.organization,
            contacts: contacts
        };

        try {
            const response = await new OrganizationsService().updateAccreditationList(value, model.attachedFiles);
            setUpdating(false);
            dispatch(actions.setAccreditationListFilled());
            await dispatch(fetchOrganization({ organizationId: organization.id }));
            return response.data;
        } catch (e) {
            setUpdating(false);
            const errors = e as unknown as HttpError;
            if (errors.fields) {
                const contactErrors = errors.fields.find(e => e.name.toLowerCase() == nameof<UpdateAccreditationList>(x => x.contacts));
                if (contactErrors != null) {
                    const updatedContactErrors = {
                        ...contactErrors,
                        messages: contactErrors.messages
                            .map(m => [
                                'ShouldContainPaymentsInteractionType',
                                'ShouldContainDirectorInteractionType',
                                'ShouldContainCommercialInteractionType',
                                'ShouldContainOperationalInteractionType'].indexOf(m) > - 1
                                ? t(`accreditation.contacts.errors.${m}`)
                                : m)
                    };

                    errors.fields = errors.fields.map(error => error == contactErrors ? updatedContactErrors : error);
                }
            }
            return errors;
        }
    };

    if (isOrganizationFetching || isContactPagerFetching || isOrganizationSettingsFetching || accreditationData == null) {
        return <Loader />;
    }

    if (showCountDown) {
        return <CountdownTimer onFinish={() => {
            dispatch(fetchOrganization({ organizationId: SessionManager.user.organizationId }));
            setShowCountDown(null);
        }} />;
    }

    if (organizationSettings?.accessLevel !== OrganizationAccessLevel.NotProvided) {
        return (
            <AccreditationListStatus accessLevel={organizationSettings?.accessLevel} />
        );
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <Row className="mb-3">
                            <Col>
                                <h2>{t('accreditation.message')}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AccreditationListEditor
                                    data={accreditationData}
                                    organizationUniqueNumber={organization?.uniqueNumber}
                                    interactionTypes={interactionTypes}
                                    onSubmit={onSubmitEdit}
                                    onSuccess={() => {
                                        toast.success(t('changesSaved'));
                                    }}
                                    onError={(msg) => toast.error(msg)}
                                    isUpdating={isUpdating}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AccreditationListPage;