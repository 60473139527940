import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {
	CreateSettingsEntry,
	ServiceStateEntry,
	SettingsEntryDto,
	UpdateSettingsEntry
} from '@models/admin/settingsModels';
import { HttpError } from '@models/shared';
import { Dictionary } from "@core/models";
import HttpClient from "@core/HttpClient";
import { RawServiceModePeriod } from "@models/settings/ServiceModePeriod";

export default class SettingsService {
	public get = () => {
		return AuthorizedHttpClient.get<SettingsEntryDto[]>(`api/settings`);
	};

	public create = (model: CreateSettingsEntry) => {
		return AuthorizedHttpClient.post<string & HttpError>(`api/settings`, model);
	};

	public update = (model: UpdateSettingsEntry) => {
		return AuthorizedHttpClient.put<HttpError>(`api/settings`, model);
	};

	public delete = (id: string) => {
		return AuthorizedHttpClient.delete(`api/settings/${id}`);
	};

	public getLocalizedSettingsJson = (languageIsoCode: string) => {
		return HttpClient.get<Dictionary<string>>(`api/settings/strings/${languageIsoCode}.json`);
	};

	public getNeutralSettingsJson = () => {
		return HttpClient.get<Dictionary<string>>(`api/settings/strings/neutral.json`);
	};

	public getServiceModeState = () => {
		return AuthorizedHttpClient.get<RawServiceModePeriod>('api/Settings/ServiceMode');
	}

	public updateServiceModeState = (model: ServiceStateEntry) => {
		const data = {start: model.startServiceMode?.toJSON(), finish: model.endServiceMode?.toJSON()};

		return AuthorizedHttpClient.post<RawServiceModePeriod>(`api/Settings/ServiceMode`, data);
	}
}