import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, FormGroup, Alert, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import loginStyle from './Login.module.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import { ChangePasswordResponse } from '@models/accounts/accountModels';
import { withTranslation, WithTranslation } from 'react-i18next';
import HttpClient from "@core/HttpClient";
import {tryGetIdentityErrors} from "@helpers/stringHelpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

type State = {
    success: boolean;
    submitting: boolean;
    passwordNewShown: boolean;
    passworConfirmShown: boolean;
    errors: string[];
};

type ResetPasswordRouteParams = {
    requestId: string;
    email: string;
};

type Props = RouteComponentProps<ResetPasswordRouteParams> & WithTranslation;

class ResetPassword extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            success: false,
            submitting: false,
            passwordNewShown: false,
            passworConfirmShown: false,
            errors: [],
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async submitForm(event: any): Promise<void> {
        this.setState({
            success: false,
            submitting: true,
            errors: [],
        });

        event.preventDefault();

        const data = new FormData(event.target);
        if (data.get('NewPassword') !== data.get('ConfirmPassword')) {
            this.setState({
                success: false,
                errors: ['loginErrors.changePasswordNotMatch'],
            });
            return;
        }

        const requestId = this.props.match.params.requestId;
        const email = this.props.match.params.email;

        if (!requestId || !email) {
            this.navigateToReturnUrl(LOGIN_REDIRECT_URL);
        }

        data.set('Email', email);
        data.set('Code', requestId);

        try {
            const response = await HttpClient.post<ChangePasswordResponse>(
                'api/Account/ResetPassword',
                data
            );

            if (response.data && response.data.succeeded)
                this.props.history.push(LOGIN_REDIRECT_URL);

            const identityErrors = tryGetIdentityErrors(response.data.errors);

            const errors: string[] = [];

            if(identityErrors == null){
            	if(response?.data?.errors != null && response.data.errors.length > 0){
					errors.push(...response.data.errors.map(x => x.description));
				}
			}else{
				errors.push(...identityErrors.map(x => this.props.t(x.i18nPath, x.args)));
			}

            this.setState({
                success: false,
                errors: errors ?? ['loginErrors.somethingWrong'],
            });
        } catch (error) {
            this.setState({
                success: false,
                errors: ['loginErrors.somethingWrong'],
            });
        } finally {
            this.setState({
                submitting: false,
            });
        }
    }

    navigateToReturnUrl(returnUrl: string) {
        this.props.history.replace(returnUrl);
    }

    private togglePasswordNewVisiblity(): void {
        this.setState({...this.state, passwordNewShown: !this.state.passwordNewShown});
    }
    private togglePasswordConfirmVisiblity(): void {
        this.setState({...this.state, passworConfirmShown: !this.state.passworConfirmShown});
    }

    render() {
        return (
            <div className="simple-layout-content-container">
                <Form className={loginStyle.form} onSubmit={this.submitForm}>
                    <FormGroup row>
                        <h1 className="header-30">
                            {this.props.t('resetPassword.header')}
                        </h1>
                    </FormGroup>
                    <FormGroup row>
                        <p className="paragraph">
                            {this.props.t('resetPassword.subHeader')}
                        </p>
                    </FormGroup>
                    <FormGroup row>
                        <InputGroup>
                            <Input
                                type={this.state.passwordNewShown ? "text" : "password"}
                                placeholder={this.props.t(
                                    'resetPassword.newPassword'
                                )}
                                className="input-text-primary"
                                required
                                name="NewPassword"
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText style={{border: "none", cursor: "pointer"}}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={this.state.passwordNewShown ? faEyeSlash : faEye}
                                            onClick={() => this.togglePasswordNewVisiblity()} />
                                    </span>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup row>
                        <InputGroup>
                            <Input
                                type={this.state.passworConfirmShown ? "text" : "password"}
                                placeholder={this.props.t(
                                    'resetPassword.confirmPassword'
                                )}
                                className="input-text-primary"
                                required
                                name="ConfirmPassword"
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText style={{border: "none", cursor: "pointer"}}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={this.state.passworConfirmShown ? faEyeSlash : faEye}
                                            onClick={() => this.togglePasswordConfirmVisiblity()} />
                                    </span>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup row>
                        <Button color="primary" className="w-100" type="submit">
                            {this.props.t('resetPassword.reset')}
                        </Button>
                    </FormGroup>
                    <FormGroup row>
                        {!this.state.success &&
                            this.state.errors &&
                            this.state.errors.map((error, index) => (
                                <Alert
                                    key={index}
                                    color="danger"
                                    className="w-100"
                                >
                                    {this.props.t(error)}
                                </Alert>
                            ))}
                    </FormGroup>
                </Form>
            </div>
        );
    }
}
export default connect()(withTranslation()(withRouter(ResetPassword)));
