import { ServiceDto } from '@models/demoBooking/ServiceDto';
import { AxiosResponse } from 'axios';
import { StaffDto } from '@models/demoBooking/StaffDto';
import HttpClient from '@core/HttpClient';
import { AvailableSlotsDto } from '@models/demoBooking/availableSlotsDto';
import { BookingResponseDto } from "@models/demoBooking/bookingResponseDto";

export default class DemoBookingService {
	public getServices(): Promise<AxiosResponse<ServiceDto[]>> {
		return HttpClient.get<ServiceDto[]>('api/demobooking/services');
	}

	public getStaffs(serviceId: string): Promise<AxiosResponse<StaffDto[]>> {
		return HttpClient.get<StaffDto[]>(`api/demobooking/staffs/${serviceId}`);
	}

	public getAvailableTimeslots(serviceId: string, staffId: string, date: Date): Promise<AxiosResponse<AvailableSlotsDto>> {
		return HttpClient.get<AvailableSlotsDto>(`api/demobooking/timeslots/${serviceId}/${staffId}/${date.toJSON()}`);
	}

	public bookAppointmentAsync(serviceId: string, staffId: string, fromTime: Date, customerName: string,
								customerPhone: string, customerEmail: string, comment: string, timeZone: string) {
		return HttpClient.post<BookingResponseDto>(`api/demobooking/appointment`, {
			serviceId: serviceId,
			staffId: staffId,
			fromTime: fromTime.toJSON(),
			customerName: customerName,
			customerEmail: customerEmail,
			customerPhone: customerPhone,
			comment: comment,
			ianaTimeZone: timeZone
		});
	}
}