import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
	fetchContactPermissionsMap,
	fetchContactPermissionTypes,
	updateContactPermissionsMap,
} from '@store/contactsStore';
import SessionManager from '@root/SessionManager';
import { Loader } from '@components/index';
import { UpdateContactsPermissions } from '@models/contacts';
import { unwrap } from '@helpers/reduxHelpers';
import PermissionsEditor from '@scenes/accountSettings/permissions/components/PermissionsEditor';
import { toast } from 'react-toastify';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

const PermissionsPage = () => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const {
		isPermissionsFetching,
		isPermissionsUpdating,
		permissions: permissionsFromStore,
		isPermissionTypesFetching,
		contactPermissionTypes,
	} = useAppSelector((x) => x.contacts);

	const [isChanged, setChanged] = useState(false);
	const [permissions, setPermissions] = useState(permissionsFromStore);

	useEffect(() => {
		setPermissions(permissionsFromStore);
	}, [permissionsFromStore]);

	useEffect(() => {
		if (contactPermissionTypes.length > 0) {
			return;
		}
		dispatch(fetchContactPermissionTypes());
	}, [contactPermissionTypes]);

	useEffect(() => {
		if (SessionManager.user?.organizationId == null) {
			return;
		}
		dispatch(fetchContactPermissionsMap({ organizationId: SessionManager.user.organizationId }));
	}, [SessionManager.user?.organizationId]);

	const onSubmit = async (model: UpdateContactsPermissions) => {

		const newContactPermissions = model.permissions.find((x) => x.contactId == SessionManager.user.contactId).permissions;
		const contactPermissionsFromStore = permissionsFromStore.find(x => x.id == SessionManager.user.contactId).permissions;

		const hasAllPermissions = newContactPermissions.includes(ContactPermissionType.All);

		const hasLessPermissions =
			!hasAllPermissions &&
			newContactPermissions.length < contactPermissionsFromStore.length;

		if (!hasAllPermissions || hasLessPermissions) {
			toast.error(t("unableToChangePermissionsForYourself"));
			return;
		}

		const result = await dispatch(updateContactPermissionsMap(model));
		const data = unwrap(result);
		if (data.isError) {
			toast.error(data.message);
		} else {
			toast.success(t('changesSaved'));
			setChanged(false);
		}
		return data;
	};

	const onClickUndoAll = () => {
		setPermissions([...permissions]);
		setChanged(false);
		toast.info(t('changesReset'));
	};

	return (
		<>
			<Row>
				<Col className={'mb-3'}>
					{isPermissionsFetching || isPermissionTypesFetching ? (
						<Loader />
					) : (
						<PermissionsEditor
							permissionTypes={contactPermissionTypes}
							data={permissions}
							onSubmit={(x) => onSubmit(x)}
							onChange={() => setChanged(true)}
						>
							{(onClickSubmit) => {
								return isChanged ? (
									<div className={'text-center'}>
										<button
											className={'btn btn-primary mt-3'}
											onClick={(e) => {
												e.preventDefault();
												onClickSubmit();
											}}
											disabled={
												isPermissionsUpdating ||
												isPermissionsFetching ||
												isPermissionTypesFetching
											}
										>
											{t('save')}
										</button>

										<button
											className={'btn btn-primary mt-3 ml-3'}
											onClick={(e) => {
												e.preventDefault();
												onClickUndoAll();
											}}
										>
											{t('undo')}
										</button>
									</div>
								) : null;
							}}
						</PermissionsEditor>
					)}
				</Col>
			</Row>
		</>
	);
};

export default PermissionsPage;