import React, { PropsWithChildren, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { delay } from '../../helpers';
import Button from '../Button';
import Loader from '../Loader';
import s from './modal.module.scss';
import { useTranslation } from 'react-i18next';

export type ModalError = 'not_close' | string | void;

export interface IModalProps {
  position?: 'top-center' | 'center-right' | 'top-left' | 'bottom-left';
  open: boolean;

  onClose(): void;

  onConfirm?(): Promise<ModalError> | void;

  confirmText?: string;
  confirmColor?: 'error' | 'primary' | 'success';
  cancelColor?: 'error' | 'white';
  cancelText?: string;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  singleButton?: boolean;
}

type StyleType = { marginTop: number; opacity: 0 | 1; minWidth: '900px' | null };

export const Modal: React.FC<PropsWithChildren<IModalProps>> = ({
                                                                  open,
                                                                  onConfirm,
                                                                  confirmText,
                                                                  cancelText,
                                                                  onClose,
                                                                  children,
                                                                  disabled,
                                                                  wide,
                                                                  position = 'top-center',
                                                                  confirmColor = 'primary',
                                                                  cancelColor = 'white',
                                                                  loading,
                                                                  singleButton,
                                                                }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();
  const [style, setStyle] = useState<StyleType>({
    ...(position === 'top-center' && { marginTop: -(modalRef.current?.offsetHeight || 500) }),
    ...(position === 'center-right' && { marginRight: -(modalRef.current?.offsetWidth || 500) }),
    ...((position === 'top-left' || position === 'bottom-left') && {
      marginLeft: -(modalRef.current?.offsetWidth || 500),
    }),
    opacity: 0,
    minWidth: wide ? '900px' : null,
  });

  const close = async () => {
    setStyle({
      opacity: 0,
      minWidth: null,
      ...(position === 'top-center' && { marginTop: -modalRef.current.offsetHeight }),
      ...(position === 'center-right' && { marginRight: -modalRef.current.offsetWidth }),
      ...((position === 'top-left' || position === 'bottom-left') && {
        marginLeft: -modalRef.current.offsetWidth,
      }),
    });
    await delay(200);
    setError('');
    onClose();
  };

  const missClick = async (e: SyntheticEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) await close();
  };
  const handleConfirm = async () => {
    const err = await onConfirm();
    if (err && err !== 'not_close') setError(err);
    if (!err) await close();
  };

  useEffect(() => {
    if (open)
      setStyle({
        ...(position === 'top-center' && { marginTop: 50 }),
        ...(position === 'center-right' && { marginRight: 100 }),
        ...((position === 'top-left' || position === 'bottom-left') && { marginLeft: 100 }),
        opacity: 1,
        minWidth: wide ? '900px' : null,
      });
  }, [open]);

  if (!open) return null;
  return (
    <div
      onMouseDown={missClick}
      style={{
        opacity: style.opacity,
        ...(position === 'top-center'
          ? {
            justifyContent: 'center',
            alignItems: 'flex-start',
          }
          : {}),
        ...(position === 'center-right'
          ? {
            justifyContent: 'flex-end',
            alignItems: 'center',
          }
          : {}),
        ...(position === 'top-left'
          ? {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingTop: '50px',
          }
          : {}),
        ...(position === 'bottom-left'
          ? {
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            paddingBottom: '50px',
          }
          : {}),
      }}
      className={s.modalWrap}
    >
      <div ref={modalRef} style={style} className={s.modal}>
        {children}
        <span title={error && ('error' || error)} className={s.error}>
          {error && ('error' || error)}
        </span>
        <div className={s.modalFooter}>
          <div className={s.modalActions}>
            {onConfirm && !singleButton && (
              <Button
                disabled={disabled || loading}
                type='fill'
                variant={confirmColor}
                onClick={handleConfirm}
              >
                {confirmText || 'Создать'}
              </Button>
            )}
            <Button type='fill' variant={cancelColor} onClick={close}>
              {cancelText || t('cancel')}
            </Button>
          </div>
        </div>
        {loading && (
          <div className='modal-loader-container'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};
