import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import AppModal from '@components/AppModal';
import { AdminTopNavBlock } from '@scenes/admin/components/AdminTopNavBlock';
import { useHistory } from 'react-router';
import { unwrap } from '@helpers/reduxHelpers';
import {
	createNotificationsEntry,
	deleteNotificationsEntry,
	fetchAllNotifications,
	updateNotificationsEntry,
} from '@store/admin/notificationsSettingsStore';
import {
	CreateNotificationSettings,
	EventGroupType,
	NotificationSettingsEntryDto,
	UpdateNotificationSettings,
} from '@models/admin/notificationsModels';
import NotificationSettingsEntry from '@scenes/admin/notifications/NotificationSettingsEntry';
import { fetchLanguages } from '@store/languagesStore';
import { LanguageType } from '@models/LanguageType';
import { enumToKeyValuePairArray } from '@helpers/enumHelpers';
import { toast } from 'react-toastify';
import { fetchSenderEmails } from "@store/senderEmails";
import PageHeader from '@components/PageHeader';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationsMainPage = (props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { items: itemsToPrepare, isFetching: isFetchingSettings, isEditing } = useAppSelector(
		(x) => x.notificationsSettings
	);
	const { languages, isFetching: isFetchingLanguage } = useAppSelector((x) => x.languages);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const isFetching = isFetchingSettings || isFetchingLanguage;

	const [isDeleteModalOpen, toggleDeleteModalOpen] = useState(false);
	const [modelToDelete, setModelToDelete] = useState<NotificationSettingsEntryDto>();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchAllNotifications());
		if (!(languages?.length > 0)) {
			dispatch(fetchLanguages());
		}
		dispatch(fetchSenderEmails());
	}, []);

	const items = useMemo(() => {
		return itemsToPrepare.map((x) => {
			let _languageTypes = [];
			if (!x?.languageTypes?.includes(LanguageType.Neutral)) {
				_languageTypes = x.languageTypes;
			}
			return {
				...x,
				languageTypes: _languageTypes,
			};
		});
	}, [itemsToPrepare]);

	const eventGroupTypes = useMemo(() => enumToKeyValuePairArray(EventGroupType), []);

	const { t } = useTranslation();

	const onAdd = async (model: CreateNotificationSettings) => {
		const data = unwrap(await dispatch(createNotificationsEntry(model)));
		dispatch(fetchAllNotifications());
		return data;
	};

	const onUpdate = async (model: UpdateNotificationSettings) => {
		const data = unwrap(await dispatch(updateNotificationsEntry(model)));
		dispatch(fetchAllNotifications());
		return data;
	};

	const onDelete = (model: NotificationSettingsEntryDto) => {
		setModelToDelete(model);
		toggleDeleteModalOpen(true);
	};

	const submitDelete = () => {
		return dispatch(deleteNotificationsEntry({ id: modelToDelete.id })).then((x) => {
			toggleDeleteModalOpen(false);
			setModelToDelete(null);
			return x;
		});
	};

	const createEmptyEntry = (): NotificationSettingsEntryDto => {
		return {
			languageTypes: [],
			eventGroupTypes: [],
		} as NotificationSettingsEntryDto;
	};

	const history = useHistory();

	return (
		<>
			<PageHeader title={t('notifications.texts.plural')} />
			<Row>
				<Col>
					<AdminTopNavBlock
						backButtonText={t('admin.backToPanel')}
						onClickBackButton={() => history.push('/admin/panel')}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<table className="table table-hover">
						<thead>
							<tr>
								<th key={'sender'}>{t('sender')}</th>
								<th key={'email'}>{t('email')}</th>
								<th key={'value'} style={{ minWidth: 170 }}>
									{t('languages')}
								</th>
								<th key={'eventGroups'}>{t('eventGroups')}</th>
								<th key={'eventTypes'}>{t('eventTypes')}</th>
								<th style={{minWidth: 200 }} key={'airline'}>{t('airline')}</th>
								<th style={{minWidth: 300 }} key={'location'}>{t('location')}</th>
								<th style={{ minWidth: 130 }} />
							</tr>
						</thead>
						<tbody>
							{items.map((entry, i) => (
								<NotificationSettingsEntry
									key={i}
									model={entry}
									eventGroupTypes={eventGroupTypes}
									sendRequest={onUpdate}
									onDelete={onDelete}
									availableLanguages={languages}
									isNew={false}
									otherEntries={items}
									onError={(x) => toast.error(x)}
								/>
							))}
							{
								<NotificationSettingsEntry
									key={'new'}
									eventGroupTypes={eventGroupTypes}
									availableLanguages={languages}
									sendRequest={onAdd}
									isNew={true}
									otherEntries={items}
									model={createEmptyEntry()}
									onError={(x) => toast.error(x)}
								/>
							}
						</tbody>
					</table>
				</Col>
			</Row>

			<AppModal
				isOpen={isDeleteModalOpen}
				onClickCloseButton={() => toggleDeleteModalOpen(false)}
				body={
					<>
						<h4>{t('admin.settings.deleteModalMessage').replace('{0}', modelToDelete?.email)}</h4>
						{t('admin.settings.areYouSure')}
					</>
				}
				footer={
					<div className="text-center w-100">
						<a
							className="btn btn-primary"
							onClick={(e) => {
								e.preventDefault();
								submitDelete();
							}}
						>
							{t('admin.settings.yes')}
						</a>
						{'   '}
						<a
							className="btn btn-primary"
							onClick={(e) => {
								e.preventDefault();
								toggleDeleteModalOpen(false);
								setModelToDelete(null);
							}}
						>
							{t('admin.settings.cancel')}
						</a>
					</div>
				}
			/>
		</>
	);
};

export default NotificationsMainPage;