import * as React from 'react';
import { createRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '@store/index';
import * as customerApplicationsHistoryStore from '@store/customerApplications/customerApplicationsHistoryStore';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Navigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { IGetCustomerApplicationHistoryDto } from '@models/customerApplications/customerApplicationHistoryModels';
import listStyles from '@assets/list.module.scss';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import CustomerApplicationHistoryItem from './components/CustomerApplicationHistoryItem';
import CustomerApplicationHistoryFilter, {
    IDateFilterArgs,
    IPriceFilterArgs,
} from '@scenes/customerApplicationHistory/components/CustomerApplicationHistoryFilter';
import moment from 'moment';
import {
    FilterConnection,
    FilterOperator,
    FilterValue,
    FilterValueCondition,
} from '@models/entityNavigation/filtering';
import { Collapse } from 'reactstrap';
import debounce from 'awesome-debounce-promise';
import nameof from 'ts-nameof.macro';
import SortSelect, { AvailableProperty, createSortSelectValue, SortSelectValue } from '@components/select/SortSelect';
import { PropertySorter, SortDirection } from '@models/entityNavigation/sorting';
import Paginator from '@components/paginator/Paginator';
import SessionManager from '../../SessionManager';
import { UserRoles } from '@config/ApiAuthorizationConstants';
import { Loader } from '@components/index';
import { LocationDto } from '@models/locations';
import { ItemsPerPage } from '@components/paginator/ItemsPerPage';
import PageHeader from '@components/PageHeader';
import s from './style.module.scss';
import { localSettingsSlice, SettingsState } from '@store/localSettings';
import { Settings } from '@root/constants/localSettings';

type Props = RouteComponentProps<{}> &
    customerApplicationsHistoryStore.IState &
    typeof customerApplicationsHistoryStore.actionCreators &
    WithTranslation & { localSettings: SettingsState } & typeof localSettingsSlice.actions;

type State = {
    isFilterOpen: boolean;
    dateFilter: IDateFilterArgs;
    priceFilter: IPriceFilterArgs;
    numberSearchTerm?: string;
    popovers: Map<string, boolean>;
    showOwn?: boolean;
    filterLocationsFrom: LocationDto[];
    filterLocationsTo: LocationDto[];
    sorting?: SortSelectValue;
    availableSortingProperties: AvailableProperty[];
};

const convertLocationToFilterValue = (location: LocationDto, airportIdName: string): FilterValue => {
    const fv = new FilterValue(airportIdName, FilterValueCondition.Equals);
    fv.value = location.id;
    return fv;
};

class CustomerApplicationHistoryPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.initializeFilterEvents();
        this.initializeSortingEvents();

        const availableSortingProperties = [
            {
                label: 'Дата создания',
                propertyName: nameof.full<IGetCustomerApplicationHistoryDto>(
                    (x) => x.createTime,
                ),
            },
            {
                label: this.props.t('customerApplicationHistory.date'),
                propertyName: nameof.full<IGetCustomerApplicationHistoryDto>(
                    (x) => x.dateStartPlan,
                ),
            },
            {
                label: this.props.t('customerApplicationHistory.organizations'),
                propertyName: 'organization.name',
            },
        ];

        this.state = {
            isFilterOpen: false,
            dateFilter: {
                from: null,
                to: null, //endDate
            },
            priceFilter: {
                from: null,
                to: null,
            },
            popovers: new Map<string, boolean>(),
            showOwn: this.getShowOwnFilterInitialState(),
            filterLocationsFrom: [],
            filterLocationsTo: [],
            sorting: createSortSelectValue(availableSortingProperties[0], SortDirection.Descending),
            availableSortingProperties: availableSortingProperties,
        };

        this.nav.sorters = [{ path: this.state.sorting?.propertyName, sortDirection: SortDirection.Descending }];

        this.tableWrapper = createRef<HTMLElement>();
    }

    private getShowOwnFilterInitialState = () => {
        let showOwn = true;

        if (
            SessionManager.isAuthenticated() &&
            SessionManager.user.hasAnyRole(UserRoles.superuser, UserRoles.tenantAdmin)
        ) {
            showOwn = null;
        }

        return showOwn;
    };

    private getData = (nav: Navigation, resetPaging: boolean) => {
        if (resetPaging) {
            nav.pagingFilter.pageNumber = this.props.localSettings.applications.page;
        }
        this.props.getData(nav);
    };

    private getDebounced = debounce(this.getData, 600);

    private nav: Navigation = {
        sorters: [],
        filters: [],
        pagingFilter: {
            pageSize: this.props.localSettings.applications.amount,
            pageNumber: this.props.localSettings.applications.page,
        },
    };

    private onChangeDatesFilter: (args?: IDateFilterArgs) => void;
    private onChangeLocationFromFilter: (locations: LocationDto[]) => void;
    private onChangeLocationToFilter: (locations: LocationDto[]) => void;
    private onChangeAirlineFilter: (airlineIds?: string[]) => void;
    private onChangeNumberFilter: (term?: string) => void;
    private onChangeSort: (value?: SortSelectValue) => void;
    private onChangeShowOwnFilter: (showOwn?: boolean) => void;
    private onChangeOrganizationsFilter: (organizationIds: string[]) => void;

    private initializeFilterEvents = (args?: IDateFilterArgs) => {
        const dateStartPlanCn = new FilterConnection(FilterOperator.And);
        const dateStartPlanMoreOrEqualsNode = new FilterValue(
            nameof.full<IGetCustomerApplicationHistoryDto>((x) => x.dateStartPlan),
            FilterValueCondition.MoreOrEqualsThan,
            args?.from,
        );
        dateStartPlanCn.values.push(dateStartPlanMoreOrEqualsNode);

        const dateStartPlanLessOrEqualsNode = new FilterValue(
            nameof.full<IGetCustomerApplicationHistoryDto>((x) => x.dateStartPlan),
            FilterValueCondition.LessOrEqualsThan,
            args?.to,
        );
        dateStartPlanCn.values.push(dateStartPlanLessOrEqualsNode);

        const dateStartFactCn = new FilterConnection(FilterOperator.And);

        const dateStartFactMoreOrEqualsNode = new FilterValue(
            nameof.full<IGetCustomerApplicationHistoryDto>((x) => x.dateStartFact),
            FilterValueCondition.MoreOrEqualsThan,
            args?.from,
        );
        dateStartFactCn.values.push(dateStartFactMoreOrEqualsNode);

        const dateStartFactLessOrEqualsNode = new FilterValue(
            nameof.full<IGetCustomerApplicationHistoryDto>((x) => x.dateStartFact),
            FilterValueCondition.LessOrEqualsThan,
            args?.to,
        );
        dateStartFactCn.values.push(dateStartFactLessOrEqualsNode);

        const datesFilterCn = new FilterConnection(FilterOperator.Or);
        datesFilterCn.connections.push(dateStartPlanCn, dateStartFactCn);

        const airlineFilterCn = new FilterConnection(FilterOperator.Or);

        const numberFilter = new FilterValue(
            nameof.full<IGetCustomerApplicationHistoryDto>((x) => x.airwaybillNumber),
            FilterValueCondition.Contains,
            null,
        );

        const credentialFilterCn = new FilterConnection(FilterOperator.Or);

        const organizationFilterCn = new FilterConnection(FilterOperator.Or);

        const locationFromFilterCn = new FilterConnection(FilterOperator.Or);
        const locationToFilterCn = new FilterConnection(FilterOperator.Or);

        const mainFilterCn = new FilterConnection(FilterOperator.And); // Change to And when not empty.
        mainFilterCn.connections.push(datesFilterCn);
        mainFilterCn.connections.push(airlineFilterCn);
        mainFilterCn.connections.push(credentialFilterCn);
        mainFilterCn.connections.push(organizationFilterCn);
        mainFilterCn.connections.push(locationFromFilterCn);
        mainFilterCn.connections.push(locationToFilterCn);
        mainFilterCn.values.push(numberFilter);

        this.nav.filters.push(mainFilterCn);

        this.onChangeDatesFilter = (args?: IDateFilterArgs) => {
            const { from, to } = args ?? { from: null, to: null };

            dateStartPlanMoreOrEqualsNode.value = from ? moment(from).utc().toISOString(false) : null;
            dateStartPlanLessOrEqualsNode.value = to ? moment(to).utc().toISOString(false) : null;

            dateStartFactMoreOrEqualsNode.value = from ? moment(from).utc().toISOString(false) : null;
            dateStartFactLessOrEqualsNode.value = to ? moment(to).utc().toISOString(false) : null;

            this.setState({ dateFilter: { from, to } });

            this.getDebounced(this.nav, true);
        };

        this.onChangeLocationFromFilter = (locations: LocationDto[] = []) => {
            locationFromFilterCn.values = locations.map((x) => convertLocationToFilterValue(x, 'departureAirportId'));

            this.getDebounced(this.nav, true);

            this.setState({ filterLocationsFrom: locations });
        };

        this.onChangeLocationToFilter = (locations: LocationDto[] = []) => {
            locationToFilterCn.values = locations.map((x) => convertLocationToFilterValue(x, 'destinationAirportId'));
            this.getDebounced(this.nav, true);

            this.setState({ filterLocationsTo: locations });
        };

        this.onChangeAirlineFilter = (airlineIds?: string[]) => {
            airlineFilterCn.values =
                airlineIds?.map((x) => new FilterValue('airlineId', FilterValueCondition.Equals, x)) ?? [];

            this.getDebounced(this.nav, true);
        };

        this.onChangeNumberFilter = (term?: string) => {
            if (term) {
                term = term.replace('#', '').trim();
            }

            numberFilter.value = term;

            if (term && term.length > 2) {
                this.getDebounced(this.nav, true);
            } else {
                numberFilter.value = null;
                this.getDebounced(this.nav, true);
            }
        };

        this.onChangeShowOwnFilter = (showMy?: boolean) => {
            if (showMy) {
                credentialFilterCn.values = [
                    new FilterValue('creatorUserId', FilterValueCondition.Equals, SessionManager.user.id),
                ];
            } else {
                credentialFilterCn.values = [];
            }
            this.setState({ showOwn: showMy }, () => {
                this.getDebounced(this.nav, true);
            });
        };

        this.onChangeShowOwnFilter(this.getShowOwnFilterInitialState());

        this.onChangeOrganizationsFilter = (organizationIds) => {
            organizationFilterCn.values = organizationIds.map(
                (x) => new FilterValue('organizationId', FilterValueCondition.Equals, x),
            );

            this.getDebounced(this.nav, true);
        };
    };

    private initializeSortingEvents = () => {
        const sorter = new PropertySorter();
        this.nav.sorters.push(sorter);
        this.setState(x => ({ sorting: createSortSelectValue(x.availableSortingProperties[0], SortDirection.Descending) }));

        this.onChangeSort = (val) => {

            this.setState({ sorting: val });

            sorter.path = val?.propertyName;
            sorter.sortDirection = val?.sortDirection;

            this.getDebounced(this.nav, false);
        };
    };

    private onChangeCountPerPage = (countPerPage: number) => {
        this.nav.pagingFilter.pageSize = countPerPage;
        this.props.updateSettingsAmount({ key: Settings.CustomerApplications, amount: countPerPage });
        this.getDebounced(this.nav, false);
    };

    private onChangePage = (pageNum: number) => {
        this.props.updateSettingsPage({ key: Settings.CustomerApplications, page: pageNum });
        this.nav.pagingFilter.pageNumber = pageNum;
        this.getDebounced(this.nav, false);
    };

    componentDidMount() {
        this.hightlightItem();
        this.props.getData(this.nav);
    }

    private hightlightItem = (): void => {
        let highlighItemId = null;

        if (this.props.location.hash && this.props.location.hash.length > 0) {
            highlighItemId = this.props.location.hash.substr(1);
            if (highlighItemId.length === 0) {
                highlighItemId = null;
            }
        }

        if (highlighItemId) {
            const hightlightWatcher = setInterval(() => {
                const el = document.getElementById(highlighItemId);
                if (el) {
                    el.click();
                    el.scrollIntoView(true);
                    el.scrollTop -= 10;
                    el.classList.add('highlighted');
                    clearInterval(hightlightWatcher);
                }
            }, 500);
        }
    };

    /* Render history items. */
    private renderItems = (pagingWrapper: IPagingWrapper<IGetCustomerApplicationHistoryDto>) => {
        if (pagingWrapper?.items == null || pagingWrapper.items.length == 0) {
            return <div className={listStyles.box}>{this.props.t('customerApplicationHistory.nothingFound')}</div>;
        }
        return pagingWrapper.items.map((x, i) => <CustomerApplicationHistoryItem key={i} data={x} />);
    };

    private onChangePriceFilter = (args: IPriceFilterArgs) => {
        this.setState({
            priceFilter: args,
        });
    };

    private clearFilters = () => {
        this.onChangeAirlineFilter([]);
        this.onChangeDatesFilter(null);
        this.onChangePriceFilter(null);
        this.onChangeNumberFilter(null);
        this.onChangeShowOwnFilter(this.getShowOwnFilterInitialState());
        this.onChangeOrganizationsFilter([]);
        this.onChangeLocationFromFilter([]);
        this.onChangeLocationToFilter([]);
    };

    private tableWrapper: any;

    private toggleOpenFilters = () => {
        this.setState((prev) => ({ isFilterOpen: !prev.isFilterOpen }));
    };

    render() {

        return (
            <div className={s.itemsTable}>
                <PageHeader
                    title={this.props.t('customerApplicationHistory.title')}
                    additionalInfo={`${this.props.t('customerApplicationHistory.totalCount')} ${this.props.pagingWrapper?.totalCount}`} />

                <div className={userItemsPageStyles.topPanel}>
                    <div className={userItemsPageStyles.sortSelect}>
                        <SortSelect
                            availableProperties={this.state.availableSortingProperties}
                            onChange={(value) => this.onChangeSort(value)}
                            value={this.state.sorting}
                        />
                    </div>

                    <a
                        href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            this.toggleOpenFilters();
                        }}
                        className={`${userItemsPageStyles.topPanel__filterBtn} ${
                            this.state.isFilterOpen ? userItemsPageStyles.topPanel__filterBtn__active : ''
                        }`}
                    >
                        <span>{this.props.t('customerApplicationHistory.filter')}</span>
                        <div>
                            <i className='icon-filter' />
                        </div>
                    </a>
                </div>

                <Collapse isOpen={this.state.isFilterOpen}>
                    <CustomerApplicationHistoryFilter
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        dateFilter={this.state.dateFilter}
                        priceFilter={this.state.priceFilter}
                        onChangeDateFilter={(args) => {
                            this.onChangeDatesFilter(args);
                        }}
                        onChangePriceFilter={(args) => {
                            this.onChangePriceFilter(args);
                        }}
                        onChangeAirlineFilter={(airlineIds) => {
                            this.onChangeAirlineFilter(airlineIds);
                        }}
                        onChangeNumberFilter={(term) => this.onChangeNumberFilter(term)}
                        onClearFilters={() => this.clearFilters()}
                        onChangeShowOwn={(x) => this.onChangeShowOwnFilter(x)}
                        showOwn={this.state.showOwn}
                        onChangeOrganizationsFilter={(x) => this.onChangeOrganizationsFilter(x)}
                        onChangeLocationFromFilter={(x) => this.onChangeLocationFromFilter(x)}
                        onChangeLocationToFilter={(x) => this.onChangeLocationToFilter(x)}
                        selectedLocationsFrom={this.state.filterLocationsFrom}
                        selectedLocationsTo={this.state.filterLocationsTo}
                    />
                </Collapse>

                {this.props.isFetching ? (
                    <Loader />
                ) : (
                    <div>
                        <div className={s.table} ref={this.tableWrapper}>
                            {this.renderItems(this.props.pagingWrapper)}
                        </div>
                        <div className={listStyles.paginator}>
                            <Paginator
                                totalResults={this.props?.pagingWrapper?.totalCount}
                                limitPerPage={this.nav.pagingFilter.pageSize}
                                currentPage={this.nav.pagingFilter.pageNumber + 1}
                                onChangePage={(p) => this.onChangePage(p)}
                                pageNeighbours={4}
                            />
                            <ItemsPerPage onChange={x => this.onChangeCountPerPage(x)}
                                          value={this.nav.pagingFilter.pageSize} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const connectedComponent
    = connect((state: ApplicationState) => (
    {
        ...state.customerApplicationsHistory, localSettings: state.localSettings,
    }), { ...customerApplicationsHistoryStore.actionCreators, ...localSettingsSlice.actions })(CustomerApplicationHistoryPage as any);

export default withRouter(withTranslation()(connectedComponent) as any);