import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import OrganizationService from '@services/OrganizationsService';
import { OrganizationSettingsDto } from '@models/settings/OrganizationSettings';
import { OrganizationAccessLevel } from '@models/organizations';

export type State = {
	organizationSettings?: OrganizationSettingsDto;
	isOrganizationSettingsFetching: boolean;
};

export const fetchOrganizationSettings = createAppThunk(
	'organizationSettings/fetchOrganizationSettings',
	async () => {
		const {data} = await new OrganizationService().fetchOrganizationSettings();
		return data;
	}
);

const slice = createSlice({
	name: 'organizationSettings',
	initialState: {
		isAccreditationListUpdating: false,
		organizationSettings: null,
		isOrganizationSettingsFetching: false
	} as State,
	reducers: {
		resetOrganizationSettings: (state) => {
			state.organizationSettings = null;
		},
		setAccreditationListFilled: (state) => {
			if (!state.organizationSettings) {
				state.organizationSettings = {
					accessLevel: OrganizationAccessLevel.Pending
				};
			}
			state.organizationSettings.accessLevel = OrganizationAccessLevel.Pending;
		}
	},
	extraReducers: (builder) => {

		builder.addCase(fetchOrganizationSettings.fulfilled, (state, action) => {
			state.organizationSettings = action.payload;
			state.isOrganizationSettingsFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchOrganizationSettings.pending, (state, action) => {
			state.isOrganizationSettingsFetching = true;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchOrganizationSettings.rejected, (state, action) => {
			state.isOrganizationSettingsFetching = false;
		});
	},
});

export const {reducer} = slice;
export const {actions} = slice;