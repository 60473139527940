export const UserRoles = {
    superuser: "SUPERUSER",
    tenantAdmin: "TENANT_ADMIN",
    awbEditor: "AwbEditor",
	serviceMode: "ServiceMode"
};

export const ApplicationName = 'reactLogin';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
    ResetPassword: 'ResetPassword',
    ResetPasswordRequest: 'ResetPasswordRequest',
    ChangePassword: 'ChangePassword',
};

const prefix = '/auth';


export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    // ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}/:email/:errorType`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}/:requestId`,
    Register: `${prefix}/${LoginActions.Register}`,
    ResetPassword: `${prefix}/${LoginActions.ResetPassword}/:requestId/:email`,
    ResetPasswordRequest: `${prefix}/${LoginActions.ResetPasswordRequest}`,
    ChangePassword: `${prefix}/${LoginActions.ChangePassword}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: '/Identity/Account/Register',
    IdentityManagePath: '/Identity/Account/Manage'
};
export const LOGIN_REDIRECT_URL = `${prefix}/${LoginActions.Login}`;
