import classNames from 'classnames';
import styles from './index.module.scss';
import React, { ReactElement, useEffect, useState } from 'react';
import Popup from '@components/demoForm/inputs/Popup';
import TextInput from '@components/demoForm/inputs/TextInput';

export interface ListItem {
	id: string;
	name: string;
}

export interface ComboboxProps<T extends ListItem> {
	label: string;
	name: string;
	placeholder?: string;
	values: T[];
	value?: T;
	setSelected?: (value: T) => void;
}

const Combobox = <T extends ListItem, >(
	{
		label,
		name,
		placeholder = '',
		values = [],
		value = null,
		setSelected = (): void => void 0
	}: ComboboxProps<T>): ReactElement => {

	const [showPopup, setShowPopup] = useState(false);
	const [selectedValue, setSelectedValue] = useState<T>(null);
	const change = (value: T): void => {
		setSelectedValue(value);
		setSelected(value);
		setShowPopup(false);
	};

	useEffect(() => {
		setSelectedValue(value);
	}, [value]);

	return <div className={classNames(styles.input, styles.text, styles['combo-box'])}>
		<TextInput label={label}
				   name={name}
				   onFocus={(): void => setShowPopup(true)}
				   value={selectedValue?.name || ''}
				   placeholder={placeholder} />

		<Popup show={showPopup} setShow={setShowPopup} className={styles['combo-box-popup']}>
			{values && values.map((value, index) =>
				<div className={styles['data-item']} key={index} data-value={value.id}
					 onClick={(): void => change(value)}>{value.name}</div>)}
		</Popup>
	</div>;
};

export default Combobox;