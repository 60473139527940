import React from 'react';
import cn from 'classnames';
import Badge from '../Badge/index';
import s from './commentItem.module.scss';
import { CommentDto } from '@models/comment';
import SessionManager from '@root/SessionManager';
import getDate from '@helpers/dateTime/getDate';

export type ICommentProps = {
  comment: CommentDto;
};

const CommentItem: React.FC<ICommentProps> = ({ comment }) => {
  const { authorEmail, created, body, authorName } = comment;
  const isAuthor = SessionManager.user.email == authorEmail;

  return (
    <div className={cn([s.comment, { [s.commentMy]: isAuthor }])}>
      <div className={cn(s.commentHeader)}>
        <div className={s.commentHeaderInfo}>
          <span className={s.commentHeaderTimeAgo}>{getDate(created)}</span>
          <Badge label={authorName || authorEmail} color={SessionManager.user.email === authorEmail ? 'white' : 'blue'} />
        </div>
      </div>
      <span className={s.commentText}>{body}</span>
    </div>
  );
};

export default CommentItem;
