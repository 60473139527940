/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

export class DateTime {

    private _moment: moment.Moment;

    private static _dateFormat = 'YYYY-MM-DD';

    constructor(date: string | Date | moment.Moment, zulu = false) {

        if (moment.isMoment(date)) {
            this._moment = date.clone();
        } else {

            if (this.isDateType(date)) {
                date = (date as Date).toISOString();
            }

            date = this.forceZulu(date as string, zulu);

            this._moment = moment(date);
        }
    }

    private isDateType = (input: any) => {
        return typeof input.getMonth === 'function';
    };

    private forceZulu = (dateString: string, zulu: boolean): string => {
        if (dateString.endsWith('Z')) {
            if (!zulu) {
                return dateString.replace('Z', '');
            }
        } else {
            if (zulu) {
                dateString += 'Z';
                return dateString;
            }
        }
        return dateString;
    };

    public diffDaysCount = (dateTime: DateTime): number => {
        return this._moment.diff(dateTime._moment, 'day');
    };

    public toISOString = (zulu = false): string => {
        return this._moment.format('YYYY-MM-DDTHH:mm:ss' + (zulu == true ? 'Z' : ''));
    };

    public toDateString = (): string => {
        return this._moment.format(DateTime._dateFormat);
    };

    public static now(resetTime: boolean): DateTime {
        return resetTime ?
            new DateTime(moment().format(this._dateFormat)) :
            new DateTime(moment());
    }

    public valueOf = (): number => {
        return this._moment.valueOf();
    };

    public resetTime = (): void => {
        this._moment = moment(this._moment.format(DateTime._dateFormat));
    };

    public clone = () : DateTime => {
        return new DateTime(this._moment);
    }

    public addDays = (count: number) : void => {
        if(count >= 0){
            this._moment.add(count, 'day');
        }else{
            this._moment.subtract(count * -1, 'day');
        }
    }

    public arrangeDates = (daysOffset: number): DateTime[] => {

        const startDate = this.clone();

        let dates = new Array(Math.abs(daysOffset)).fill(0).map((x, i) => {
            const d = startDate.clone();
            const inx = daysOffset > 0 ? i : i * (-1);
            d.addDays(inx);
            return d;
        });

        if (daysOffset < 0) {
            dates = dates.reverse();
        }

        return dates;
    };

    public format = (format: string) => {
        return this._moment.format(format);
    }

    public toLocal = () => {
    	return new DateTime(this._moment.local());
	}
}