import { ReviewDto } from '@models/reviewDto';
import { createAppThunk } from '@helpers/reduxHelpers';
import ReviewService from '@services/ReviewService';
import { createSlice } from '@reduxjs/toolkit';

export type State = {
	latestReviews: ReviewDto[];
	isFetched: boolean;
	isFetching: boolean;
}

export const fetchLatestReviews = createAppThunk('reviews/fetchLatestReviews', async () => {
	const reviewService = new ReviewService();

	return await reviewService.getLatestReviewsAsync();
});

const slice = createSlice({
	name: 'latest-reviews',
	initialState: {
		latestReviews: [],
		isFetched: false,
		isFetching: false
	} as State,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchLatestReviews.fulfilled, (state, action) => {
			state.latestReviews = action.payload as ReviewDto[];
			state.isFetching = false;
			state.isFetched = true;
		});

		builder.addCase(fetchLatestReviews.rejected, state => {
			state.latestReviews = [];
			state.isFetching = false;
			state.isFetched = false;
		})

		builder.addCase(fetchLatestReviews.pending, state => {
			state.latestReviews = [];
			state.isFetching = true;
			state.isFetched = false;
		})
	}
});

export const { reducer } = slice;
export const { actions } = slice;