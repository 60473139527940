import React, { useEffect } from 'react';
import useHashParam from 'use-hash-param';
import { useTranslation } from 'react-i18next';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import clsx from 'clsx';
import styles from './AccountSettingsMainPage.module.scss';
import PersonalPage from '@scenes/accountSettings/personal/PersonalPage';
import OrganizationPage from '@scenes/accountSettings/organization/OrganizationPage';
import ContactsPage from '@scenes/accountSettings/contacts/ContactsPage';
import ContactEditPage from '@scenes/accountSettings/contacts/ContactEditPage';
import ContactCreatePage from '@scenes/accountSettings/contacts/ContactCreatePage';
import SessionManager from '@root/SessionManager';
import PermissionsPage from '@scenes/accountSettings/permissions/PermissionsPage';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

const AccountSettingsMainPage = (): JSX.Element => {
	const [activeTabId, setActiveTabId] = useHashParam('tabId', null);
	const [editContactId, setEditContactId] = useHashParam('id',null);
	const [isCreateContactMode, setCreateContactMode] = useHashParam('newEmployee',null);

	const { t } = useTranslation();

	const onChangeTab = (newTabId: string) => {
		if (activeTabId !== newTabId) {
			setActiveTabId(newTabId);
		}
		setEditContactId('');
	};

	useEffect(() => {
		if (activeTabId == null || activeTabId == '') {
			setActiveTabId('personal');
		}
	}, [activeTabId]);

	const renderContactsPage = () => {
		if (isCreateContactMode) {
			return (
				<ContactCreatePage
					organizationId={SessionManager.user.organizationId}
					onExit={() => setCreateContactMode('')}
				/>
			);
		}

		if (editContactId) {
			return <ContactEditPage contactId={editContactId} onExit={() => setEditContactId('')} />;
		}

		return (
			<>
				{SessionManager.user.hasPermissions(ContactPermissionType.UpdateContact) && <Row>
					<Col className={'mb-3 clearfix'}>
						<div className={'d-inline-block float-right'}>
							<button className={'btn btn-success'} onClick={() => setCreateContactMode('true')}>
								{t('add')}
							</button>
						</div>
					</Col>
				</Row>
				}

				<Row>
					<Col>
						<ContactsPage />
					</Col>
				</Row>
			</>
		);
	};

	return (
		<Row>
			<Col>
				<Row>
					<Col>
						<h1>{t('accountSettings')}</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<Nav tabs className={clsx(styles.navTabs, 'mt-5')}>
							<NavItem>
								<NavLink
									className={clsx({ active: activeTabId == 'personal' })}
									onClick={() => {
										onChangeTab('personal');
									}}
								>
									{t('personalInfo')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={clsx({ active: activeTabId === 'organization' })}
									onClick={() => {
										onChangeTab('organization');
									}}
								>
									{t('organizationText')}
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={clsx({ active: activeTabId === 'employees' })}
									onClick={() => {
										onChangeTab('employees');
									}}
								>
									{t('employees')}
								</NavLink>
							</NavItem>

							{SessionManager.user.hasPermissions(
								ContactPermissionType.All
							) && <NavItem>
									<NavLink
										className={clsx({ active: activeTabId === 'permissions' })}
										onClick={() => {
											onChangeTab('permissions');
										}}
									>
										{t('permissions')}
									</NavLink>
								</NavItem>
							}

						</Nav>
						<TabContent activeTab={activeTabId}>
							<TabPane tabId="personal">
								<Row>
									<Col>
										<div className={clsx(styles.block, styles.block__tab)}>
											{activeTabId == 'personal' && <PersonalPage/>}
										</div>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="organization">
								<Row>
									<Col>
										<div className={clsx(styles.block, styles.block__tab)}>
											<OrganizationPage />
										</div>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="employees">
								<Row>
									<Col>
										<div className={clsx(styles.block, styles.block__tab)}>
											{activeTabId == 'employees' && renderContactsPage()}
										</div>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="permissions">
								<Row>
									<Col>
										<div className={clsx(styles.block, styles.block__tab)}>
											{SessionManager.user.hasPermissions(
												ContactPermissionType.All
											) ? (
												<PermissionsPage />
											) : (
												'Access denied.'
											)}
										</div>
									</Col>
								</Row>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AccountSettingsMainPage;