import React from "react";
import { PropsWithChildren, useEffect } from "react";
import { FilterConnection, FilterOperator, FilterValue } from "@models/entityNavigation/filtering";
import { GroupOrFieldFilter } from "@components/Filter/groupOrFieldFilter";
import { FieldFilter } from "@components/Filter/fieldFilter";

interface Props {
	filterFields: GroupOrFieldFilter;
	onFilter?: (filter: FilterConnection) => void;
}

export function Filter(props: PropsWithChildren<Props>) {
	useEffect(() => {
		const filter = buildFilter(props.filterFields);
		props.onFilter(filter);
	}, [ props.filterFields ]);

	return <div data-testid="filter">
		{props.children}
	</div>;

	function convertField(result: FilterConnection, field: FieldFilter, hasAnyFields = false) {
		const values = Array.isArray(field.value)
			? field.value.map(value => new FilterValue(field.name, field.condition, value))
			: [ new FilterValue(field.name, field.condition, field.value) ];

		let filterConnection = result;
		if (values.length > 1) {
			if (hasAnyFields) {
				filterConnection = new FilterConnection(FilterOperator.Or);
				result.connections.push(filterConnection)
			}

			filterConnection.operator = FilterOperator.Or;
		}

		filterConnection.values.push(...values);
	}

	function buildFilter(filterItem: GroupOrFieldFilter): FilterConnection {
		const result = new FilterConnection(FilterOperator.And);
		if (filterItem == null) {
			return result;
		}

		switch (filterItem.type) {
			case 'group':
				result.operator = filterItem.operator;
				for (const field of filterItem.fields) {
					if (field.type == 'group') {
						result.connections.push(buildFilter(field));
					} else {
						convertField(result, field, filterItem.fields.length > 1);
					}
				}
				break;

			case 'field':
				convertField(result, filterItem);
				break;
		}

		return result;
	}
}