import React, { useState } from 'react';
import s from '../modals.module.scss';
import { routes } from '../../constants';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';

interface IModalProps {
  open: boolean;
  hasGenCode: boolean;
  confirmText: string;

  onClose(boolean?): void;

  onConfirm(boolean?): void;

  dimensionsValidationResult: number;
}

const RequestBookingModal: React.FC<IModalProps> = ({
                                                      open,
                                                      onClose,
                                                      onConfirm,
                                                      confirmText,
                                                      dimensionsValidationResult,
                                                      hasGenCode,
                                                    }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={false}
      confirmText={confirmText}
    >
      <div className={s.modalHeader}>
        <span className={s.modalTitle}>{t('requestSearchResults.agreementTitle')}</span>
      </div>
      <div className={s.modalBody}>
        <div className={s.rowSection}>
          {t('requestSearchResults.agreement')}
        </div>
        <div className={s.rowSection}>
          {t(
            dimensionsValidationResult === 0
              ? 'requestSearchResults.agreementNotValidated'
              : 'requestSearchResults.agreementCanBeChanged',
          )}
        </div>

        <div className={s.rowSection}>
          {hasGenCode && (
            <Row className={'mt-4'}>
              <Col>
                <Label>
                  {t('requestSearchResults.agreementGen')}
                </Label>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RequestBookingModal;
