import i18n from '@i18n';
import { localStorageService } from '@services/LocalStorageService';
import moment from 'moment';
import SettingsService from '@services/SettingsService';

export async function changeLanguage(languageIsoCode: string) {
	if (!i18n.hasResourceBundle(languageIsoCode, 'strings')) {
		const { data } = await new SettingsService().getLocalizedSettingsJson(languageIsoCode);
		i18n.addResources(languageIsoCode, 'strings', data);
	}

	localStorageService.setItem<string>('locale', languageIsoCode);
	moment.locale(i18n.language);
	i18n.changeLanguage(languageIsoCode);
}