import React, { useState } from 'react';
import { Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';

import styles from './ChangePassword.module.scss';

import nameof from 'ts-nameof.macro';

import { ChangePasswordErrors, ChangePasswordModel } from '@models/accounts/changePasswordModels';

import AccountService from '@services/AccountService';

import { useTranslation } from 'react-i18next';

import { isWhiteSpace } from '@helpers/stringHelpers';
import { ContactDto } from '@models/contacts';
import clsx from 'clsx';
import { Row, Col } from 'reactstrap';

const InputField = (props: { label: string; name: string }): JSX.Element => {
	return (
		<Field name={props.name}>
			{({ field, form }: FieldProps<string, ChangePasswordModel>): JSX.Element => (
				<>
					<label className="control-label">{props.label}</label>
					<input className='form-control' type="password" {...field} />
					{form.touched[props.name] && form.errors[props.name] && (
						<div className={styles.error}>{form.errors[props.name]}</div>
					)}
				</>
			)}
		</Field>
	);
};

const GetPassword = (props: { email: string }): JSX.Element => {
	const [isSuccess, setSuccess] = useState<boolean>(null);
	const [isSubmitting, setSubmitting] = useState(false);

	const { t: tPassword } = useTranslation('', { keyPrefix: 'password' });

	const sendEmailRequest = async (): Promise<void> => {
		const accountService = new AccountService();

		try {
			setSubmitting(true);
			setSuccess(null);
			await accountService.createPasswordRequest({ email: props.email });
			setSuccess(true);
		} catch (error) {
			setSuccess(false);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<>
			<div className={styles.header}>{tPassword('get')}</div>
			<div className={styles.formGroup}>{tPassword('noPassword')}</div>
			<div className={clsx(styles.buttons, 'mt-4')}>
				<button className={'btn btn-primary'} onClick={sendEmailRequest} disabled={isSubmitting}>
					{tPassword('sendEmail')}
				</button>
			</div>
			{isSuccess !== null && isSuccess && (
				<div className="alert alert-success mt-4">{tPassword('emailSent')}</div>
			)}
			{isSuccess !== null && !isSuccess && (
				<div className="alert alert-danger mt-4">{tPassword('emailSendFail')}</div>
			)}
		</>
	);
};

const ChangePassword = (props: { contact: ContactDto }): JSX.Element => {
	const { contact } = props;

	const [success, setSuccess] = useState(null);
	const [errors, setErrors] = useState<string[]>([]);

	const initialValues: ChangePasswordModel = {
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	};

	const { t } = useTranslation();
	const { t: tPassword } = useTranslation('', { keyPrefix: 'password' });

	return (
		contact && (
			<div>
				{contact.hasPassword == true ? (
					<Formik
						initialValues={initialValues}
						validate={(values): {} => {
							const errors: ChangePasswordErrors = {};

							if (
								values?.newPassword &&
								values?.confirmPassword &&
								values.newPassword !== values.confirmPassword
							)
								errors.confirmPassword = tPassword('passwordsMismatch');

							if (isWhiteSpace(values?.currentPassword))
								errors.currentPassword = t('validation.required');

							if (isWhiteSpace(values?.newPassword)) errors.newPassword = t('validation.required');

							if (isWhiteSpace(values?.confirmPassword))
								errors.confirmPassword = t('validation.required');

							return errors;
						}}
						onSubmit={async (
							values: ChangePasswordModel,
							{ setSubmitting }: FormikHelpers<ChangePasswordModel>
						): Promise<void> => {
							const accountService = new AccountService();

							try {
								setSuccess(null);
								setErrors([]);
								const response = (await accountService.changePassword(values)).data;

								if (response.succeeded) {
									setSuccess(true);
								} else {
									setSuccess(false);
									setErrors(response.errors.map((error) => error.description));
								}
							} catch (error) {
								setSubmitting(false);
							} finally {
							}
						}}
					>
						{({ isSubmitting }: FormikProps<ChangePasswordModel>): JSX.Element => (
							<Row>
								<Col>
									<Row>
										<Col>
											<div className={styles.header}>{tPassword('changePassword')}</div>
										</Col>
									</Row>
									<Row>
										<Col>
											<InputField
												name={nameof.full<ChangePasswordModel>((x) => x.currentPassword)}
												label={tPassword('currentPassword')}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<InputField
												name={nameof.full<ChangePasswordModel>((x) => x.newPassword)}
												label={tPassword('newPassword')}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<InputField
												name={nameof.full<ChangePasswordModel>((x) => x.confirmPassword)}
												label={tPassword('confirmPassword')}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<button className='btn btn-primary mt-4' type="submit" disabled={isSubmitting}>
												{t('save')}
											</button>
										</Col>
									</Row>
								{success !== null && success && (
									<div className="alert alert-success mt-4">{tPassword('passwordChanged')}</div>
								)}
								{success !== null &&
									!success &&
									errors.map((error, index) => (
										<div key={index} className="alert alert-danger mt-4">
											{error}
										</div>
									))}
								</Col>
							</Row>
						)}
					</Formik>
				) : (
					<GetPassword email={contact.email} />
				)}
			</div>
		)
	);
};

export default ChangePassword;