import { AddressDatumDto, AddressTokenDto, TokenType } from '@models/awbs/awbsModels';
import { createAppThunk } from '@helpers/reduxHelpers';
import AddressDataService from '@services/AddressDataService';
import { createAction, createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';

export interface State {
    isFetching: boolean;
    isParsing: boolean;
    isSaving: boolean;
    addressDatum?: AddressDatumDto;
    parsedTokens?: AddressTokenDto[];
}

export const getParticipantContactInfoById = createAppThunk(
    'participantContactInfos/getById',
    async (id: string) => {
        const { data } = await new AddressDataService().get(id);
        if (data != null && data.accountNumber == null)
            data.accountNumber = '';

        return data;
    });

export const parseAddress = createAppThunk(
    'participantContactInfos/parse',
    async (address: string) => {
        const { data } = await new AddressDataService().parseAddress(address);
        return data;
    });

export const createOrUpdateAddress = createAsyncThunk(
    'participantContactInfos/createAddress',
    async (participant: AddressDatumDto) => {
        const addressDataService = new AddressDataService();
        const isNewParticipant = participant.id.startsWith('new-address-');
        const { data } = !isNewParticipant
            ? await addressDataService.updateAddress(participant)
            : await addressDataService.createAddress(participant);

        return { ...participant, id: data };
    }
);

export interface UpdateParsedTokenType {
    tokenIndex: number;
    tokenType: TokenType;
    id?: string;
}

export const changeParsedTokenType = createAction<UpdateParsedTokenType>('changeParsedTokenType');
export const setParsedData = createAction<string>('setParsedData');
export const createAddressDatum = createAction<AddressDatumDto>('createAddressDatum');
export const updateAddressDatum = createAction<AddressDatumDto>('updateAddressDatum');
export const clearParsedTokens = createAction('clearParsedTokens');

export const initialState: State = {
    isFetching: false,
    isParsing: false,
    isSaving: false,
    addressDatum: null,
    parsedTokens: []
};

const slice = createSlice({
    name: 'participantContactInfos',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getParticipantContactInfoById.pending,
                state => {
                    state.isFetching = true;
                    state.addressDatum = null;
                })
            .addCase(getParticipantContactInfoById.fulfilled,
                (state, action) => {
                    state.isFetching = false;
                    state.addressDatum = action.payload;
                });

        builder
            .addCase(createAddressDatum,
                (state) => {
                    state.addressDatum = { id: `new-address-${nanoid()}` };
                });

        builder
            .addCase(parseAddress.pending,
                state => {
                    state.isParsing = true;
                    state.parsedTokens = [];
                })
            .addCase(parseAddress.fulfilled,
                (state, action) => {
                    state.isParsing = false;
                    state.parsedTokens = action.payload;
                });

        builder
            .addCase(setParsedData,
                (state, action) => {
                    const parsedTokens = state.parsedTokens;
                    const updatedAddressDatum: AddressDatumDto = {
                        id: state.addressDatum.id,
                        scannedRawData: action.payload
                    };

                    const updateAddressValue = (value: string, parsedToken: AddressTokenDto) => [value, parsedToken.value].join(' ').trim();

                    for (const parsedToken of parsedTokens) {
                        switch (parsedToken.tokenType) {
                            case TokenType.Address:
                                updatedAddressDatum.address = updateAddressValue(updatedAddressDatum.address, parsedToken);
                                break;
                            case TokenType.Street:
                                updatedAddressDatum.address = updateAddressValue(updatedAddressDatum.address, parsedToken);
                                break;
                            case TokenType.House:
                                updatedAddressDatum.address = updateAddressValue(updatedAddressDatum.address, parsedToken);
                                break;
                            case TokenType.Office:
                                updatedAddressDatum.address = updateAddressValue(updatedAddressDatum.address, parsedToken);
                                break;
                            case TokenType.Place:
                                if (parsedToken.id != null) {
                                    updatedAddressDatum.cityId = parsedToken.id;
                                }
                                break;
                            case TokenType.PostalCode:
                                updatedAddressDatum.postCode = updateAddressValue(updatedAddressDatum.postCode, parsedToken);
                                break;
                            case TokenType.Region:
                                break;
                            case TokenType.Country:
                                updatedAddressDatum.countryId = parsedToken.id;
                                break;
                            case TokenType.Name:
                                updatedAddressDatum.name = updateAddressValue(updatedAddressDatum.name, parsedToken);
                                break;
                            case TokenType.AccountNumber:
                                updatedAddressDatum.accountNumber = updateAddressValue(updatedAddressDatum.accountNumber, parsedToken);
                                if (updatedAddressDatum.accountNumber != null) {
                                    updatedAddressDatum.isRequiredAccountNumber = true;
                                }
                                break;
                            case TokenType.Phone:
                                updatedAddressDatum.contact = updateAddressValue(updatedAddressDatum.contact, parsedToken);
                                break;
                            case TokenType.Number:
                                break;
                        }
                    }

                    state.addressDatum = updatedAddressDatum;
                });

        builder
            .addCase(updateAddressDatum,
                (state, action) => {
                    state.addressDatum = { ...action.payload };
                });

        builder
            .addCase(changeParsedTokenType,
                (state, action) => {
                    const { tokenIndex, tokenType, id } = action.payload;
                    const parsedToken = state.parsedTokens[tokenIndex];
                    parsedToken.tokenType = tokenType;
                    parsedToken.id = id;
                });

        builder
            .addCase(createOrUpdateAddress.pending,
                (state) => {
                    state.isFetching = true;
                    state.isSaving = true;
                })
            .addCase(createOrUpdateAddress.fulfilled,
                (state, action) => {
                    state.isFetching = false;
                    state.isSaving = false;
                    state.addressDatum = action.payload;
                });

        builder
            .addCase(clearParsedTokens,
                (state) => {
                    state.parsedTokens = [];
                }
            );
    }
});

export const { reducer } = slice;
export const { actions } = slice;
