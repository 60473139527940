export enum SortDirection
{
    Ascending,
    Descending
}

export class PropertySorter
{
    public path: string;
    public sortDirection: SortDirection;
}

export type PropSorter = {
    path: string,
    sortDirection: SortDirection
}