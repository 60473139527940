import axios from 'axios';
import { tryGetExceptionWithCode } from '@helpers/stringHelpers';
import i18n from '@i18n';

const HttpClient = axios.create();

HttpClient.interceptors.request.use(
	(response) => {
		response.headers['Accept-Language'] = i18n.language;
		return response;
	}
);

HttpClient.interceptors.response.use(
	(response) => response,
	(error) => {
		const e = { ...error };
		e.response.data = { ...(e.response.data || {}), isError: true };

		const exceptionWithCode = tryGetExceptionWithCode(error.response.data?.message);
		if (exceptionWithCode) {
			e.response.data.message = i18n.t(exceptionWithCode.i18nPath, exceptionWithCode.args);
		}

		return Promise.reject(e);
	}
);

export default HttpClient;