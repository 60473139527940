import * as React from 'react';
import { Formik, Field, FormikProps } from 'formik';
import nameof from 'ts-nameof.macro';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { isNullOrEmptyString } from '@helpers/stringHelpers';
import { ContactDto, CreateContact, UpdateContact } from '@models/contacts';
import styles from './PersonalInfoEditor.module.scss';
import StaticSelect from '@components/select/StaticSelect';
import { InteractionTypeDto } from '@models/contacts/InteractionType';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useEffect, useMemo } from 'react';
import { fetchInteractionTypes } from '@store/contactsStore';
import { Loader } from '@components/index';
import i18next from 'i18next';
import ValidationMessage from '@components/ValidationMessage';
import clsx from 'clsx';
//import { JobPositionTypeDto } from '@models/contacts/JobPositionType';
import { HttpError } from '@models/shared';
import { submitFormAndShowErrorsAsync } from '@helpers/FormHelper';
import { createContactShape } from "@models/validationSchemas/contact";

type Model = Partial<ContactDto | UpdateContact | CreateContact>;

export enum EditorMode {
	View,
	Edit,
	Create
}

export type Props<TModel extends Model> = {
	data: Partial<TModel>;
	mode: EditorMode,
	onSubmit: (data: TModel) => Promise<HttpError | null>;
	onSuccess: () => void;
	showCanLogin: boolean;
	onError: (message: string) => void;
	children: (renderEditor: () => JSX.Element, submitButtonClickEvent: () => void) => JSX.Element;
	hideInteractions?: boolean;
};

function PersonalInfoEditor<TModel extends Model>(props: Props<TModel>) {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();

	const {
		//isJobPositionTypesFetching,
		isInteractionTypesFetching,
		//jobPositionTypes,
		interactionTypes,
	} = useAppSelector((x) => x.contacts);

	useEffect(() => {
		// if (!(jobPositionTypes?.length > 0)) {
		// 	dispatch(fetchJobPositionTypes());
		// }
		if (!(interactionTypes?.length > 0)) {
			dispatch(fetchInteractionTypes());
		}
	}, []);

	const renderCheckbox = (label: JSX.Element | string, name: string) => {
		return (
			<div className="form-check">
				<Field name={name}>
					{({field}) =>
						props.mode != EditorMode.View ? (
							<input
								key={name}
								className="form-check-input"
								type="checkbox"
								id={name}
								checked={field.value || false}
								onChange={({target}) => {
									field.onChange({
										target: {
											name: field.name,
											value: target.checked,
										},
									});
								}}
							/>
						) : (
							<div className={styles.formText}>{field.value || false ? 'yes' : 'no'}</div>
						)
					}
				</Field>
				<label className={`${styles.formLabel} control-label`} htmlFor={name}>
					{label}
				</label>
			</div>
		);
	};

	const renderInput = (
		label: JSX.Element | string,
		name: string,
		required: boolean,
		formikProps: FormikProps<Model>
	) => {
		return (
			<label className={clsx(styles.formLabel, `control-label`)} htmlFor={name}>
				{label}
				{required && <sup className="required"/>}
				<Field name={name}>
					{({field}) =>
						props.mode != EditorMode.View ? (
							<>
								<input
									type='text'
									key={field.name}
									className={`${styles.formInput} form-control`}
									id={field.name}
									name={field.name}
									value={field.value || ''}
									onChange={field.onChange}
								/>
								<ValidationMessage name={field.name} errors={formikProps.errors}/>
							</>
						) : (
							<div className={styles.formText}>
								{!isNullOrEmptyString(field.value) ? field.value : ' - '}
							</div>
						)
					}
				</Field>
			</label>
		);
	};

	const renderInteractionTypeSelect = (
		label: string,
		name: string,
		required: boolean,
		formikProps: FormikProps<Model>
	) => {
		return (
			<Field name={name}>
				{({field}) => {
					return (
						<>
							<label
								className={clsx(styles.formLabel, `control-label`, required ? 'required' : null)}
								htmlFor={name}
							>
								{label}
							</label>
							{props.mode != EditorMode.View ? (
								<>
									<StaticSelect<InteractionTypeDto>
										isMulti
										value={field.value != null
											? interactionTypes.filter((y) => field.value?.includes(y.value))
											: []}
										checkboxes={true}
										availableOptions={interactionTypes}
										onChange={(x) => {
											formikProps.setFieldValue(
												name,
												x.map((c) => c.value)
											);
											formikProps.setFieldTouched(name, true);
										}}
										optionLabel={(x) => <>{t(`interactionTypes.${x.key}`)}</>}
										selectedLabel={(x) => <>{t(`interactionTypes.${x.key}`)}</>}
										isSearchable={false}
										placeholder={t('selectInteractionTypes')}
									/>
									<ValidationMessage name={field.name} errors={formikProps.errors}/>
								</>
							) : (
								<div className={styles.formText}>
									{field.value?.length > 0 ? field.value.join(', ') : ' - '}
								</div>
							)}
						</>
					);
				}}
			</Field>
		);
	};

	const renderEditor = (formikProps: FormikProps<Model>) => {
		return (
			<div className="form">
				<Row>
					<Col>
						{renderInput(
							t('firstName'),
							nameof.full<TModel>((x) => x.firstName),
							true,
							formikProps
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						{renderInput(
							t('lastName'),
							nameof.full<TModel>((x) => x.lastName),
							true,
							formikProps
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						{renderInput(
							t('email'),
							nameof.full<TModel>((x) => x.email),
							true,
							formikProps
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						{renderInput(
							t('phone'),
							nameof.full<TModel>((x) => x.phone),
							true,
							formikProps
						)}
					</Col>
				</Row>
				<Row className={props.showCanLogin == false ? 'd-none' : null}>
					<Col>
						{renderCheckbox(
							t('contact.canLogin'),
							nameof.full<TModel>((x) => x.canLogin)
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						{renderInput(
							t('jobPosition'),
							nameof.full<TModel>((x) => x.jobTitle),
							true,
							formikProps
						)}
					</Col>
				</Row>
				{!props.hideInteractions && <Row className={'mt-3'}>
					<Col>
						{renderInteractionTypeSelect(
							t('interactionTypesText'),
							nameof.full<TModel>((x) => x.interactionTypes),
							true,
							formikProps
						)}
					</Col>
				</Row>}
			</div>
		);
	};

	const validationSchema = useMemo(
		() => createContactShape(),
		[ i18next.language ]
	);

	return (
		<Formik<Model>
			enableReinitialize
			initialValues={props.data}
			validateOnChange={false}
			validationSchema={validationSchema}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onSubmit={async (values, {}) => {
				const result = await submitFormAndShowErrorsAsync(values as TModel, props.onSubmit, props.onError);
				if (result.isError) {
					props.onError(result.message);
				} else {
					props.onSuccess();
				}
			}}
		>
			{(formikProps) => {
				return isInteractionTypesFetching ? (
					<Loader/>
				) : (
					props.children(() => renderEditor(formikProps), formikProps.handleSubmit)
				);
			}}
		</Formik>
	);
}

export default PersonalInfoEditor;