import { Guid } from 'guid-typescript';
import React, { useEffect, useRef } from 'react';
import { useField } from 'formik';
import styles from './InputField.module.scss';
import clsx from 'clsx';
import Inputmask from 'inputmask';
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

type FieldProps = {
	type: 'text' | 'textarea' | 'checkbox' | 'radio';
	label?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	labelProps?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inputProps?: any;
	placeholder?: string;
	id?: string;
	name: string;
	hasHint?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	checkValue?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valueConverter?: (value: any) => any;
	mask?: string;
	required?: boolean;
	disabled?: boolean;
};

const InputField = (props: FieldProps) => {
		const [ field, meta, helpers ] = useField(props.name);
		const ref = useRef();

		let InputElement = null;

		const id = props.id || Guid.create().toString();

		let hasHint = false;

		if (props.hasHint == null || props.hasHint == true) {
			hasHint = props.label && props.type != 'checkbox' && props.type != 'radio';
		} else {
			hasHint = false;
		}

		const sharedProps = {
			placeholder: props.placeholder != null ? props.placeholder : props.label,
		};

		if (hasHint) {
			sharedProps['data-tippy-content'] = props.label;
		}

		useEffect(() => {
			if (!ref.current) {
				return;
			}

			if (props.mask)
				Inputmask({mask: props.mask}).mask(ref.current);
			else
				Inputmask.remove(ref.current);
		}, [ props.mask, ref ]);

		switch (props.type) {
			case 'checkbox':
				InputElement = (
					<div className="checkbox">
						<label {...(props.labelProps || {})}>
							<input
								disabled={props.disabled}
								type="checkbox"
								checked={field.value}
								onChange={({target}) => {
									field.onChange({
										target: {
											name: field.name,
											value: target.checked,
										},
									});
								}}
								id={id}
								{...(props.inputProps || {})}
								{...sharedProps}
							/>
							&nbsp;
							{!hasHint ? props.label : null}
						</label>
					</div>
				);
				break;

			case 'radio':
				InputElement = (
					<label {...(props.labelProps || {})}>
						<input
							disabled={props.disabled}
							type="radio"
							checked={field.value?.toString() == props.checkValue?.toString()}
							onChange={({target}) => {
								let val = target.value;
								if (props.valueConverter) {
									val = props.valueConverter(val);
								}
								helpers.setValue(val);
							}}
							id={id}
							{...(props.inputProps || {})}
							{...sharedProps}
							defaultValue={props.checkValue}
						/>
						&nbsp;
						{!hasHint ? props.label : null}
					</label>
				);
				break;

			case 'text':
			default:
				InputElement = (
					<>
						{!hasHint && props.label ? (
							<label className="control-label" htmlFor={id} {...(props.labelProps || {})}>
								{props.label}
							</label>
						) : null}
						<Tippy
							disabled={!hasHint}
							content={props.label}
							placement={'left'}
							trigger={'focusin'}
							theme={'light'}
						>
							<input
								disabled={props.disabled}
								type="text"
								id={id}
								className={clsx(
									styles.styledInput,
									'form-control',
									meta.error ? 'errorField' : null,
									props.required == true ? 'input-required' : null
								)}
								name={field.name}
								onChange={field.onChange}
								placeholder={props.placeholder || ''}
								{...sharedProps}
								value={field.value || ''}
								ref={ref}
							/>
						</Tippy>
						<>
							{meta.error && <span className="validationMessage">{meta.error}</span>}
						</>
					</>
				)

				break;
			case
			'textarea':
				InputElement = (
					<>
						{!hasHint && props.label ? (
							<label className="control-label" htmlFor={id}>
								{props.label}
							</label>
						) : null}
						<Tippy
							disabled={!hasHint}
							content={props.label}
							placement={'left'}
							trigger={'focusin'}
							theme={'light-border'}
						>
						<textarea
							disabled={props.disabled}
							className={clsx(styles.styledInput, 'form-control', props.required == true ? 'input-required' : null)}
							id={id}
							name={field.name}
							value={field.value || ''}
							onChange={field.onChange}
							placeholder={props.placeholder || props.label}
						/>
						</Tippy>
						{meta.error && <span className="validationMessage">{meta.error}</span>}
					</>
				)
				break;
		}

		return <>{InputElement}</>;
	}
;

export default InputField;
