/* eslint-disable @typescript-eslint/no-empty-function */
import { RouteComponentProps, withRouter } from 'react-router';
import { WithTranslation, withTranslation } from 'react-i18next';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import '@assets/reactCalendar.scss';
import AirlineSelect from '@components/select/AirlineSelect';
import { Col, CustomInput, Row } from 'reactstrap';
import SessionManager from '../../../SessionManager';
import { UserRoles } from '@config/ApiAuthorizationConstants';
import StandardNavigationSelect from '@components/select/StandardNavigationSelect';
import { OrganizationDictionaryItem } from '@models/organizations';
import OrganizationsService from '@services/OrganizationsService';
import LocationSelect from '@components/select/LocationSelect';
import LocationsService from '@services/LocationsService';
import { LocationDto, LocationType } from '@models/locations';

export interface IDateFilterArgs {
	from?: string;
	to?: string;
}

export interface IPriceFilterArgs {
	from: number;
	to: number;
}

type State = {
	calendarFocusedInput: 'endDate' | 'startDate' | null;
	organizations: OrganizationDictionaryItem[];
	numberValue?: string;
};

const minNumberDigitsRequired = 3;

type Props = {
	dateFilter?: IDateFilterArgs;
	priceFilter?: IPriceFilterArgs;
	onChangeDateFilter?: (newValues: IDateFilterArgs) => void;
	onChangePriceFilter?: (newValues: IPriceFilterArgs) => void;
	onChangeAirlineFilter?: (airlineIds: string[]) => void;
	onChangeNumberFilter?: (term?: string) => void;
	onChangeOrganizationsFilter?: (organizationIds: string[]) => void;
	onChangeLocationFromFilter?: (locations: LocationDto[]) => void;
	onChangeLocationToFilter?: (locations: LocationDto[]) => void;
	onClearFilters?: () => void;
	onChangeShowOwn?: (showOwn: boolean) => void;
	showOwn?: boolean;
	selectedLocationsFrom: LocationDto[];
	selectedLocationsTo: LocationDto[];
} & WithTranslation &
	RouteComponentProps<{}>;

class CustomerApplicationHistoryFilter extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {
			calendarFocusedInput: null,
			numberValue: null,
			organizations: [],
		};

		this.airlineSelectRef = React.createRef();
		this.numberRef = React.createRef();
	}

	airlineSelectRef: React.RefObject<AirlineSelect>;
	numberRef: React.RefObject<HTMLInputElement>;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
	UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {}

	clearFilters = () => {
		if (this.props.onClearFilters) {
			this.props.onClearFilters();
		}

		this.airlineSelectRef.current.reset();

		this.setState({
			calendarFocusedInput: null,
			numberValue: null,
			organizations: [],
		});
	};

	onChangeOrganizations = (organizations: OrganizationDictionaryItem[]) => {
		this.setState({ organizations });
		if (this.props.onChangeOrganizationsFilter) {
			this.props.onChangeOrganizationsFilter(organizations.map((x) => x.id));
		}
	};

	onChangeShowOwn = (showOwn: boolean) => {
		if (this.props.onChangeShowOwn) {
			this.props.onChangeShowOwn(showOwn);
		}
	};

	render() {
		return (
			<div className={userItemsPageStyles.filter__block}>
				<div className={userItemsPageStyles.filter__top}>
					<span className={userItemsPageStyles.filter__title}>
						{this.props.t('customerApplicationHistory.filterOptions')}
					</span>
					<a
						href="#"
						className={userItemsPageStyles.filter__clear}
						onClick={(e) => {
							e.preventDefault();
							this.clearFilters();
						}}
					>
						{this.props.t('customerApplicationHistory.clearFilters')}
					</a>
				</div>

				<div className={userItemsPageStyles.filter__body}>
					<Row>
						<Col sm={6}>
							<div className={userItemsPageStyles.filter__select}>
								<span className={userItemsPageStyles.filter__item__title}>
									{this.props.t('airline')}
								</span>
								<AirlineSelect
									ref={this.airlineSelectRef}
									onChange={(x) =>
										this.props.onChangeAirlineFilter ? this.props.onChangeAirlineFilter(x) : null
									}
									loadingMessage={this.props.t('options.loadingOptions')}
									noOptionsMessage={this.props.t('options.noOptions')}
									placeholder={this.props.t('customerApplicationHistory.selectAirlines')}
								/>
							</div>
						</Col>

						<Col sm={6}>
							<div className={userItemsPageStyles.filter__number}>
								<span className={userItemsPageStyles.filter__item__title}>
									{this.props.t('customerApplicationHistory.number')}
								</span>
								<input
									className="form-control"
									ref={this.numberRef}
									value={this.state.numberValue || ''}
									onChange={(x) => {
										const value = x.target.value;
										this.setState({ numberValue: value });
										if (this.props.onChangeNumberFilter != null) {
											this.props.onChangeNumberFilter(value);
										}
									}}
									placeholder={this.props.t('customerApplicationHistory.enterNumber')}
								/>
								{this.state.numberValue && this.state.numberValue.length < minNumberDigitsRequired && (
									<span className="text-danger">
										{this.props
											.t('customerApplicationHistory.minNumberDigitsRequired')
											.replace('{value}', minNumberDigitsRequired + '')}
									</span>
								)}
							</div>
						</Col>
					</Row>

					<Row>
						<Col sm={6}>
							<div className={userItemsPageStyles.filter__calendar}>
								<span className={userItemsPageStyles.filter__item__title}>
									{this.props.t('customerApplicationHistory.selectDateRange')}
								</span>
								<DateRangePicker
									focusedInput={this.state.calendarFocusedInput}
									isOutsideRange={() => null}
									startDatePlaceholderText={this.props.t('customerApplicationHistory.startDate')}
									endDatePlaceholderText={this.props.t('customerApplicationHistory.endDate')}
									startDate={
										this.props?.dateFilter?.from
											? moment(this.props.dateFilter.from).startOf('day')
											: null
									}
									endDate={
										this.props?.dateFilter?.to
											? moment(this.props.dateFilter.to).endOf('day')
											: null
									}
									startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
									endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
									displayFormat={() => 'DD/MM/YYYY'}
									customArrowIcon={<i className={`calendarArrowIcon icon-arrow-long`} />}
									inputIconPosition="before"
									showDefaultInputIcon={true}
									customInputIcon={<i className={`calendarInputIcon icon-calendar`} />}
									onDatesChange={({ startDate, endDate }) => {
										const from = startDate?.startOf('day').toISOString(true);
										const to = endDate?.endOf('day').toISOString(true);

										this.props.onChangeDateFilter({ from, to });
									}}
									onFocusChange={(inputName) => {
										this.setState({ calendarFocusedInput: inputName });
									}}
								/>
							</div>
						</Col>

						<Col sm={6}>
							{SessionManager.isAuthenticated() &&
							SessionManager.user.hasAnyRole(UserRoles.superuser, UserRoles.tenantAdmin) ? (
								<div className={userItemsPageStyles.filter__organizations}>
									<span className={userItemsPageStyles.filter__item__title}>
										{this.props.t('customerApplicationHistory.organizations')}
									</span>
									<StandardNavigationSelect<OrganizationDictionaryItem>
										onChange={(x) => this.onChangeOrganizations(x)}
										optionLabel={(x) => <span>{x?.name}</span>}
										selectedLabel={(x) => <span>{x?.name}</span>}
										fetch={(nav) => new OrganizationsService().dictionary(nav)}
										filterPropertyName={'name'}
										getUniqueOptionValue={(x) => x.id}
										value={this.state.organizations}
										placeholder={this.props.t('customerApplicationHistory.searchOrganizations')}
										isMulti
									/>
								</div>
							) : (
								<div className={userItemsPageStyles.filter__switch}>
									<span className={userItemsPageStyles.filter__item__title}>
										{this.props.t('viewMode.title')}
									</span>
									<CustomInput
										// eslint-disable-next-line @typescript-eslint/no-unused-vars
										onChange={(e) => this.onChangeShowOwn(!this.props.showOwn)}
										className="custom-switch-md"
										type="switch"
										id="exampleCustomSwitch"
										name="customSwitch"
										label={this.props.t('viewMode.own')}
										checked={this.props.showOwn == null ? false : this.props.showOwn}
									/>
								</div>
							)}
						</Col>
					</Row>

					<Row>
						<Col md={6}>
							<div>
								<span className={userItemsPageStyles.filter__item__title}>
									{this.props.t('customerRequestsHistory.source')}
								</span>
								<LocationSelect
									isClearable={true}
									isMulti={true}
									fetch={(term) =>
										new LocationsService().getKnownLocations(term, LocationType.Airport, true)
									}
									onChange={(x) =>
										this.props.onChangeLocationFromFilter
											? this.props.onChangeLocationFromFilter(x)
											: null
									}
									loadingMessage={this.props.t('options.loadingOptions')}
									noOptionsMessage={this.props.t('options.noOptions')}
									placeholder={this.props.t('customerApplicationHistory.airportNameOrIata')}
									selectedOptions={this.props.selectedLocationsFrom}
									initializeWithEmptyTerm={true}
								/>
							</div>
						</Col>
						<Col md={6}>
							<div>
								<span className={userItemsPageStyles.filter__item__title}>
									{this.props.t('customerRequestsHistory.target')}
								</span>
								<LocationSelect
									isClearable={true}
									isMulti={true}
									fetch={(term) =>
										new LocationsService().getKnownLocations(term, LocationType.Airport, true)
									}
									onChange={(x) =>
										this.props.onChangeLocationToFilter
											? this.props.onChangeLocationToFilter(x)
											: null
									}
									loadingMessage={this.props.t('options.loadingOptions')}
									noOptionsMessage={this.props.t('options.noOptions')}
									placeholder={this.props.t('customerApplicationHistory.airportNameOrIata')}
									selectedOptions={this.props.selectedLocationsTo}
									initializeWithEmptyTerm={true}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(withTranslation()(CustomerApplicationHistoryFilter));