import { ContactPermissionListItemDto, UpdateContactsPermissions } from '@models/contacts';
import { ContactPermissionType, ContactPermissionTypeDto } from '@models/contacts/ContactPermissionType';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@i18n';
import orderBy from 'lodash/orderBy';
import { HttpError } from '@models/shared';
import { Dictionary } from '@core/models';

export type Props = {
	data: ContactPermissionListItemDto[];
	permissionTypes: ContactPermissionTypeDto[];
	onSubmit: (data: UpdateContactsPermissions) => Promise<HttpError | null>;
	children: (submitButtonClickEvent: () => void) => JSX.Element;
	onChange: () => void;
};

const toggleContactPermission = (
	entries: ContactPermissionListItemDto[],
	contactIds: string[],
	permissionType: ContactPermissionType,
	allPermissionTypes: ContactPermissionTypeDto[],
	add: boolean
): ContactPermissionListItemDto[] => {
	const newEntries = entries.filter((x) => !contactIds.includes(x.id));

	entries
		.filter((x) => contactIds.includes(x.id))
		.forEach((entry) => {
			const newEntry = { ...entry };

			if (add) {
				if (permissionType == ContactPermissionType.All) {
					newEntry.permissions = allPermissionTypes.map((x) => x.value);
				} else {
					const newPermissions = [...newEntry.permissions, permissionType];

					if (
						allPermissionTypes
							.map((x) => x.value)
							.filter((x) => x > ContactPermissionType.All)
							.every((x) => newPermissions.includes(x))
					) {
						newPermissions.push(ContactPermissionType.All);
					}

					newEntry.permissions = newPermissions;
				}
			} else {
				if (permissionType == ContactPermissionType.All) {
					newEntry.permissions = [];
				} else {
					newEntry.permissions = newEntry.permissions.filter(
						(x) => x != permissionType && x != ContactPermissionType.All
					);
				}
			}

			newEntries.push(newEntry);
		});

	return newEntries;
};

// const toggleAllPermissionsForContacts = (
// 	entries: ContactPermissionListItemDto[],
// 	contactAllPermissionsMap: Dictionary<boolean>,
// 	isTrue: boolean,
// 	contactIds?: string[]
// ): Dictionary<boolean> => {
//
// 	let q = entries;
// 	if (contactIds != null) {
// 		q = entries.filter((x) => contactIds.includes(x.id));
// 	}
//
// 	q.forEach((entry, i) => {
// 		contactAllPermissionsMap[entry.id] = isTrue;
// 	});
//
// 	return contactAllPermissionsMap;
//
// 	//setContactAllPermissionsMap(map);
// };

const PermissionsEditor = (props: Props) => {
	const { t } = useTranslation();

	const [entries, setEntries] = useState<ContactPermissionListItemDto[]>([]);

	useEffect(() => {
		if (!(props.data?.length > 0 && props.permissionTypes?.length > 0)) {
			return;
		}

		const newEntries = [];

		const allPermissions = props.permissionTypes.map((x) => x.value).filter((x) => x > ContactPermissionType.All);

		props.data.forEach((entry) => {
			const newEntry = { ...entry };

			if (newEntry.permissions.includes(ContactPermissionType.All)) {
				newEntry.permissions = [ContactPermissionType.All, ...allPermissions];
			}

			newEntries.push(newEntry);
		});

		setEntries(newEntries);
	}, [props.data, props.permissionTypes]);

	const permissionTypes = useMemo(() => {
		const result = [...props.permissionTypes];
		return orderBy(
			result.filter((x) => x.value > ContactPermissionType.None),
			(x) => x.value
		);
	}, [props.permissionTypes]);

	const generalPermissionsMap: Dictionary<boolean> = useMemo(() => {
		const map: Dictionary<boolean> = {};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		permissionTypes.forEach((permissionType, i) => {
			map[permissionType.value] = entries.every((x) => x.permissions.includes(permissionType.value));
		});
		return map;
	}, [entries]);

	const toggleGeneralPermission = (permissionType: ContactPermissionType) => {
		const turnOff = generalPermissionsMap[permissionType];

		const modifiedEntries = toggleContactPermission(
			entries,
			entries.map((x) => x.id),
			permissionType,
			permissionTypes,
			!turnOff
		);

		props.onChange();

		setEntries(modifiedEntries);
	};

	const permissionTypesThs = useMemo(() => {
		const ths1 = [],
			ths2 = [];

		permissionTypes.forEach((x, i) => {
			ths1.push(<th key={i}>{t(`contactPermissionTypes.${x.key}`)}</th>);
			ths2.push(
				<th key={i}>
					<input
						type="checkbox"
						id={`general_${x.key}`}
						checked={generalPermissionsMap[x.value]}
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						onChange={({ target }) => {
							toggleGeneralPermission(x.value);
							//generalPermissionsMap(x.value);
							// if(x.value == ContactPermissionType.All){
							// 	toggleAllPermissionsForContacts(entries, generalPermissions.includes(x.value) != true);
							// }
						}}
					/>
				</th>
			);
		});

		return { ths1, ths2 };
	}, [entries, permissionTypes, generalPermissionsMap, i18n.language]);

	// const processGeneralPermissions = (
	// 	entries: ContactPermissionListItemDto[],
	// 	generalPermissions: ContactPermissionType[]
	// ) => {
	// 	// Process general permissions.
	// 	let newGeneralPermissions: ContactPermissionType[] = [...generalPermissions];
	// 	permissionTypes.forEach((permissionType) => {
	// 		if (
	// 			entries.every(
	// 				(y) =>
	// 					y.permissions.includes(permissionType.value) ||
	// 					y.permissions.includes(ContactPermissionType.All)
	// 			)
	// 		) {
	// 			newGeneralPermissions.push(permissionType.value);
	// 		} else {
	// 			newGeneralPermissions = newGeneralPermissions.filter((x) => x != permissionType.value);
	// 		}
	// 	});
	// };

	const trs = useMemo(() => {
		const results: JSX.Element[] = [];

		if (entries.length == 0 || permissionTypes.length == 0) {
			return <></>;
		}

		orderBy(entries, (x) => x.id).forEach((contact, i) => {
			const nameTh = (
				<th key={i}>
					{contact.firstName} {contact.lastName}
				</th>
			);
			const checkboxesTds = [];

			permissionTypes.forEach((permissionType, i) => {
				if (permissionType.value == ContactPermissionType.All) {
					// All flag.

					const isChecked = contact.permissions.includes(ContactPermissionType.All);

					checkboxesTds.push(
						<td key={i}>
							<input
								type="checkbox"
								id={`${contact.id}_${permissionType.key}`}
								checked={isChecked}
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								onChange={({ target }) => {
									const add = !contact.permissions.includes(permissionType.value);

									let modifiedEntries = [...entries];

									modifiedEntries = toggleContactPermission(
										modifiedEntries,
										[contact.id],
										permissionType.value,
										permissionTypes,
										add
									);

									props.onChange();

									// let allPermissionsMap: Dictionary<boolean> = {...contactAllPermissionsMap};
									// allPermissionsMap = toggleAllPermissionsForContacts(modifiedEntries, allPermissionsMap, add, [contact.id]);

									//processGeneralPermissions(modifiedEntries, generalPermissions);

									setEntries(modifiedEntries);
								}}
							/>
						</td>
					);
					return;
				}

				const isChecked = contact.permissions.includes(permissionType.value);

				// Other flags.
				checkboxesTds.push(
					<td key={i}>
						<input
							type="checkbox"
							id={`${contact.id}_${permissionType.key}`}
							checked={isChecked}
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							onChange={({ target }) => {
								const add = !contact.permissions.includes(permissionType.value);

								let modifiedEntries = [...entries];

								modifiedEntries = toggleContactPermission(
									modifiedEntries,
									[contact.id],
									permissionType.value,
									permissionTypes,
									add
								);

								props.onChange();
								//processGeneralPermissions(modifiedEntries, generalPermissions);

								setEntries(modifiedEntries);
							}}
						/>
					</td>
				);
			});

			results.push(
				<tr key={i}>
					{nameTh}
					{checkboxesTds}
				</tr>
			);
		});

		return results;
	}, [entries, permissionTypes, generalPermissionsMap]);

	const submitButtonClickEvent = () => {
		const results: UpdateContactsPermissions['permissions'] = [];
		entries.forEach((contact) => {
			let permissions = [...contact.permissions];

			const allPermissions =
				permissionTypes
					.map((x) => x.value)
				.filter(x => x > ContactPermissionType.All);

			if (permissions.length > 0) {
				permissions = permissions.filter((x) => x != ContactPermissionType.None);

				if (allPermissions.every((x) => permissions.includes(x)) ||
					permissions.includes(ContactPermissionType.All)
				) {
					permissions = [ContactPermissionType.All];
				}
			} else {
				permissions = [ContactPermissionType.None];
			}

			results.push({ contactId: contact.id, permissions });
		});
		props.onSubmit({ permissions: results });
	};

	return (
		<>
			<table className="table">
				<thead>
					<tr>
						<th rowSpan={2}>{t('employee')}</th>
						{permissionTypesThs.ths1}
					</tr>
					<tr>{permissionTypesThs.ths2}</tr>
				</thead>
				<tbody>{trs}</tbody>
			</table>
			{props.children(submitButtonClickEvent)}
		</>
	);
};

export default PermissionsEditor;