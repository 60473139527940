import {ReactComponent as UploadCloudSvg} from "@assets/svg/upload-cloud.svg";
import AppModal from "@components/AppModal";
import styles from "@components/Upload.module.scss";
import React, {useCallback} from "react";
import {DropzoneOptions, useDropzone} from "react-dropzone";
import clsx from "clsx";
import {Progress} from "reactstrap";
import {useTranslation} from "react-i18next";

type AwbUploadModalProps = {
    isOpen: boolean;
    onUpload: (file: File) => void;
    onClickCloseButton: () => void;
    uploadProgress: number;
    mode: AwbUploadingMode;
};

export enum AwbUploadingMode {
    None,
    Uploading,
    Recognizing,
    Completed
}

const AwbUploadModal = (props: AwbUploadModalProps) => {
    
    const {t} = useTranslation();
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
    }, []);
    
    const dropzoneOpts: DropzoneOptions = {
        onDrop,
        accept: ['image/jpeg', 'image/png', 'application/pdf'],
        multiple: false,
        onDropAccepted: (files: File[]) => {
            props.onUpload(files[0]);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onDropRejected: fileRejections => {
            alert(t('awb.cantUploadFile'))
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone(dropzoneOpts);
    
    const renderByMode = () => {
        switch (props.mode){
            case AwbUploadingMode.None:
                return isDragActive ?
                    <>
                        <div className={clsx(styles.text, isDragActive ? styles.textDraggable : null)}>
                            {t('upload.dropFilesHere')}
                        </div>
                    </> :
                    <>
                        <div className={styles.fileFormats}>
                            .pdf .jpg .png
                        </div>
                        <div className={styles.text}>
                            {t('upload.dragAndDropFileHere')}
                        </div>
                    </>
            case AwbUploadingMode.Uploading:
                return <>
                    <div className="text-center">{props.uploadProgress}%</div>
                    <Progress value={props.uploadProgress} />
                </>;
            case AwbUploadingMode.Recognizing:
                return <>
                    <div className="text-center">{t('awb.recognizing')}</div>
                    <Progress animated color="info" value={100} />
                </>;
            case AwbUploadingMode.Completed:
                return <>
                    <div className={styles.text}>{t('awb.recognizingSuccessful')}</div>
                </>;
        }
    }

    return <AppModal
        isOpen={props.isOpen}
        body={<div className={styles.modalBody}>
            
            <div className={styles.title}>{t('awb.uploadFileWithAwb')}</div>
            
            <div className={clsx(styles.uploadField, isDragActive ? styles.uploadFieldDraggable : null)} {...getRootProps()}>                
                <input {...getInputProps()} />                
                <div className={styles.imgWrapper}>                    
                    
                    <UploadCloudSvg/>
                    
                    {renderByMode()}
                    
                </div>
            </div>
            
        </div>}
        onClickCloseButton={props.onClickCloseButton}
    />;
};

export default AwbUploadModal;