import { useField } from "formik";
import nameof from "ts-nameof.macro";
import { AirwaybillDto } from "@models/awbs/awbsModels";
import React from "react";

export const Requirement = (props: { disabled: boolean, placeholder: string }) => {
    const [field] = useField(nameof<AirwaybillDto>((x) => x.handlingInformationRequirements));

    return <textarea
        className={'form-control'}
        disabled={props.disabled}
        name={nameof<AirwaybillDto>((x) => x.handlingInformationRequirements)}
        placeholder={props.placeholder}
        value={field.value || ''}
        onChange={field.onChange}
    />;
}