import styles from './index.module.scss';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import AppModal from '@components/AppModal';
import { Col, Row } from 'reactstrap';

interface IColumnConfig<T> {
    title: string;
    getValue: (value: T) => string;
    formField: (editedModel: T, setValue: (model: T) => void, index: number) => React.ReactNode;
    hideTitleInForm?: boolean;
}

interface Props<T> {
    formTitle: string;
    fieldName: string;
    columns: IColumnConfig<T>[];
    emptyModel: T;
    disabled?: boolean;
    onDelete: (valueIndex: number) => void;

    onSave(valueIndex: number, editedModel: T): void;

    onFormShow?: () => void;
}

export const TableWithForm = <T extends {}>(props: Props<T>): JSX.Element => {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = React.useState(- 1);
    const [editedModel, setEditedModel] = useState<T>(null);
    const [errors, setErrors] = useState<string[]>([]);

    const [field] = useField<T[]>(props.fieldName);

    useEffect(() => {
        let current = true;
        if (current && editedModel != null && props.onFormShow) {
            props.onFormShow();
        }

        return () => {
            current = false;
        };
    }, [editedModel]);

    const onCancel = () => {
        setSelectedIndex(- 1);
        setEditedModel(null);
    };

    return <>
        <table className={styles.ociTable}>
            <thead>
            <tr>
                {props.columns.map((x) => <th key={x.title}>{x.title}</th>)}
                {!props.disabled && <th>
                    <button className={clsx('btn btn-primary', styles.buttonAdd)} onClick={e => {
                        e.preventDefault();
                        setSelectedIndex(- 1);
                        setEditedModel(props.emptyModel);
                    }}>{t('add')}
                    </button>
                </th>}
            </tr>
            </thead>
            <tbody>
            {field.value?.map((x, idx) => {
                return <tr key={idx}>
                    {props.columns.map((column, idx) => <td key={idx}>{column.getValue(x)}</td>)}
                    {!props.disabled && <td>
                        <div className={styles.ociButtons}>
                            <a className={styles.button} onClick={(e) => {
                                e.preventDefault();
                                setSelectedIndex(idx);
                                setEditedModel(x);
                            }}><i className={'icon-edit'} /></a>
                            <a className={styles.button} onClick={(e) => {
                                e.preventDefault();
                                props.onDelete(idx);
                            }}><i className={'icon-trash'} /></a>
                        </div>
                    </td>}
                </tr>;
            })}
            </tbody>
        </table>

        {!props.disabled && <AppModal
            isOpen={editedModel != null}
            onClickCloseButton={onCancel}
            body={<>
                <h4>{props.formTitle}</h4>
                {props.columns.map((column, idx) => <Row key={idx}>
                    <Col>
                        {!column.hideTitleInForm && <h3>{column.title}</h3>}
                        {column.formField(editedModel, setEditedModel, selectedIndex)}
                    </Col>
                </Row>)}
            </>}
            footer={<div className={'w-100'}>
                <button className={'btn btn-danger d-block float-left px-4'} onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                }}>{t('cancel')}</button>
                {Object.keys(errors).some(key => errors[key] != null) == false &&
                    <button className={'btn btn-primary btn-success btn-green d-block float-right px-5'}
                            onClick={(e) => {
                                e.preventDefault();
                                props.onSave(selectedIndex, editedModel);
                                onCancel();
                            }}>{t('ok')}</button>}
            </div>}
        />}
    </>;
};