import React, { ReactNode, useMemo, useState } from 'react';
import {ReactComponent as IconPlane} from '@material-design-icons/svg/round/travel_explore.svg';
import {ReactComponent as IconAccount} from '@material-design-icons/svg/round/manage_accounts.svg';
import {ReactComponent as IconHistoryRequest} from '@material-design-icons/svg/round/history.svg';
import {ReactComponent as IconHistoryBooking} from '@material-design-icons/svg/round/airplane_ticket.svg';
import {ReactComponent as IconDb} from '@material-design-icons/svg/round/dns.svg';
import {ReactComponent as IconAdmin} from '@material-design-icons/svg/round/admin_panel_settings.svg';
import { useTranslation } from 'react-i18next';
import { Container, NavbarBrand, NavItem, NavLink, Popover, PopoverBody } from 'reactstrap';
import { Link, NavLink as RRNavLink, useLocation } from 'react-router-dom';
import styles from './LeftMenu.module.scss';
import SessionManager from '../SessionManager';
import { UserRoles } from '@config/ApiAuthorizationConstants';
import { miniLogo } from '@helpers/tenantsHelper';
import { routes } from '@root/constants';

type MenuItem = {
  isVisible: boolean;
  id: string;
  icon: ReactNode;
  link: string;
  label: string;
};
type MenuItems = Array<MenuItem>;

const LeftMenu = (): JSX.Element => {
  const [state, setState] = useState({ popovers: {} });
  const { pathname } = useLocation();
  const { popovers } = state;
  const { t } = useTranslation();

  const showPopover = (id: string, show: boolean): void => {
    setState({
      ...state,
      popovers: {
        [id]: show,
      },
    });
  };

  const menuItems = useMemo<MenuItems>(
    () =>
      [
        {
          label: t('leftMenu.sendRequest'),
          id: 'request-link',
          icon: <IconPlane fill="currentColor" />,
          link: routes.request,
          isVisible: true,
        },
        {
          label: t('leftMenu.account'),
          id: 'account',
          icon: <IconAccount fill="currentColor" />,
          link: routes.account,
          isVisible: true,
        },
        {
          label: t('leftMenu.bookings'),
          id: 'booking-link',
          icon: <IconHistoryBooking fill="currentColor" />,
          link: routes.bookingHistory,
          isVisible: true,
        },
        {
          label: t('leftMenu.requestsHistory'),
          id: 'request-history-link',
          icon: <IconHistoryRequest fill="currentColor" />,
          link: routes.requestsHistory,
          isVisible: true,
        },
        {
          label: t('leftMenu.awbPanel'),
          id: 'awb-panel',
          icon: <IconDb fill="currentColor" />,
          link: routes.awbList,
          isVisible: SessionManager.user.hasAnyRole(UserRoles.awbEditor),
        },
        {
          label: t('leftMenu.adminPanel'),
          id: 'admin-panel',
          icon: <IconAdmin fill="currentColor" />,
          link: routes.adminPanel,
          isVisible: SessionManager.user.hasAnyRole(UserRoles.superuser, UserRoles.tenantAdmin),
        },
      ], [],
  );

  return (
    <Container className={styles.leftMenu}>
      <NavbarBrand id='public-site' tag={Link} to='/' onMouseEnter={(): void => showPopover('public-site', true)}
                   onMouseLeave={(): void => showPopover('public-site', false)} className={styles.navbarBrand}>
        <img className={styles.miniLogo} src={miniLogo} />
        <Popover placement='right' target='public-site' isOpen={popovers['public-site']}>
          <PopoverBody>{t('leftMenu.publicSite')}</PopoverBody>
        </Popover>
      </NavbarBrand>

      <ul className='navbar-nav flex-column'>
        {menuItems.map((el) =>
          el.isVisible ? (
            <NavItem key={el.id} id={el.id} onMouseEnter={(): void => showPopover(`${el.id}`, true)}
                     onMouseLeave={(): void => showPopover(`${el.id}`, false)}>
              <NavLink tag={RRNavLink} activeClassName={`${el.link === pathname && styles.activeLink}`}
                       className={styles.navLink} to={el.link}>
                <span className={styles.icon}>{el.icon}</span>
              </NavLink>
              <Popover placement='right' target={el.id} isOpen={popovers[`${el.id}`]}>
                <PopoverBody>{el.label}</PopoverBody>
              </Popover>
            </NavItem>
          ) : null,
        )}
      </ul>
    </Container>
  );
};

export default LeftMenu;