import React from 'react';
import { useTranslation } from 'react-i18next';

type FieldErrorProps = {
	error: string; // error message or resource key
	isTouched: boolean;
};

const FieldError = (props: FieldErrorProps): JSX.Element => {
	const { error, isTouched } = props;
	const { t, i18n } = useTranslation();
	return (
		<>
			{error && isTouched ? (
				<div style={{color: 'red'}}>
					{i18n.exists(error) ? t(error) : error}
				</div>
			) : null}
		</>
	);
};

export default FieldError;