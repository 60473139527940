import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import CustomerApplicationsService from '@services/CustomerApplicationsService';
import {IPagingWrapper} from "@models/entityNavigation/IPagingWrapper";
import {
	CustomerApplicationEventDto,
	GetCustomerApplicationEventLog
} from "@models/customerApplications/customerApplicationEventsModels";

export type State = {
	isFetching: boolean;
	pagingWrapper: IPagingWrapper<CustomerApplicationEventDto>;
};

export const fetchEvents = createAsyncThunk(
	'customerApplicationEventLog/fetchEvents',
	async (arg: GetCustomerApplicationEventLog) => {
		const {data} =
			await new CustomerApplicationsService()
				.getEvents(arg);
		return data;
	});

const slice = createSlice({
	name: 'customerApplicationEventLog',
	initialState: {
		isFetching: true,
		pagingWrapper: {
			items: [],
			totalCount: 0
		}
	} as State,
	reducers: {
		clear: (state: State) => {
			state.pagingWrapper = {
				items: [],
				totalCount: 0
			};
		}
	},
	extraReducers: builder => {

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchEvents.pending, (state, action) => {
			state.isFetching = true;
		});

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchEvents.rejected, (state, action) => {
			state.isFetching = false;
		});

		builder.addCase(fetchEvents.fulfilled, (state, action) => {
			state.isFetching = false;
			state.pagingWrapper = action.payload;
		});

	}
});

export const {reducer} = slice;
export const {actions} = slice;