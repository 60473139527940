import React, { useEffect, useState } from 'react';
import CustomerApplicationInfoHeader from '@scenes/customerApplication/components/CustomerApplicationInfoHeader';
import { Badge, Button, InfoCard, Loader, PageHeader, TextArea } from '@root/components';
import { useSelector } from 'react-redux';
import { ApplicationState, useAppDispatch, useAppSelector } from '@root/store';
import * as customerApplicationsStore from '@store/customerApplications/customerApplicationsStore';
import {
    addComment,
    clearBookingData,
    fetchCustomerApplication, fetchCustomerRequest, fetchSale,
} from '@store/customerApplications/customerApplicationsStore';
import { useTranslation } from 'react-i18next';
import s from './booking.module.scss';
import { ReactComponent as SendIcon } from '@material-design-icons/svg/round/send.svg';
import { useParams } from 'react-router';
import { BookingConfirmation, Comments, GeneralData, Status, TraceHistory } from '@scenes/booking/components';
import EventLogPage from '@scenes/customerApplication/eventLog/EventLogPage';
import { routes } from '@root/constants';
//import { getTrackAndTraceInfoAsync, resetTrack } from '@store/trackAndTrace';
import { Awb, ComingSoon } from './components/Cards';

const Booking: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [commentText, setCommentText] = useState('');
    //const { traceLoading } = useAppSelector(appState => appState.tracking);
    const [isEnabled] = useState(true);
    const { id } = useParams<{ id: string }>();
    const { customerApplication } = useSelector<ApplicationState, customerApplicationsStore.State>(
        (x) => x.customerApplications,
    );

    const onAddComment = () => {
        dispatch(addComment({ customerApplicationId: id, body: commentText }));
        setCommentText('');
    };

    useEffect(() => {
        dispatch(fetchCustomerApplication({ customerApplicationId: id }));
    }, [id]);

    useEffect(() => {
        return () => {
            //dispatch(resetTrack());
            dispatch(clearBookingData());
        };
    }, []);

    return (
        <>
            <PageHeader
                backUrl={routes.bookingHistory}
                backText={t('backToList')}
                title={`${t('customerApplication.booking')}${customerApplication?.airwaybillNumber ? ' подтверждено' : ' запрошено'}`}
                badge={
                    <Badge
                        label={
                            customerApplication?.airwaybillPreview?.number ||
                            customerApplication?.airwaybillNumber
                        }
                        color='blue'
                        size='large'
                    />
                }
            />

            <div className={s.container}>
                <CustomerApplicationInfoHeader />
                <BookingConfirmation customerApplicationId={id} />
                <div className={s.columns}>
                    <div className={s.columnsLeft}>
                        <InfoCard classNames={s.tileCell} title={t('requestSearchResults.status')} expandable>
                            <Status />
                        </InfoCard>
                        <div className={s.tile}>
                            <div className={s.tileCell}>
                                <InfoCard title={t('awb.awb')} expandable>
                                    <Awb />
                                </InfoCard>
                            </div>
                            <div className={s.tileCell}>
                                <InfoCard title={t('payment.title')} expandable>
                                    <ComingSoon />
                                </InfoCard>
                            </div>
                        </div>
                    </div>
                    <div className={s.columnsRight}>
                        {isEnabled && customerApplication?.airwaybillNumber &&
                            <InfoCard title={t('trackHistory.title')} expandable>
                                <TraceHistory airWaybillNumber={customerApplication?.airwaybillNumber} />
                            </InfoCard>
                        }
                        <InfoCard title={t('generalData.data')} expandable>
                            <GeneralData />
                        </InfoCard>
                        <InfoCard title={`${t('comments.title')} (${customerApplication?.comments?.length})`}
                                  expandable>
                            <div className={s.commentsContainer}>
                                <Comments comments={customerApplication?.comments} />
                                <div className={s.textArea}>
                                    <TextArea
                                        placeholder={t('comments.enterComment')}
                                        value={commentText}
                                        onChange={setCommentText}
                                    />
                                    <Button
                                        onClick={onAddComment}
                                        disabled={!commentText}
                                        icon={<SendIcon fill='currentColor' />}
                                        type='fill'
                                        variant='primary'>{t('comments.send')}</Button>
                                </div>
                            </div>
                        </InfoCard>
                        <InfoCard title={t('customerApplicationEventLog.title')} expandable>
                            <EventLogPage customerApplicationId={id} />
                        </InfoCard>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Booking;