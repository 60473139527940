import React, { useEffect, useRef, useState } from 'react';
import { welcomeImages } from '@helpers/tenantsHelper';
import styles from './index.module.scss';
import classNames from 'classnames';
import { ModalDialog } from '@components/ModalDialog';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchLatestReviews } from '@store/reviewStore';
import DemoForm from '@components/demoForm';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';

import Carousel from '@components/Carousel';

const cx = classNames.bind(styles);

const FFCargoWelcomePage = (): JSX.Element => {

	const [animateText, setAnimateText] = useState(false);
	const [animateVideo, setAnimateVideo] = useState(false);
	const [animateAirPlan, setAnimateAirPlan] = useState(false);
	const [showVideoPopup, setShowVideoPopup] = useState(false);
	const {latestReviews, isFetched} = useAppSelector(appState => appState.latestReviews);

	const dispatch = useAppDispatch();

	useEffect(() => {
		setAnimateText(true);
	});

	useEffect(() => {
		if (!isFetched) {
			dispatch(fetchLatestReviews());
		}
	}, []);

	const animateVideoClass = cx({
		[styles['animate']]: true,
		[styles['animate-active']]: animateVideo
	});

	const animateAircraftClass = cx({
		[styles['animate']]: true,
		[styles['animate-active']]: animateAirPlan
	});

	const fadeInTextClass = cx({
		[styles['animate']]: true,
		[styles['fade']]: true,
		[styles['animate-active']]: animateText
	});

	const getImageFromLogoId = (logoId: string): string => {
		switch (logoId) {
			case 'FFCargo':
				return welcomeImages.ffCargoLogo;
			case 'Trasko':
				return welcomeImages.traskoLogo;
			case 'Abipa':
				return welcomeImages.abipaLogo;
			case 'Ata':
				return welcomeImages.ataLogo;
			case 'CargoWorld':
				return welcomeImages.cargoWorldLogo;
			case 'SkyCargo':
				return welcomeImages.skyCargoLogo;
			default:
				return null;
		}
	};

	const stepsToUse = [
		{
			highlight: 'Выбрать в верхней части страницы ссылку «Зарегистрироваться»',
			text: 'и заполнить необходимые сведения об организации и пользователе',
			addSoonToken: false
		},
		{
			highlight: 'Дождаться обратной связи от нашего специалиста',
			text: 'по телефону или\n' +
				'по электронной почте',
			addSoonToken: false
		},
		{
			highlight: 'Заполнить аккредитационный лист пользователя',
			text: '',
			addSoonToken: true
		},
		{
			highlight: 'Можно начинать поиск рейсов',
			text: 'и подачу заявок на бронирование',
			addSoonToken: false
		}
	];

	const circle = useRef(null);
	const searchSection = useRef(null);
	const placeSection = useRef(null);
	const docsSection = useRef(null);

	const getTop = (el: HTMLElement): number => {
		const rect = el.getBoundingClientRect(),
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return rect.top + scrollTop;
	};

	useEffect(() => {
		const scrollListener = (): void => {
			const sections = [searchSection.current, placeSection.current, docsSection.current];
			sections.forEach((aminItem) => {
				const animItemHeight = aminItem.offsetHeight;
				const animItemTopOffset = getTop(aminItem);
				const animStart = 1.5;

				let animItemPoint = window.innerHeight - animItemHeight / animStart;
				if (animItemHeight > window.innerHeight) {
					animItemPoint = window.innerHeight - window.innerHeight / animStart;
				}
				if (window.pageYOffset > animItemTopOffset - animItemPoint && window.pageYOffset < animItemTopOffset + animItemHeight) {
					const imagePos = aminItem.querySelector(`.${styles['lazy-img']}`);

					const rect = circle.current;
					rect.style.width = imagePos.offsetHeight * aminItem.getAttribute('data-width') + 'px';
					if (aminItem.getAttribute('data-pos')) {
						rect.style.left = imagePos.offsetLeft + imagePos.offsetWidth - rect.offsetWidth * 0.8 + 'px';
						rect.style.top = imagePos.offsetTop - rect.offsetHeight / 6 + 'px';
					} else {
						rect.style.left = imagePos.offsetLeft + 'px';
						rect.style.top = imagePos.offsetTop + 'px';
					}
				}
			});

		};

		const cleanupFunction = (): void => window.removeEventListener('scroll', scrollListener);
		window.addEventListener('scroll', scrollListener);

		return cleanupFunction;
	});

	const heroSection = useRef(null);
	const videoSection = useRef(null);
	const airPlanImage = useRef(null);

	useEffect(() => {
		const videoObserver = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					if (!entry.isIntersecting) {
						return;
					}

					setAnimateVideo(true);
					videoObserver.unobserve(videoSection.current);
				});
			},
			{
				threshold: 0.8
			}
		);

		videoObserver.observe(videoSection.current);

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach(entry => {
					if (!entry.isIntersecting) {
						return;
					}

					if (entry.target == heroSection.current) {
						setAnimateText(true);
						observer.unobserve(heroSection.current);
						return;
					}

					if (entry.target == airPlanImage.current) {
						observer.unobserve(airPlanImage.current);
						setAnimateAirPlan(true);
					}
				});
			},
			{
				threshold: 0.1
			}
		);

		const animatedBlocks = [heroSection.current, airPlanImage.current];
		animatedBlocks.forEach((section) => observer.observe(section));

		return (): void => {
			videoObserver.disconnect();
			observer.disconnect();
		};
	});

	const [isMapAnimated, setMapAnimated] = useState(true);
	const lastAnimation = useRef<HTMLImageElement>(null);
	useEffect(() => {
		const restartAnimation = (): void => setMapAnimated(false);

		lastAnimation.current.addEventListener('animationend', restartAnimation);
		return (): void => lastAnimation.current.removeEventListener('animationend', restartAnimation);
	});

	useEffect(() => {
		if (!isMapAnimated) {
			setMapAnimated(true);
		}
	}, [isMapAnimated]);

	const reviewCards = latestReviews
		.map(review => (
			<div key={review.logoId} className={styles['review-card']}>
				<div className={styles['review-logo']}>
					<img src={getImageFromLogoId(review.logoId)} alt=''/>
				</div>
				<div className={styles['review-content']}>
					<h4 className={styles['review-title']}>{review.highlightText}</h4>
					{
						review.reviewBody
							.split('\n')
							.map((text, index) =>
								<p key={index}
								   className={classNames(styles['text-l'], styles['review-text'])}>{text}</p>)
					}
					<span className={styles.quote}/>
				</div>
				<div className={styles['review-author']}>
					<p className={styles['author-name']}><strong>{review.author}</strong></p>
					<div className={styles['text-s']}>{review.authorPost}
					</div>
				</div>
			</div>));

	return (
		<>
			<script src='https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'/>
			<section className={styles.hero}>
				<div className={styles['map-animation']}>
					<div className={classNames(styles['map-images'], cx({[styles['map-animated']]: isMapAnimated}))}>
						<img src={welcomeImages.mapImages[0]} className={classNames(styles['map-0'])} alt='map'/>
						<img src={welcomeImages.mapImages[1]} className={classNames(styles['map-1'], styles['map-img'])}
							 alt='map'/>
						<img src={welcomeImages.mapImages[2]} className={classNames(styles['map-2'], styles['map-img'])}
							 alt='map'/>
						<img src={welcomeImages.mapImages[3]} className={classNames(styles['map-3'], styles['map-img'])}
							 alt='map'/>
						<img src={welcomeImages.mapImages[4]} className={classNames(styles['map-4'], styles['map-img'])}
							 alt='map'/>
						<img src={welcomeImages.mapImages[5]} className={classNames(styles['map-5'], styles['map-img'])}
							 alt='map' ref={lastAnimation}/>
					</div>
				</div>
				<div className={styles.container}>
					<div
						className={classNames(styles['title-line'], styles['content-center'], styles.column, styles['over-map'])}
						ref={heroSection}>
						<h1 className={classNames(styles['t-xl'], styles['t-center'], fadeInTextClass)}
							data-delay='400'>
							Онлайн поиск и бронирование грузовых авиаперевозок
						</h1>
						<h4 className={classNames(styles['t-sub'], styles['t-center'], fadeInTextClass)}
							data-delay='400'>
							На платформе Cargobooking365.com</h4>
						<p className={classNames(styles['text-gray'], styles['text-l'], styles['t-center'], fadeInTextClass)}
						   data-delay='400'>
							100+ транспортных компаний присоединились к нам, получив
							возможность быстрее находить лучшие варианты перевозки своих грузов.
							<strong className={styles.row}>Присоединяйтесь и вы уже сегодня!</strong>
						</p>
						<div className={classNames(styles['buttons-line'], styles['content-center'], fadeInTextClass)}
							 data-delay='400'>
							<a href='#about' className={classNames(styles.btn, styles.main)}>Узнать больше</a>
							<a href='#demo' className={classNames(styles.btn, styles.secondary)}>Запросить демо</a>
							<a href='/auth/login' className={classNames(styles.btn, styles.secondary)}>Начать</a>
						</div>
					</div>
					<div className={classNames(styles['hero-animation'], styles['will-start'], animateVideoClass)}
						 ref={videoSection}>
						<div className={styles.anchor} id='about'/>
						<div className={classNames(styles['animate-zone'], styles['lazy-img'])}>
							<img src={welcomeImages.videoBase} alt='' className={styles['img-base']}/>
							<img src={welcomeImages.videoMenu} alt='' className={styles['img-menu']}/>
							<img src={welcomeImages.videoSideBar} alt='' className={styles['img-sidebar']}/>
							<img src={welcomeImages.videoSecondRow} alt='' className={styles['img-row-2']}/>
							<img src={welcomeImages.videoFirstRow} alt='' className={styles['img-row-1']}/>
							<img src={welcomeImages.videoRowOpen} alt='' className={styles['img-rowopen']}/>
							<img src={welcomeImages.videoButton} alt='' className={styles['img-button']}/>
						</div>
						<button className={styles['play-video']} data-modal-open='video'
								onClick={() => setShowVideoPopup(true)}>
							<div className={styles['button-inner']}>
								<div className={classNames(styles['button-content'], styles['content-center'])}>
									<div className={styles['button-text']}>Посмотреть видео</div>
									<div className={classNames(styles['button-icon'], styles['content-center'])}>
										<svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 24'>
											<path fillRule='evenodd' clipRule='evenodd'
												  d='m1.498 23.769 12.194-11.032a.954.954 0 0 0 .308-.705.954.954 0 0 0-.308-.706L1.495.228A.879.879 0 0 0 .529.085.94.94 0 0 0 0 .935v22.127a.94.94 0 0 0 .53.854.879.879 0 0 0 .969-.147Z'/>
										</svg>
									</div>
								</div>
							</div>
						</button>
					</div>
				</div>
			</section>
			<ModalDialog isBodyLocked={true}
						 show={showVideoPopup}
						 contentClassName={styles['video-modal']}
						 onClose={() => setShowVideoPopup(false)}>
				<div className={classNames(styles['m-video'], styles['content-center'])}>
					<iframe src='https://www.youtube.com/embed/xQgAY4R5o2s?autoplay=1'
							title='YouTube video player' frameBorder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen={true}/>
				</div>
			</ModalDialog>

			<div className={styles['rect-animation']}>
				<div className={styles['rect']} ref={circle}>
					<svg viewBox='0 0 296 296' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<circle cx='148' cy='148' r='148' fill='#3796F6'/>
					</svg>
				</div>

				<section className={styles.search} data-width='1' ref={searchSection}
						 data-circle-class-name={styles['first-section']}>
					<div className={styles.container}>
						<div className={classNames(styles['cols-content'], styles['r-img'])}>
							<div className='search-info'>
								<h2 className={styles['t-l']}>Легко заполнить, быстро найти</h2>
								<p className={styles['text-l']}>Оцените стоимость вашей транспортировки, указав пункты
									отправления
									и назначения, тип и характеристики груза</p>
								<ul className={styles.list}>
									<li><strong>Специальные грузы (special handling коды)</strong></li>
									<li>Импорт данных в систему из Excel</li>
									<li>Подбор вариантов ближайших аэропортов</li>
								</ul>
							</div>
							<div className={styles['search-img']}>
								<div className={classNames(styles['lazy-img'], styles['right-crop'])}>
									<img src={welcomeImages.search} alt=''/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className={styles.place} data-width='0.5' data-pos='right' ref={placeSection}
						 data-circle-class-name={styles['second-section']}>
					<div className={styles.container}>
						<div className={classNames('place-content', styles['cols-content'])}>
							<div className={styles['place-img']}>
								<div className={styles['lazy-img']}>
									<img src={welcomeImages.place} alt=''/>
								</div>
							</div>
							<div className='place-info'>
								<h2 className={styles['t-l']}>Вся информация на одном экране</h2>
								<p className={styles['text-l']}>Предложения авиакомпаний с актуальными тарифами и
									условиями
									перевозки груза</p>
								<ul className={styles.list}>
									<li><strong>Наличие провозных емкостей по рейсам</strong></li>
									<li>Маршруты и расписание</li>
									<li>Тарифы и дополнительные сборы</li>
								</ul>
							</div>
						</div>
					</div>
				</section>

				<section className={styles.docs} data-width='0.5' ref={docsSection}
						 data-circle-class-name={styles['last-section']}>
					<div className={styles.container}>
						<div className={classNames('docs-content', styles['cols-content'], styles['r-img'])}>
							<div className='docs-info'>
								<h2 className={styles['t-l']}>Электронный документооборот</h2>
								<p className={styles['text-l']}>Создание и обработка основных документов по перевозке в
									удобном
									веб-интерфейсе</p>
								<ul className={styles.list}>
									<li><strong>Создание авианакладной</strong></li>
									<li>Подача заявок на бронирование</li>
									<li>Отправка телеграмм CARGO-IMP<span className={styles.token}>Скоро</span></li>
								</ul>
							</div>
							<div className='docs-img'>
								<div className={classNames(styles['lazy-img'], styles['right-crop'])}>
									<img src={welcomeImages.docs} alt=''/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section className={styles.reviews}>
				<div className={styles.container}>
					<div className={classNames(styles['title-line'], styles['content-center'], styles.column)}>
						<h2 className={classNames(styles['t-l'], styles['t-center'])}>Отзывы о платформе</h2>
						<p className={classNames(styles['text-l'], styles['t-center'])}>Опыт применения
							Cargobooking365.com</p>
					</div>
					<Carousel
						items={reviewCards}
					/>
				</div>
			</section>
			<section className={styles['how-start']}>
				<div className={styles.container}>
					<div
						className={classNames(styles['title-line'], styles['content-center'], styles.column, styles['line-large'])}>
						<h2 className={classNames(styles['t-l'], styles['t-center'])}>Как начать использование
							платформы</h2>
						<p className={classNames(styles['text-l'], styles['t-center'])}>Настоятельно рекомендуем
							связаться с нашим менеджером для
							представления функционирования платформы и демонстрации ее возможностей
						</p>
					</div>
					<div className={styles.steps}>
						{stepsToUse.map((step, idx) => (
							<div key={idx} className={styles.step}>
								<span className={styles['step-number']}>Шаг {idx + 1}</span>
								<div className={styles['step-image']}>
									<div className={styles['step-icon']}>
										<img src={welcomeImages[`step${idx + 1}`]} alt={`Шаг ${idx + 1}`}/>
									</div>
								</div>
								<p className={styles['text-l']}><strong>{step.highlight}</strong>
									{step.addSoonToken && <span className={styles.token}>Скоро</span>}</p>
								{step.text &&
									<p className={classNames(styles['step-info'], styles['m-0'])}>{step.text}</p>}
							</div>
						))}
					</div>

					<div className={styles['content-center']}>
						<a href='#' className={classNames(styles['c-s'], styles['read-more'])}
						   title='Перейти в «Базу знаний»'>Больше информации в «Базе знаний» проекта
							<span role='icon' className={classNames(styles['content-center'], styles['icon-sm'])}><svg
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 20 20'>
									<path d='m11 4 6 6-6 6M16.5 10H3' stroke='#3796F6' strokeWidth='2'
										  strokeLinecap='round'
										  strokeLinejoin='round'/>
								</svg>
							</span>
						</a>
					</div>
				</div>
			</section>
			<DemoForm/>
			<footer className={styles.footer}
					ref={airPlanImage}>
				<div className={styles.container}>
					<div className={styles['footer-content']}>
						<div
							className={classNames(styles['title-line'], styles['content-center'], styles.column, styles['line-small'])}>
							<h2 className={classNames(styles['t-l'], styles['t-center'])}>Уже зарегистрированы? Начните
								прямо сейчас!</h2>
							<a href={LOGIN_REDIRECT_URL}
							   className={classNames(styles.btn, styles.secondary, styles['secondary-alt'], styles['c-s'])}>
								<span className={classNames(styles.icon, styles['icon-m'], styles['content-center'])}>
								<img src={welcomeImages.goIcon} alt='Перейти'/></span><span
								className={styles.title}>Перейти</span></a>
						</div>
					</div>
				</div>
				<div className={classNames(styles['air-plane'], animateAircraftClass)}>
					<div className={styles['lazy-img']}>
						<img src={welcomeImages.airPlan} alt=''/>
					</div>
				</div>
			</footer>
		</>
	);
};

export default FFCargoWelcomePage;