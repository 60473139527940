import {
    CreateCustomerApplication,
    CreateCustomerApplicationDto,
    GetCustomerApplicationDto,
    UpdateCustomerApplication,
} from '@models/customerApplications/customerApplicationModels';
import { GetCustomerRequestDto, GetSaleDto } from '@models/customerRequests/customerRequestModels';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerRequestService from '@services/CustomerRequestsService';
import CustomerApplicationsService from '@services/CustomerApplicationsService';
import SalesService from '@services/SalesService';
import { createAppThunk } from '@helpers/reduxHelpers';
import { ContactDto } from '@models/contacts';
import ContactsService from '@services/ContactsService';
import { HttpError } from '@models/shared';

export type AuxData = {
    customerRequest: GetCustomerRequestDto;
    sale: GetSaleDto;
    contact: ContactDto;
};

export type State = {
    isFetching: boolean;
    auxData?: AuxData;
    sale: GetSaleDto;
    customerRequest: GetCustomerRequestDto;
    contact: ContactDto | HttpError;
    createResult?: CreateCustomerApplicationDto;
    customerApplication?: GetCustomerApplicationDto;
};

export const fetchAuxData = createAsyncThunk(
    'fetchAuxData',
    async (arg: { customerRequestId: string; saleId: string }) => {
        const response = await Promise.all([
            new CustomerRequestService().getSearchRequest(arg.customerRequestId),
            new SalesService().getSale(arg.saleId),
            new ContactsService().fetchOne(),
        ]);

        return {
            customerRequest: response[0].data,
            sale: response[1].data,
            contact: response[2].data as ContactDto,
        };
    },
);

export const fetchSale = createAsyncThunk(
    'customerApplications/fetchSale',
    async (saleId: string) => {
        if (saleId == null || saleId == '00000000-0000-0000-0000-000000000000') {
            return null;
        }
        const {data} = await new SalesService().getSale(saleId);
        return data;
    },
);

export const fetchCustomerRequest = createAsyncThunk(
    'customerApplications/fetchCustomerRequest',
    async (customerRequestId: string) => {
        const {data} = await new CustomerRequestService().getSearchRequest(customerRequestId);
        return data;
    },
);

export const fetchContact = createAsyncThunk(
    'customerApplications/fetchContact',
    async () => {
        const {data} = await new ContactsService().fetchOne();
        return data;
    },
);

export const fetchCustomerApplication = createAppThunk(
    'fetchCustomerApplication',
    async (arg: { customerApplicationId: string }) => {
        const {data} = await new CustomerApplicationsService().get(arg.customerApplicationId);
        return data;
    },
);

export const createCustomerApplication = createAsyncThunk(
    'createCustomerApplication',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (arg: { model: CreateCustomerApplication; files: File[] }, thunkAPI) => {
        const service = new CustomerApplicationsService();
        const {data: result} = await service.create(arg.model, arg.files);
        return result;
    },
);

export const addComment = createAsyncThunk(
    'addComment',
    async (data: { customerApplicationId: string, body: string }) => {
        const service = new CustomerApplicationsService();
        const {data: result} = await service.addComment(data);
        return result;
    },
);

export const updateCustomerApplication = createAppThunk(
    'updateCustomerApplication',
    async (arg: { model: UpdateCustomerApplication; filesToUpload: File[] }) => {
        const {data} = await new CustomerApplicationsService().update(arg.model, arg.filesToUpload);
        const {fileIdsToDelete} = arg.model;
        arg.model.fileIdsToDelete = [];
        return {updatedModel: arg.model, uploadedFiles: data.uploadedFiles, fileIdsToDelete};
    },
);

const slice = createSlice({
    name: 'customerApplications',
    initialState: {
        isFetching: true,
    } as State,
    reducers: {
        removeAwb: (state: State) => {
            state.customerApplication.airwaybillPreview = null;
        },
        clearBookingData: (state: State) => {
            state.sale = null;
            state.contact = null;
            state.customerRequest = null;
            state.customerApplication = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuxData.fulfilled, (state, action) => {
            state.auxData = action.payload;
            state.customerRequest = state.auxData.customerRequest;
            state.sale = state.auxData.sale;
        });

        builder.addCase(fetchSale.fulfilled, (state, action) => {
            state.sale = action.payload;
        });

        builder.addCase(fetchCustomerRequest.fulfilled, (state, action) => {
            state.customerRequest = action.payload;
        });

        builder.addCase(fetchContact.fulfilled, (state, action) => {
            state.contact = action.payload;
        });

        builder.addCase(fetchCustomerApplication.fulfilled, (state, action) => {
            state.customerApplication = action.payload;
            state.isFetching = false;
        });

        builder.addCase(createCustomerApplication.fulfilled, (state, action) => {
            state.createResult = action.payload;
        });

        builder.addCase(addComment.fulfilled, (state, action) => {
            state.customerApplication.comments = action.payload;
        });

        builder.addCase(updateCustomerApplication.fulfilled, (state, action) => {
            let files =
                state
                    .customerApplication
                    .files
                    .filter(x => action.payload.fileIdsToDelete.indexOf(x.id) == - 1);

            files = [...files, ...action.payload.uploadedFiles];

            state.customerApplication = {
                ...state.customerApplication,
                ...action.payload.updatedModel,
                files,
            };
        });
    },
});

export const {reducer} = slice;
export const {clearBookingData, removeAwb} = slice.actions;