import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import AwbUpdatePage from '@scenes/customerApplication/awb/AwbUpdatePage';

const AwbUpdateForm = () => {
	const history = useHistory();

	const { id: airwaybillId } = useParams<{ id: string }>();

	return (
		<Row className="mb-3">
			<Col md={12}>
				<AwbUpdatePage
					onClickBack={() => history.push('/awb/list')}
					onSuccessSave={() => history.push('/awb/list')}
					airwaybillId={airwaybillId}
					showFwbButton={true}
				/>
			</Col>
		</Row>
	);
};

export default AwbUpdateForm;
