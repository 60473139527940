import * as React from 'react';

import { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import clsx from "clsx";
import styles from './index.module.scss';
import { TableRow } from "./models";
import { buildReducer, TableActionType } from "@components/EditableTable/reducer";
import { defaultRowValidatorFactory, defaultRowEmptyCheck } from "@components/EditableTable/helpers";
import { Option } from '@components/select/AirlineSelect';

interface Props<TModel> {
	rowEmptyCheck?: typeof defaultRowEmptyCheck;
	modelFactory: () => TModel;
	initialValues: TModel[];
	onChange: (values: TableRow<TModel>[]) => void;
	fields: TableField[];
	maxEmptyRowSize: number;
}

export enum TableFieldType {
	Text,
	Integer,
	Float,
	List
}

export type TableField = {
	propertyPath: string;
	columnName: string;
	placeholder: string;
	required?: boolean;
	type?: TableFieldType;
	items?: Option[];
}

/* eslint-disable react/display-name */
const TableHead = memo((props: { columnNames: string[] }) => {
	return <thead>
	<tr>
		{props.columnNames.map((x, i) => <th key={i}>{x}</th>)}
	</tr>
	</thead>;
})

const EditableTable = function <TModel>(props: Props<TModel>): JSX.Element {

	const [incrementor, setIncrementor] = useState(0);

	const generateId = useCallback(() => {
		const newState = incrementor + 1;
		setIncrementor(newState);
		return newState;
	}, [incrementor]);

	const firstInputsRef = useRef([]);

	const requiredKeys = useMemo(() => {
		return props.fields.filter(x => x.required).map(x => x.propertyPath);
	}, [props.fields]);

	const rowEmptyCheck = props.rowEmptyCheck || defaultRowEmptyCheck;

	const rowValidator = useMemo(() => defaultRowValidatorFactory<TModel>(...requiredKeys), [requiredKeys]);

	const arrayReducer =
		useMemo(() =>
				buildReducer<TModel>(rowEmptyCheck, rowValidator),
			[rowEmptyCheck, rowValidator]
		);

	const { initialValues, onChange: saveChanges } = props;

	const initialReducerValue = useMemo(() => {

		let models = initialValues;

		const isEmpty = initialValues == null || initialValues.length == 0;
		if (isEmpty) {
			models = [props.modelFactory()];
		}

		const results = models.map((x, i) => ({
			id: i,
			isValid: true,
			isEmpty: isEmpty,
			...x
		}));

		setIncrementor(results.length - 1);

		return results;

	}, [initialValues]);

	const [values, dispatch] = useReducer(arrayReducer, initialReducerValue);

	const addNewRow = () => {

		const newModel = props.modelFactory();

		dispatch({
			type: TableActionType.Add,
			payload: {
				model: {
					id: generateId(),
					isValid: true,
					isEmpty: true,
					...newModel
				}
			}
		});
	};

	useEffect(() => {
		firstInputsRef.current = firstInputsRef.current?.slice(0, values.length);

		if (firstInputsRef.current == null ||
			firstInputsRef.current.length === 0) {
			return;
		}

		// Focus to the first input of the last row.
		const lastItem = firstInputsRef.current[firstInputsRef.current.length - 1];
		lastItem.focus();

	}, [values.length]);

	const onChangeInput = (e, id: number, field: TableField) => {
		const newValue = e.target.value == null ? '' : e.target.value;
		let newValueConverted: null;

		switch (field.type) {
			case TableFieldType.Integer:
				const matches = newValue.match(/\d+/g);
				if (matches.length > 0) {
					newValueConverted = matches[0];
				} else {
					newValueConverted = null;
				}
				break;
			case TableFieldType.Float:
				newValueConverted = newValue.match(/-?\d*[,.]?\d*/g)[0];
				break;
			case TableFieldType.Text:
			default:
				newValueConverted = newValue?.toString() || '';
				break;
		}

		dispatch({
			type: TableActionType.ChangeValue,
			payload: { id, name: field.propertyPath, newValue: newValueConverted }
		});
	};

	const emptyRows = useMemo(() => {
		return values.filter(x => x.isEmpty);
	}, [values]);

	const onFocusOut = () => {
		setFocusOnRowId(null);
		if (values.length > props.maxEmptyRowSize) {
			if (emptyRows.length > props.maxEmptyRowSize) {
				dispatch({ type: TableActionType.Remove, payload: { id: emptyRows[0].id } });
			}
		}
		saveChanges(values);
	};

	const onFocusIn = (rowId: number) => {
		setFocusOnRowId(rowId);
	};

	const removeRow = (rowId: number) => {
		dispatch({ type: TableActionType.Remove, payload: { id: rowId } });
	};

	const [focusedRowId, setFocusOnRowId] = useState<number>(null);

	const columnNames = useMemo(() => {
		return props.fields.map(x => x.columnName)
	}, [props.fields]);

	return <table className={styles.table}>
		<TableHead columnNames={columnNames}/>
		<tbody>
		{values.map((value, rowIndex) => (
			<tr key={value.id}
				className={(value.isValid == false && focusedRowId != value.id) ? styles.invalidRow : null}>
				{props.fields.map((field, fieldIndex) => {
					const refProps = fieldIndex == 0 ? { ref: (el) => firstInputsRef.current[rowIndex] = el } : {};

					const keyDownProps = fieldIndex == props.fields.length - 1 ? {
						onKeyDown: (e: React.KeyboardEvent)  => {
							if (rowIndex != values.length - 1) {
								return;
							}

							if (!(e.key == 'Tab' || e.keyCode == 9)) {
								return;
							}

							e.preventDefault();

							if (!values[values.length - 1].isEmpty) {
								addNewRow();
							} else {
								firstInputsRef.current[0].focus();
							}
						}
					} : {};

					return <td key={fieldIndex}>
						{field.type == TableFieldType.List
							? <select
								{...refProps}
								{...keyDownProps}
								className={clsx('form-control', field.required == true ? 'input-required' : '')}
								value={value[field.propertyPath] || ''}
								onChange={e => onChangeInput(e, value.id, field)}
								onFocus={_ => onFocusIn(value.id)}
								onBlur={_ => onFocusOut()}
							>
								<option/>
								{field.items.map((o, idx) => <option key={idx} value={o.value}>{o.label}</option>)}
							</select>
							: <input
								{...refProps}
								{...keyDownProps}
								placeholder={field.placeholder}
								className={clsx('form-control', field.required == true ? 'input-required' : '')}
								value={value[field.propertyPath] || ''}
								onChange={e => onChangeInput(e, value.id, field)}
								onFocus={_ => onFocusIn(value.id)}
								onBlur={_ => onFocusOut()}
							/>}
					</td>;
				})}

				<td className={styles.tableButtons} key={props.fields.length + 1}>
					{values.length > 1 && <a
						onClick={e => {
							e.preventDefault();
							removeRow(value.id);
						}}><i className='icon-trash'/></a>}
					{(values.length == 1 || (rowIndex == values.length - 1 && emptyRows.length <= props.maxEmptyRowSize - 1)) &&
						<a
							onClick={e => {
								e.preventDefault();
								addNewRow();
							}}
						><i className='icon-plus'/></a>}
				</td>
			</tr>
		))}
		</tbody>
	</table>;
};

export default EditableTable;