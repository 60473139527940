import { HttpError } from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {
	ContactDto,
	ContactListItemDto,
	ContactPermissionListItemDto,
	CreateContact,
	GetContacts, ToggleContactActivation,
	UpdateContact,
	UpdateContactsPermissions
} from '@models/contacts';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { InteractionType, InteractionTypeDto } from '@models/contacts/InteractionType';
import { ContactPermissionTypeDto } from '@models/contacts/ContactPermissionType';
import HttpClient from '@core/HttpClient';
import qs from 'qs';

export default class ContactsService {
	public fetchPager = (q: GetContacts) => {
		return AuthorizedHttpClient.get<HttpError | IPagingWrapper<ContactListItemDto>>(
			`api/contacts?${qs.stringify(q, { allowDots: true })}`
		);
	};

	public fetchOne = (id?: string) => {
		return AuthorizedHttpClient.get<HttpError | ContactDto>(`api/contacts/${id || '00000000-0000-0000-0000-000000000000'}`);
	};

	public update = (cmd: UpdateContact) => {
		return AuthorizedHttpClient.put<HttpError | null>(`api/contacts`, cmd);
	};

	public create = (cmd: CreateContact) => {
		return AuthorizedHttpClient.post<HttpError | string>(`api/contacts`, cmd);
	};

	public fetchInteractionTypes = () => {
		return HttpClient.get<InteractionTypeDto[]>(`api/contacts/interactionTypes`);
	};

	public fetchContactPermissionTypes = () => {
		return AuthorizedHttpClient.get<ContactPermissionTypeDto[]>(`api/contacts/permissionTypes`);
	};

	public fetchContactPermissionsMap = (organizationId: string) => {
		return AuthorizedHttpClient.get<ContactPermissionListItemDto[]>(`api/contacts/permissions/${organizationId}`);
	};

	public updateContactPermissionsMap = (map: UpdateContactsPermissions) => {
		return AuthorizedHttpClient.put<HttpError | null>(`api/contacts/permissions`, map);
	};

	public toggleActivation = (cmd: ToggleContactActivation) => {
		return AuthorizedHttpClient.put<HttpError | null>(`api/contacts/activation`, cmd);
	}

	public checkEmail(email: string) {
		return AuthorizedHttpClient.get<HttpError | string>(`api/contacts/check/${email}`);
	}

	public fetchColleaguesByInteractionType(iType: InteractionType) {
		return AuthorizedHttpClient.get<string[]>(`api/contacts/colleaguesByInteractionType/${iType}`);
	}
}