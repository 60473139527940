import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconBack } from '@material-design-icons/svg/round/chevron_left.svg';
import s from './pageHeader.module.scss';
import cn from 'classnames';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';

interface IPageHeaderProps {
  title: string;
  additionalInfo?: string;
  size?: 'header' | 'subHeader';
  badge?: ReactNode;
  backUrl?: string;
  backText?: string;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ title, backUrl, backText, additionalInfo, size, badge }) => {
  const navigate = useHistory();
  const { t } = useTranslation();

  const onBack = () => navigate.push(backUrl);

  return (
    <div className={s.header}>
      {backUrl &&
        <span>
          <Button onClick={onBack} icon={<IconBack fill='currentColor' />} type='text' variant='primary'>
             {backText || t('admin.dictionaries.goBack')}
          </Button>
        </span>
      }
      <div className={cn([s.main, size === 'subHeader' && s.subHeader])}>
        {title}
        {badge && badge}
      </div>
      {additionalInfo && (
        <div className={s.additionalInfo}>
          <span>{additionalInfo}</span>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
