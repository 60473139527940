import React, { useEffect, useState } from 'react';
import { ReactComponent as RequestSvg } from '@material-design-icons/svg/round/assignment.svg';
import { ReactComponent as ClosingSvg } from '@material-design-icons/svg/round/location_on.svg';
import { ReactComponent as BookedSvg } from '@material-design-icons/svg/round/event_available.svg';
import { ReactComponent as ExecutionSvg } from '@material-design-icons/svg/round/flight.svg';
import s from './TracingPage.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { BookingStatus } from '@models/enums';
import StatusLoader from '@scenes/booking/components/StatusLoader';
import { useAppSelector } from '@root/store';
import { getEventCodes, getSendingStatuses, hasEventCodes, hasSendingStatuses } from '@helpers/statusHelper';
import { SendingStatus } from '@root/enum';

const Status = () => {
    const [bookingStatus, setBookingStatus] = useState<BookingStatus>(BookingStatus.Request);
    const [isDelivered, setIsDelivered] = useState(false);
    const { t } = useTranslation();
    const { traceData, traceLoading } = useAppSelector(s => s.tracking);
    const { bookingConfirmation } = useAppSelector(s => s.bookingConfirmation);

    const statuses = [
        {
            status: BookingStatus.Request,
            title: t('tracing.request'),
            icon: <RequestSvg fill='currentColor' />,
        },
        {
            status: BookingStatus.Booking,
            title: t('tracing.booking'),
            icon: <BookedSvg fill='currentColor' />,
        },
        {
            status: BookingStatus.Execution,
            title: t('tracing.execution'),
            icon: <ExecutionSvg fill='currentColor' />,
        },
        {
            status: BookingStatus.Closing,
            title: t('tracing.closing'),
            alternativeTitle: t('tracing.arrived'),
            icon: <ClosingSvg fill='currentColor' />,
        },
    ];

    useEffect(() => {
        if (traceData?.data && !traceLoading) {
            if (traceData?.isSuccess && traceData?.data?.traceInfo?.trackAndTrace?.events?.length > 0) {
                const codes = getEventCodes(traceData?.data?.traceInfo?.trackAndTrace?.events);
                if (hasEventCodes(codes, ['BKD']))
                    setBookingStatus(BookingStatus.Booking);

                if (hasEventCodes(codes, ['CLD', 'DEP', 'TRA', 'RCT', 'ARR', 'ARV', 'ARE']))
                    setBookingStatus(BookingStatus.Execution);

                if (hasEventCodes(codes, ['DLV', 'RCV']) || traceData.data.traceInfo.eventsByLocations[traceData.data.traceInfo.trackAndTrace.destination]?.length > 0) {
                    setBookingStatus(BookingStatus.Closing);
                    setIsDelivered(codes.filter(code => code == 'DLV').length > 0);
                }
            }
        }
    }, [traceData?.data, bookingConfirmation]);

    useEffect(() => {
        if (bookingConfirmation) {
            const sendStatuses = getSendingStatuses(bookingConfirmation?.cargoRoutes);
            if (hasSendingStatuses(sendStatuses, [SendingStatus.BKD]))
                setBookingStatus(BookingStatus.Booking);

            if (hasSendingStatuses(sendStatuses, [SendingStatus.DEP]))
                setBookingStatus(BookingStatus.Execution);

            if (hasSendingStatuses(sendStatuses, [SendingStatus.DLV])) {
                setBookingStatus(BookingStatus.Closing);
                setIsDelivered(sendStatuses.filter(code => code == SendingStatus.DLV).length > 0);
            }
        }
    }, [bookingConfirmation]);

    const isEqual = (viewStatus: BookingStatus) => viewStatus === bookingStatus;

    return (
        <div>
            <div className={s.bookingStatuses}>
                {statuses.map((status, index) =>
                    <div key={index} className={clsx(
                        s.itemWrapper,
                        isEqual(status.status) && s.itemActive,
                        status.status > bookingStatus && s.itemFuture)}>
                        {status.icon}
                        {
                            status.alternativeTitle
                                ? (
                                    <span>{(isDelivered || !isEqual(status.status) ? status.title : status.alternativeTitle)}</span>
                                )
                                : (<span>{status.title}</span>)
                        }
                        {status.status === bookingStatus && status.status !== BookingStatus.Closing &&
                            <StatusLoader />
                        }
                    </div>,
                )}
            </div>
        </div>
    );
};

export default Status;