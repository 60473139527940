import React, { useState } from 'react';
import { Label } from 'reactstrap';
import styles from './AirportSelect.module.scss';
import LocationSelect from '@components/select/LocationSelect';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LocationDto, LocationType } from '@models/locations';
import LocationsService from '@services/LocationsService';
import { useTranslation } from 'react-i18next';

type AirportSelectProps = {
    label?: string;
    placeholder: string;
    icon: string;
    value: LocationDto;
	hideWarning?: boolean;
    onChange(value: LocationDto): void;
	hideWithoutTariffs?: boolean;
};

const AirportSelect = (props: AirportSelectProps) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [term, setTerm] = useState<string>(null);

    const [hasValidTerm, setValidTerm] = useState(true);

    const onChangeTerm = (term: string) => {
        setValidTerm(term != null && term.length >= 3);
        setTerm(term);
    };

    const { t } = useTranslation();

    return (
        <>
			{!props.hideWarning && <div style={{ opacity: !hasValidTerm ? '1' : '0' }}>
                <span className="text-danger">
                    {t('request.minLettersRequired')}
                </span>
            </div>}
			{props.label && <Label className={styles.label} style={{ color: '#757575' }}>
                {props.label}
            </Label>}
            <LocationSelect
                placeholder={props.placeholder}
                selectedOptions={props.value != null ? [props.value] : null}
                onChange={(x) => {
                    let val: LocationDto = null;
                    if (x && x.length > 0) {
                        val = x[0];
                    }

                    if (val) {
						props.onChange(val);
                    } else {
                        props.onChange(null);
                    }
                }}
                fetch={term => new LocationsService().getLocations(term, null, props.hideWithoutTariffs)}
                isMulti={false}
                isClearable={true}
                onTermChanged={onChangeTerm}
            />
        </>
    );
};

export default AirportSelect;