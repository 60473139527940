import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Button from 'reactstrap/lib/Button';

interface IMultiSwitchOption {
  labelRes: string;
  value: string;
}
interface IMultiSwitchProps {
    options: IMultiSwitchOption[];
    defaultValue?: string;
    onChange: (val: string) => void;
}

const MultiSwitch = (props: IMultiSwitchProps): JSX.Element => {
    const { options, defaultValue, onChange } = props;
    const { t } = useTranslation();
    const [rSelected, setRSelected] = React.useState<string>(defaultValue);

    const _on_change = (newValue: string): void => {
        setRSelected(newValue);
        onChange(newValue);
    }

    return (
        <ButtonGroup>
            {(options || []).map((opt) => (
                <Button
                    key={opt.value}
                    color="primary"
                    outline
                    onClick={() => _on_change(opt.value)}
                    active={rSelected === opt.value}
                >
                {t(opt.labelRes)}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default MultiSwitch;