import { CountryDictionaryItem } from '@models/countries';
import { createAppThunk } from '@helpers/reduxHelpers';
import CountryService from '@services/CountryService';
import { createSlice } from '@reduxjs/toolkit';

export type State = {
	allCountries: CountryDictionaryItem[];
	isFetching: boolean;
}

export const fetchCountries = createAppThunk(
	'fetchCountries',
	async () => {
		const countries = await new CountryService().getAllCountries();
		return countries;
	}
);

const slice = createSlice({
	name: 'countries',
	initialState: {
		allCountries: [],
		isFetching: false
	} as State,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchCountries.fulfilled, (state, action) => {
			state.allCountries = action.payload;
			state.isFetching = false;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchCountries.pending, (state, action) => {
			state.isFetching = true;
		});
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchCountries.rejected, (state, action) => {
			state.isFetching = false;
		});
	}
});

export const {reducer} = slice;
export const {actions} = slice;