import { FormikProps } from 'formik';
import * as React from 'react';
import SelectField from '@components/inputs/Select/SelectField';
import { useTranslation } from 'react-i18next';
import { WayToGetAccountingDoc, WayToGetAccountingDocDto } from '@models/organizations/WayToGetAccountingDoc';

const WayToGetAccountingDocSelectField = (props: {
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formikProps: FormikProps<any>;
	isEditMode: boolean;
	onChange?: (selectedObjects: WayToGetAccountingDocDto[], selectedValues: WayToGetAccountingDoc[]) => void;
	disabledSelector?: (obj: WayToGetAccountingDocDto) => boolean;
}) => {

	const {t} = useTranslation();

	const availableObjects: WayToGetAccountingDocDto[] = React.useMemo(() => {
		return [
			{ value: WayToGetAccountingDoc.None, key: WayToGetAccountingDoc[WayToGetAccountingDoc.None]},
			{ value: WayToGetAccountingDoc.ByEmail, key: WayToGetAccountingDoc[WayToGetAccountingDoc.ByEmail]},
			{ value: WayToGetAccountingDoc.ByEdm, key: WayToGetAccountingDoc[WayToGetAccountingDoc.ByEdm]},
		];
	}, []);

	return (
		<SelectField<WayToGetAccountingDocDto, WayToGetAccountingDoc>
			fieldValueSelector={x => x.value}
			availableObjects={availableObjects}
			formikProps={props.formikProps}
			name={props.name}
			isEditMode={props.isEditMode}
			onChange={props.onChange}
			optionLabelSelector={(x) => <>{t(`waysToGetAccountingDoc.${x.key}`)}</>}
			selectedLabelSelector={(x) => <>{t(`waysToGetAccountingDoc.${x.key}`)}</>}
			placeholder={t('selectWayToGetDocs')}
			disabledSelector={props.disabledSelector}
		/>
	);
};

export default WayToGetAccountingDocSelectField;