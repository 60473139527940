import getBrowserHistory from '@root/getBrowserHistory';
import getRootReducer from '@store/getRootReducer';
import initializeStore from '@root/store';

export const browserHistory = getBrowserHistory();

const rootReducer = getRootReducer(browserHistory);

export const store = initializeStore(rootReducer, browserHistory, {});

export function resetStore() {
	store.dispatch({type: 'RESET'});
}