import * as React from 'react';
import { Modal as RbModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CSSProperties } from 'react';

type Props = {
  isOpen: boolean;
  onClickCloseButton: () => void;
  body: JSX.Element;
  footer?: JSX.Element;
  size?: string;
  style?: CSSProperties | undefined;
}

const AppModal = (props: Props) => {
  return (
    <RbModal isOpen={props.isOpen} toggle={() => props.onClickCloseButton()} size={props.size || ''}
             style={props.style}>
      <ModalHeader toggle={() => props.onClickCloseButton()} />
      <ModalBody>
        {props.body}
      </ModalBody>
      {props.footer &&
        <ModalFooter>
          {props.footer}
        </ModalFooter>
      }
    </RbModal>
  );
};

export default AppModal;