import * as React from 'react';
import { PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router';
import NavMenu from '@components/NavMenu/NavMenu';
import s from './layout.module.scss';
import LeftMenu from '@components/LeftMenu';
import AuthorizeRoute from '@components/AuthRoute.component';
import PoweredBy from '@components/PoweredBy';
import useAccreditationListRedirection from "@helpers/routingHelpers";

interface LayoutRouteProps {
  component: | React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path?: string | string[];
  exact?: boolean;
}

type LayoutProps = {};

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({ children }) => {
  return (
    <div className={s.container}>
      <LeftMenu />
      <div className={s.area}>
        <NavMenu />
        <div className={s.content}>
          {children}
        </div>
        <div className={s.footer}>
          <PoweredBy />
        </div>
      </div>
    </div>
  );
};

const AdminLayout = ({ component: Component, ...rest }: LayoutRouteProps): JSX.Element => {
    useAccreditationListRedirection();

  const renderComponentFunc = (props) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );
  return <AuthorizeRoute {...rest} component={renderComponentFunc} />;
};

export default AdminLayout;