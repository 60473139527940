import { LocationDto } from "@models/locations";
import {CargoDimensionModel} from "@models/shared";
import { string } from 'yup';

export type CreateAirwaybill = {
	customerApplicationId?: string | undefined;
	airwaybill: AirwaybillDto;
};

export type UpdateAirwaybill = {
	airwaybill: AirwaybillDto;
};

export type CreateHouseAirwaybill = {
	houseAirwaybill: AirwaybillDto;
};

export type UpdateHouseAirwaybill = {
	houseAirwaybill: AirwaybillDto;
};

export interface IOtherCharge {
    code: string;
    amount: number;
}

export interface IOtherChargeCode {
    id?: string;
    code: string;
    description: string;
}

export type AirwaybillDto = {
	id?: string | undefined;
	parentAirwaybillId?: string;
	issuingCarrierAgentAccountNumber?: string | undefined;
	accountingInformation?: string | undefined;
	agentCityId?: string | undefined;
	agentIataCode?: string | undefined;
	airwaybillNumber?: string | undefined;
	awbDepartureAirportId?: string | undefined;
	routingDepartureAirportId?: string | undefined;
	routingDepartureCityId?: string | undefined;
	airportOfDestinationId?: string | undefined;
	amountOfInsurance?: string | undefined;
	cargoChargeableWeight?: string | undefined;
	cargoVolume?: number | undefined;
	chgsCode?: string | undefined;
	collectTax?: string | undefined;
	collectTotal?: string | undefined;
	collectTotalOtherChargesDueAgent?: string | undefined;
	collectTotalOtherChargesDueCarrier?: string | undefined;
	collectValuationCharge?: string | undefined;
	collectWeightCharge?: string | undefined;
	commodityItem?: string | undefined;
	scannedRecipientData?: string | undefined;
	recipientId?: string | undefined;
	recipient?: AddressDatumDto | undefined;
	recipientCityId?: string | undefined;
	recipientStateProvince?: string | undefined;
	currency?: string | undefined;
	declaredValueForCarriage?: string | undefined;
	declaredValueForCustoms?: string | undefined;
	executionCityId?: string | undefined;
	executionDateStamp?: string | undefined;
	executedSign?: string | undefined;
	cargoGrossWeight?: string | undefined;
	isCargoGrossWeightUnitKg?: boolean | undefined;
	handlingInformationRequirements?: string | undefined;
	handlingInformationDestination?: string | undefined;
	agentName?: string | undefined;
	cargoDescription?: string | undefined;
	cargoQuantity?: string | undefined;
	notNegotia?: string | undefined;
	otherCharges?: IOtherCharge[];
	prepaidTax?: string | undefined;
	prepaidTotal?: string | undefined;
	prepaidTotalOtherChargesDueAgent?: string | undefined;
	prepaidTotalOtherChargesDueCarrier?: string | undefined;
	prepaidValuationCharge?: string | undefined;
	prepaidWeightCharge?: string | undefined;
	cargoRateCharge?: string | undefined;
	cargoClass?: string | undefined;
	referenceNumber?: string | undefined;
	requestedFlightAndDate?: string | undefined;
	requestedFlightAndDate2?: string | undefined;
	firstCarrierId?: string | undefined;
	routingBySecondCarrier?: string | undefined;
	routingByThirdCarrier?: string | undefined;
	routingToFirstDestinationId?: string | undefined;
	routingToSecondDestinationId?: string | undefined;
	routingToThirdDestinationId?: string | undefined;
	sci?: string | undefined;
    contactToNotify?: string;
	scannedSenderData?: string | undefined;
	senderId?: string | undefined;
	sender?: AddressDatumDto | undefined;
	senderName?: string | undefined;
	senderAddress?: string | undefined;
	senderAccountNumber?: string | undefined;
	senderCityId?: string | undefined;
	senderStateProvince?: string | undefined;
	cargoTotalForRow?: string | undefined;
	issuedBy?: string | undefined;
	isWeightOrValuationChargePrepaid?: boolean | undefined;
	isOtherChargePrepaid?: boolean | undefined;
	isDraft?: boolean | undefined;
	cargoDimensions: CargoDimensionModel[];
	harmonizedCommodityCodes:string[];
	ociList: OciModel[];
	childrenHouseAirwaybills?: AirwaybillListItemDto[];
	isFwbSent?: boolean;
};

interface CityDictionaryItemDto {
    id: string;
    name: string;
    codeIata: string;
    latitude: number;
    longitude: number;
    sourceId: string;
    countryId: string;
    countryName: string;
    countryCodeIso2: string;
    countryCodeIso3: string;
    countryNumericIso: number;
}

export type AddressDatumDto = {
	id: string;
	name?: string | undefined;
	address?: string | undefined;
	postCode?: string | undefined;
	contact?: string | undefined;
	extraContact?: string | undefined;
	normalizedContact?: string | undefined;
	organizationId?: string | undefined;
	accountNumber?: string | undefined;
	isRequiredAccountNumber?: boolean;
	cityId?: string | undefined;
	countryId?: string | undefined;
	stateProvince?: string | undefined;
	placeId?: string | undefined;
	scannedRawData?: string;
    cityName?: string;
    city?: CityDictionaryItemDto;
}

export type AddressInfoDto = {
	senderId?: string;
	recipientId?: string;
}
export enum TokenType {
	Unknown,
	Address,
	Street,
	House,
	Office,
	Place,
	PostalCode,
	Region,
	Country,
	Name,
	AccountNumber,
	Phone,
	Number
}

export type AddressTokenDto = {
	value: string;
	tokenType: TokenType;
	id?: string;
};

export type AirwaybillListItemDto = {
	Id?: string | undefined;
	AirwaybillNumber?: string | undefined;
	CargoChargeableWeight?: string | undefined;
	CargoGrossWeight?: string | undefined;
	Currency?: string | undefined;
	RequestedFlightAndDate?: string | undefined;
	RequestedFlightAndDate2?: string | undefined;
	AirportOfDestination?: {
		CodeIata: string
	},
	AwbDepartureAirport?: {
		CodeIata: string
	},
    RoutingDepartureAirport?: {
		CodeIata: string
	},
	ChildrenHouseAirwaybills?: AirwaybillListItemDto[];
	IsFwbSent?: boolean;
	FmaCode?: string;
};

export type FwbDto = {
	customerApplicationId?: string | undefined;
	fwbCode?: string | undefined;
};

export type FhlDto = {
	parentAirwaybillId?: string | undefined;
	fhlCode?: string | undefined;
};

export enum AirwaybillCopyType {
	Original1ForIssuingCarrier,
	Original2ForConsignee,
	Original3ForShipper,
	Copy4DeliveryReceipt,
	Copy5ExtraCopy,
	Copy6ExtraCopy,
	Copy7ExtraCopy,
	Copy8ForAgent,
}

export interface AwbListQuery {
	awbNumber: string;
	departure?: LocationDto[];
	arrival?: LocationDto[];
	pageNumber: number;
	pageSize: number;
}

export interface OciModel {
	countryId: string;
	infoCode: string;
	customCode: string;
	note: string;
}

export type AirwaybillDetailsDto = {
	parentId: string;
	isMaster: boolean;
	neighborOrChildAirwaybills: string[];
}

export type TrackAndTraceDto = {
	awb: string,
	weight: number,
	pieces: number,
	origin: string,
	destination: string,
	originCoord: string,
	destinationCoord: string,
	carbonEmission: string,
	distance: number,
	time: string,
	eventLocation: string,
	events: TrackEventDto[],
	description?: string
}

export type TrackEventDto = {
	code: string,
	eventLocation: string,
	eventDate: string,
	eventLocationCoord: string,
	flight?: FlightDto,
	pieces: number,
	weight: number,
	volume: string,
	time: string,
	eventUlds?: EventUldDto[]
}

export type FlightDto = {
	number: string,
	origin: string,
	destination: string,
	scheduleDeparture: string,
	actualDeparture: string,
	scheduleArrival: string,
	actualArrival: string,
	originCoord: string,
	destinationCoord: string,
	carbonEmission: string,
	distance: number
}

export type EventUldDto = {
	pieces: number,
	uld: string
}

export type TraceInfoDto = {
	trackAndTrace: TrackAndTraceDto,
	latestEvent: TrackEventDto,
	flights: FlightDto[],
	eventsByLocations: Record<string, TrackEventDto[]>,
	hasSubscription: boolean
}

export type TrackGeneralDto = {
    traceInfo: TraceInfoDto;
    customerApplicationId: string;
}

export type Operation<T> = {
	isSuccess: boolean;
	data?: T;
	message?: string;
}