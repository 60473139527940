import * as React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppDispatch } from '@root/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getFhlCode } from '@store/airwaybills/houseAirwaybillsStore';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useClipboard } from 'use-clipboard-copy';
import clsx from 'clsx';
import BackToViewPanel from './components/BackToViewPanel';
import { useAppSelector } from '@root/store';
import { sendFwb } from "@store/airwaybills/airwaybillsStore";

type Props = {
	onClickBack?: () => void;
	houseAirwaybillId: string;
	onSendFhlCompleted?: () => void;
};

const FhlPage = (props: Props) => {
	const {fhlCode} = useAppSelector((x) => x.houseAirwaybills);

	const dispatch = useAppDispatch();

	const clipboard = useClipboard({copiedTimeout: 1000});

	useEffect(() => {
		dispatch(getFhlCode(props.houseAirwaybillId));
	}, [ props.houseAirwaybillId ]);

	const sendFhlCode = () => {
		dispatch(sendFwb({id: props.houseAirwaybillId, fwbCode: fhlCode}))
			.then(_ => {
				if (props.onSendFhlCompleted) {
					props.onSendFhlCompleted();
				}
			});
	}

	const {t} = useTranslation();

	return (
		<>
			{props.onClickBack && <BackToViewPanel onClickBack={props.onClickBack}/>}
			<h2>{t('awb.fhl')}</h2>
			<Row className="mb-3">
				<Col>
                    <textarea
						disabled={true}
						ref={clipboard.target}
						className="form-control"
						style={{height: 200}}
						value={fhlCode}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="text-center">
					<button
						onClick={clipboard.copy}
						className={clsx('btn', !clipboard.copied ? 'btn-primary' : 'btn-secondary')}
					>
						{clipboard.copied ? (
							t('awb.copied')
						) : (
							<>
								<i className="icon-inline icon-copy"/> {t('awb.copyCode')}
							</>
						)}
					</button>
					{'  '}
					<button className="btn btn-primary" onClick={sendFhlCode}>
						<i className="icon-inline icon-mail"/> {t('awb.send')}
					</button>
				</Col>
			</Row>
		</>
	);
};

export default FhlPage;
