/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
    Input,
    Label,
} from 'reactstrap';
import styles from './InputNumber.module.scss';

interface InputNumberProps {
    id: string;
    name: string;
    label: string;
    step?: number;
    value: number | string;
    hasError?: boolean;
    onChange?: (field: string, value: any) => void;
    onBlur?: (field: string, isTouched: boolean) => void;
}

const InputNumber = (props: InputNumberProps): JSX.Element => {

    const { step, id, value, label, name, onChange, onBlur } = props;

    const updateValue = (newValue: number): void => {
        onChange(name, Math.max(1, newValue || 0));
    };

    const handleBlur = (): void => {
        if (onBlur) {
            onBlur(name, true);
        }
    };

    return (
        <div className={styles.inputControl}>
            <Label for={id}>{label}</Label>
            <div className={styles.inputNumber}>
                <button
                    className={`${styles.inputBtn} ${styles.leftBtn}`}
                    type="button"
                    onClick={_ => updateValue(+(value || 0) - (step || 1))}
                >-</button>
                <Input
                    style={{ height: 'auto' }}
                    type="number"
                    value={value}
                    id={id}
                    name={name}
                    className={styles.inputNumber}
                    onBlur={handleBlur}
                    onChange={(e): void =>
                        updateValue(parseInt(e.target.value, 10))
                    }
                 />
                <button
                    className={`${styles.inputBtn} ${styles.rightBtn}`}
                    type="button"
                    onClick={_ => updateValue(+(value || 0) + (step || 1))}
                >+</button>
            </div>
        </div>
    );
};

export default InputNumber;
