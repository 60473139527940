import { FormikErrors } from 'formik';
import React from 'react';
import _get from 'lodash/get';

const ValidationMessage = (props: { name: string | string[]; errors: FormikErrors<any> }) => {
	const normalizedNames: string[] = Array.isArray(props.name) ? props.name : [props.name];

	return (
		<>
			{normalizedNames.map((x, i) => {
				const error = x.indexOf('.') > - 1 ? _get(props.errors, x) : props.errors[x];

				return <div key={i}>{error && <span className="validationMessage">{error}</span>}</div>;
			})}
		</>
	);
};

export default ValidationMessage;