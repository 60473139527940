import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { IDictionariesCount } from '@models/admin/DictionariesModels';
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';

export interface IState {
    isFetching: boolean;
    model?: IDictionariesCount;
}

// Create the slice.
const slice = createSlice({
    name: 'dictionaries',
    initialState: {
        isFetching: true,
        model: null,
    } as IState,
    reducers: {
        setFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
        setData: (state, action: PayloadAction<IDictionariesCount>) => {
            state.model = action.payload;
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Define action creators.
export const actionCreators = {
    get: () => async (dispatch: Dispatch) => {

        dispatch(slice.actions.setFetching(true));

        const r = await AuthorizedHttpClient.get<IDictionariesCount>('api/statistics/dictionariesCount');

        dispatch(slice.actions.setData(r.data));

        dispatch(slice.actions.setFetching(false));
    }
};