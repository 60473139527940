import React from "react";
import styles from "./AirlineLogo.module.scss";
import {IImageDto} from "@models/shared";

type Props = {
    image?: IImageDto;
    label?: string;
    name: string;
};

export default class AirlineLogo extends React.PureComponent<Props, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return <>

                {this.props.label && (
                    <span className={styles.label}>{this.props.label}</span>
                )}

                {this.props.image && (
                    <span className={styles.image}>
                        <img
                            src={`data:${this.props.image.contentType};base64,${this.props.image.contentBase64}`}
                        />
                    </span>
                )}

                {!this.props.image && (
					<span>{this.props.name}</span>
                )}

        </>;
    }
}