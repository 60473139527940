import React from 'react';
import { ReactComponent as IconInfo } from '@material-design-icons/svg/round/info.svg';
import { ReactComponent as IconQuestion } from '@material-design-icons/svg/round/help.svg';
import s from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Tippy from '@tippyjs/react';

interface ITrackAlert {
  text: string;
  id?: string;
  status: 'success' | 'error';
  additionalInfo?: string;
}

const TrackAlert: React.FC<ITrackAlert> = ({ text, id, status, additionalInfo }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(
      s.container,
      {
        [s.success]: status === 'success',
        [s.error]: status === 'error',
      })}>
      <div className={s.left}>
          {additionalInfo &&
              <Tippy content={additionalInfo}>
                  <IconInfo fill='currentColor' />
              </Tippy>
          }
        <span className={status === "success" && s.awb}>{text}</span>
      </div>
      <Link
        target='_blank'
        to={`/booking/${id}`}
        className={s.link}
      >
        {status === 'success' && t('customerApplicationHistory.openBookingForm')}</Link>
    </div>
  );
};

export default TrackAlert;