import { HttpError } from '@models/shared';
import HttpClient from '@core/HttpClient';

interface DimensionRow {
	pieces: number;
	length: number;
	width: number;
	height: number;
	weight: number;
}

export default class DimensionsService {
	public parse = (text: string) => {
		return HttpClient
			.post<DimensionRow[] & HttpError>(
				`api/CargoDimension/parse`,
				text,
				{
					headers: {
						'Content-Type': 'text/plain'
					}
				}
			);
	};
}