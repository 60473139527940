//Email
//General Email Regex (RFC 5322 Official Standard)
export function isEmail(email: string): boolean {
    if (!email) return false;
    return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(
        email
    );
}

export function isInn(value: string): boolean {
    if (!value)
    	return false;
    const isDigitsOnly = /^\d+$/.test(value);
    if(!isDigitsOnly){
    	return false;
	}
    const digits = value.replace(/\D/g, '');

    const tenDigitsInnCtrl = [2, 4, 10, 3, 5, 9, 4, 6, 8];
	const twelveDigitsInnCtrl1 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
	const twelveDigitsInnCtrl2 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

	const isTenDigitsInn = digits.length == 10;
	const isTwelveDigitsInn = digits.length == 12;

    if(!isTenDigitsInn && !isTwelveDigitsInn){
		return false;
	}

    const arrayOfDigits = Array.from(digits).map(x => parseInt(x));

    if(isTenDigitsInn){
    	let c = 0; // Control value.
    	for (let i = 0; i < 9; i++){
			c += tenDigitsInnCtrl[i] * arrayOfDigits[i];
		}
		c = c%11;

		if(c == 10){
			c = 0;
		}

		return arrayOfDigits[arrayOfDigits.length - 1] == c;
	}

    // Twelve-digit INN.

	let c1 = 0; // Control value.
	for (let i = 0; i < 11; i++){
		c1 += twelveDigitsInnCtrl1[i] * arrayOfDigits[i];
	}
	c1 = c1%11;

	let c2 = 0; // Control value.
	for (let i = 0; i < 10; i++){
		c2 += twelveDigitsInnCtrl2[i] * arrayOfDigits[i];
	}
	c2 = c2%11;

	if(c1 == 10){
		c1 = 0;
	}

	if(c2 == 10){
		c2 = 0;
	}

	return arrayOfDigits[arrayOfDigits.length - 1] == c1 &&
		arrayOfDigits[arrayOfDigits.length - 2] == c2;
}