import { InteractionType } from "@models/contacts/InteractionType";
import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import style from './index.module.scss';
import {
    AccreditationModel,
    ContactInfoDto
} from "@models/organizations/accreditationModels";
import { useEffect, useState } from "react";
import { Option } from "@components/select/AirlineSelect";
import { Input, Tooltip } from "reactstrap";
import nameof from "ts-nameof.macro";
import { FormikProps } from "formik";
import { interactionTypesAvailableForAddingArray, optionalInteractionTypes } from "@helpers/accreditationHelpers";
import Tippy from "@tippyjs/react";
import { ReactComponent as Info } from '@assets/svg/legacy/info.svg';
import Select from "react-select";
import { boolean, number, string } from "yup";

interface Props {
    formikProps: FormikProps<AccreditationModel>,
    contact: ContactInfoDto,
    interactionType: InteractionType,
    onChange: (id: string) => void,
    onRemove?: (contact: ContactInfoDto) => void,
    onAdd?: () => void,
    onUpdateContact?: (contact: ContactInfoDto) => void,
    onOpenEditor?: (contact: ContactInfoDto, isOpen: boolean) => void,
    editedContacts?: Record<string, boolean>,
    hideValidationErrors?: boolean,
    index?: number
}

export const InteractionContact = (
    {
        formikProps,
        contact,
        interactionType,
        onChange,
        onRemove,
        onAdd,
        onUpdateContact,
        onOpenEditor,
        editedContacts,
        hideValidationErrors,
        index
    }: Props) => {

    const {t} = useTranslation();
    const [options, setOptions] = useState<Option[]>([]);
    const [editedField, setEditedField] = useState('');
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
    const interactionTypeName = InteractionType[interactionType];

    useEffect(() => {
        const existingContactIds = formikProps.values.interactionMap[interactionTypeName] || [];

        const contacts = (formikProps.values.contacts || [])
            .filter(contactItem => contactItem.id == contact?.id || !existingContactIds.includes(contactItem.id))
            .map(contact => ({
                value: contact.id,
                label: `${contact.firstName} ${contact.lastName}`
            }));

        const values = [{value: '', label: t('contact.createNew')}, ...contacts];

        setOptions(values);
    }, [formikProps]);

    useEffect(() => {
        const pathToErrors = ['interactionMap', interactionTypeName, `${index}`];
        let errors = null;
        for (const path of pathToErrors) {
            errors = (errors || formikProps.errors)[path];
            if (errors == null) {
                break;
            }
        }

        setValidationErrors(errors || {});
    }, [formikProps.errors]);

    const edit = (e: React.MouseEvent, fieldName: string) => {
        e.preventDefault();
        setEditedField(fieldName);
    }

    const contactsMap = formikProps.values.interactionMap[interactionTypeName];
    const hasEmptyRow = contactsMap.indexOf('') > - 1;
    const canAddContact = !hasEmptyRow && interactionTypesAvailableForAddingArray.indexOf(interactionType) > - 1;
    const canRemove = contactsMap.length > 1 || contact != null && optionalInteractionTypes.includes(interactionType);

    let paddingLeft = 20;
    if (canAddContact) {
        paddingLeft += 20;
    }
    if (canRemove) {
        paddingLeft += 20;
    }

    const cellStyles = {
        [20]: '',
        [40]: style.padding40,
        [60]: style.padding60,
    };

    const [editValue, setEditValue] = useState('');
    const hasValidationError = (fieldName: string) => {
        return !hideValidationErrors && validationErrors[fieldName] != null && editedField != fieldName;
    };

    const renderField = (fieldName: string) => {
        const value = contact == null ? '' : contact[fieldName];

        const openEditor = () => {
            setEditValue(value || '');
            setEditedField(fieldName);
            onOpenEditor(contact, true);
        };

        const closeEditor = () => {
            setEditedField('');
            onOpenEditor(contact, false);
        }

        const saveChanges = () => {
            const newValue = editValue.trim();
            if (newValue != contact[fieldName]) {
                onUpdateContact({...contact, [fieldName]: newValue});
            }

            closeEditor();
        };

        return <td
            className={clsx(contact != null ? style.editable : '', editedField == fieldName ? style.edit : '', hasValidationError(fieldName) ? style.errorField : '')}
            id={`${interactionTypeName}${index}${fieldName}`}>
            {editedField != fieldName
                ?
                <>
                    {value}
                    {contact != null && !editedContacts[contact.id] &&
                        <div className={style.actions}>
                            <a href={''} onClick={e => {
                                e.preventDefault();
                                openEditor();
                            }}><i className={'icon-edit'}/></a>
                        </div>}
                    {hasValidationError(fieldName) &&
                        <Tooltip isOpen={true} target={`${interactionTypeName}${index}${fieldName}`}
                                 placement={"top"}>
                            {validationErrors[fieldName]}
                        </Tooltip>}
                </>
                : <div className={style.editField}>
                    <Input
                        key={fieldName}
                        value={editValue}
                        onKeyUp={e => {
                            if (e.key == 'Escape') {
                                closeEditor();
                            } else {
                                if (e.key == 'Enter')
                                    saveChanges();
                            }
                        }}
                        onChange={e => {
                            setEditValue(e.currentTarget.value)
                        }}/>
                    <div className={style.editActions}>
                        <a href={''}
                           onClick={async e => {
                               e.preventDefault();
                               saveChanges();
                           }}><i className={'icon-save'}/></a>
                        <a href={''}
                           onClick={e => {
                               e.preventDefault();
                               closeEditor();
                           }}><i className={'icon-close'}/></a>
                    </div>
                </div>
            }
        </td>
    };

    return <tr
        className={clsx(style.Interaction, index == 0 ? style.startRow : '', contactsMap.length == 0 || index == contactsMap.length - 1 ? style.endRow : '')}>
        {index == 0 ?
            <td rowSpan={Math.max(1, contactsMap.length)}>
                {t(`interactionTypes.${interactionTypeName}`)}
                <Tippy content={t(`interactionTypes.hints.${interactionTypeName}`)}>
                    <i style={{cursor: 'pointer'}} className="d-inline-block ml-1">
                        {' '}<Info/>
                    </i>
                </Tippy>
            </td> : null}

        <td className={clsx(style.editable, editedField === 'id' ? style.edit : '', cellStyles[paddingLeft], hasValidationError('id') ? style.errorField : '')}
            id={`${interactionTypeName}${index}id`}>
            {editedField === 'id'
                ? <Select
                    isClearable
                    isSearchable
                    options={options}
                    value={options?.find(o => o.value == contact?.id)}
                    onChange={value => onChange(value.value)}
                    onBlur={() => setEditedField('')}
                />
                : <>
                    {contact?.firstName} {contact?.lastName}
                    <div className={style.actions}>
                        {canAddContact && <a href='' onClick={(e) => {
                            e.preventDefault();
                            onAdd();
                        }}>
                            <i className={'icon-plus'}/>
                        </a>}
                        <a href='' onClick={(e) => edit(e, 'id')}>
                            <i className={'icon-edit'}/>
                        </a>
                        {canRemove && <a href='' onClick={(e) => {
                            e.preventDefault();
                            onRemove(contact);
                        }}>
                            <i className={'icon-trash'}/>
                        </a>}
                    </div>
                </>}
            {hasValidationError('id') &&
                <Tooltip isOpen={true} target={`${interactionTypeName}${index}id`} placement={"left"}>
                    {validationErrors['id']}
                </Tooltip>}
        </td>
        {renderField(nameof<ContactInfoDto>(x => x.jobTitle))}
        {renderField(nameof<ContactInfoDto>(x => x.email))}
        {renderField(nameof<ContactInfoDto>(x => x.phone))}
    </tr>;
};