import Async from 'react-select/async';
import DropdownIndicator from '@components/select/components/DropdownIndicator';
import { CountryDictionaryItem } from '@models/countries';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import CountryService from '@services/CountryService';

interface Props {
    countryId: string;
    onChange: (value: CountryDictionaryItem) => void;
}

export const CountrySelect = ({ countryId, onChange }: Props) => {
    const [isCountryTermEnglish, setIsCountryTermEnglish] = useState(false);
    const [country, setCountry] = useState<CountryDictionaryItem>(null);

    const fetchCountry = useMemo(async () => {
        if (countryId == country?.id) {
            return country;
        }

        let countryItem: CountryDictionaryItem = null;

        if (countryId != null) {
            const countries = await new CountryService().getAllCountries();
            if (countries.length > 0) {
                countryItem = countries.filter(x => x.id == countryId)[0];
            }
        }

        return countryItem;
    }, [countryId]);

    const initCountry = async () => {
        fetchCountry.then((v) => {
            setCountry(v);
        });
    };

    useEffect(() => {
        initCountry();
    }, [countryId]);

    const fetchCountriesDebounced = useDebouncedCallback((term: string, resolve: (options: CountryDictionaryItem[]) => void) => {
        const search = term.toLowerCase();
        setIsCountryTermEnglish(/[a-zA-Z]+/.test(search));
        new CountryService()
            .getAllCountries()
            .then((data: CountryDictionaryItem[]) => {
                const countries = data.filter(country =>
                    search == ''
                    || country.name.toLowerCase().includes(search)
                    || country.translatedName.toLowerCase().includes(search));
                resolve(countries);
            });
    }, 400);

    const fetchCountries = (term: string) =>
        new Promise<CountryDictionaryItem[]>(resolve => {
            if (term.length < 3) {
                return;
            }

            fetchCountriesDebounced(term, resolve);
        });

    return <Async
        loadOptions={fetchCountries}
        components={{ DropdownIndicator }}
        onChange={onChange}
        isclearable
        value={country}
        placeholder={t('awb.country')}
        getOptionLabel={option => isCountryTermEnglish ? option.name : option.translatedName}
        getOptionValue={option => option.id}
    />;
};