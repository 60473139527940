import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadProgress } from "@models/shared";
import AwbUploadModal, { AwbUploadingMode } from "@scenes/customerApplication/awb/components/AwbUploadModal";
import { unwrap } from "@helpers/reduxHelpers";
import { uploadAndRecognizeAwbImage } from "@store/airwaybills/airwaybillsStore";
import { useAppDispatch } from "@root/store";
import { toast } from "react-toastify";
import clsx from "clsx";

interface Props {
	parentId?: string;
	onRecognize: (guid: string) => void;
	buttonClassName?: string;
	buttonTitle?: string;
}

export const RecognizeControl = ({parentId, onRecognize, buttonClassName, buttonTitle}: Props) => {
	const [isMasterAwbUploadModalOpen, setMasterAwbUploadModalOpen] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadingMode, setUploadingMode] = useState(AwbUploadingMode.None);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const onUploadProgress: UploadProgress = (e) => {
		const pc = Math.round((100 * e.loaded) / e.total);
		if (pc > 0) {
			setUploadProgress(25);
			setTimeout(() => {
				setUploadProgress(50);
				setTimeout(() => {
					if (pc == 100) {
						setUploadProgress(100);
						setTimeout(() => {
							setUploadingMode(AwbUploadingMode.Recognizing);
						}, 500);
					} else if (pc > 50) {
						setUploadProgress(pc);
					}
				}, 1000);
			}, 2000);
		}
	};

	const uploadMasterAwb = async (file: File) => {
		setUploadingMode(AwbUploadingMode.Uploading);

		const response = unwrap(
			await dispatch(uploadAndRecognizeAwbImage({
				parentId: parentId,
				customerApplicationId: '00000000-0000-0000-0000-000000000000',
				file,
				onUploadProgress
			}))
		);

		if (!response.isError) {
			setUploadingMode(AwbUploadingMode.Completed);

			setTimeout(() => {
				setMasterAwbUploadModalOpen(false);

				onRecognize(response);
			}, 500);
		} else {
			toast.error(`Error occured: ${response.message}`);
		}
	};

	return <>
		<a className={buttonClassName || clsx('btn', 'btn-primary')} onClick={_ => setMasterAwbUploadModalOpen(true)} color="primary">
			{buttonTitle || t('awb.recognizeAwbFromFile')}
		</a>

		<AwbUploadModal
			isOpen={isMasterAwbUploadModalOpen}
			onUpload={(file) => uploadMasterAwb(file)}
			onClickCloseButton={() => setMasterAwbUploadModalOpen(false)}
			uploadProgress={uploadProgress}
			mode={uploadingMode}
		/>
	</>
}