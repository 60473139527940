import * as React from 'react';
import { isMainTenant, poweredByLogo } from '@helpers/tenantsHelper';
import s from './style.module.scss';

function PoweredBy(): React.ReactElement {
  return (
    <>
      {!isMainTenant && (
        <span className={s.container}>
          <span>Powered by</span>
          <img src={poweredByLogo} alt='CargoBooking365' title='CargoBooking365' />
        </span>
      )}
    </>
  );
}

export default PoweredBy;
