// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { StylesConfig } from 'react-select';
import React, { useMemo } from 'react';
import { SortDirection } from '@models/entityNavigation/sorting';
import { useTranslation } from 'react-i18next';
import DropdownIndicator from '@components/select/components/DropdownIndicator';
import StaticSelect from '@components/select/StaticSelect';

export type AvailableProperty = { label: string; propertyName: string; shortPropertyName?: string};
type SelectOption = { label: string; property: AvailableProperty; sortDirection: SortDirection };
export type SortSelectValue = { propertyName: string; shortPropertyName?: string; sortDirection: SortDirection };

type Props = {
	availableProperties: AvailableProperty[];
	onChange: (value?: SortSelectValue) => void;
	value: SortSelectValue;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	placeholder?: any;
	isClearable?: boolean;
	isSearchable?: boolean;
};

export const createSortSelectValue = (
	availableProperty: AvailableProperty,
	sortDirection: SortDirection
): SortSelectValue => {
	return { propertyName: availableProperty.propertyName, shortPropertyName: availableProperty.shortPropertyName, sortDirection };
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const customStyles: Partial<StylesConfig> = {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	control: (base, state) => ({
		...base,
		height: '36px',
		'min-height': '36px',
		borderColor: '#EDF1F4',
	}),

	dropdownIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	indicatorSeparator: (provided) => ({
		display: 'none',
	}),

	valueContainer: (provided) => ({
		...provided,
		paddingLeft: 10,
		fontWeight: 500,
		color: '#121212',
	}),

	singleValue: (provided) => ({
		...provided,
		paddingLeft: 10,
		fontWeight: 500,
	}),

	placeholder: (provided) => ({
		...provided,
		paddingLeft: 10,
	}),
};

const SortSelect = (props: Props) => {

	const { t } = useTranslation();

	const sortDirections = [SortDirection.Ascending, SortDirection.Descending];

	const availableOptions: SelectOption[] = useMemo(() => {
		const arr = [];

		props.availableProperties.forEach((availableProperty) => {
			sortDirections.forEach((sortDirection) => {
				const sortDirectionText =
					sortDirection === SortDirection.Ascending ? t('sorting.ascending') : t('sorting.descending');

				arr.push({
					label: `${availableProperty.label} - ${sortDirectionText}`,
					property: availableProperty,
					sortDirection: sortDirection,
				} as SelectOption);
			});
		});

		return arr;
	}, [props.availableProperties]);

	let value: SelectOption[] = [];

	if (props.value != null) {
		const o = availableOptions.find(
			(x) => x.property.propertyName == props.value.propertyName && x.sortDirection == props.value.sortDirection
		);
		value = [o];
	}else{
		value = [];
	}

	return (
		<StaticSelect<SelectOption>
			isSearchable={false}
			components={{ DropdownIndicator }}
			availableOptions={availableOptions}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			selectedLabel={x => <>x.label</>}
			optionLabel={(x) => <>{x.label}</>}
			onChange={(opt) => {
				const option = opt?.length > 0 ? opt[0] : null;
				if (option == null) {
					props.onChange(null);
					return;
				}
				props.onChange({
					propertyName: option.property.propertyName,
					sortDirection: option.sortDirection,
                    shortPropertyName: option.property.shortPropertyName
				} as SortSelectValue);
			}}
			value={value}
			placeholder={props.placeholder || t('sorting.sorting')}
			styles={customStyles}
			// isClearable={props.isClearable}
			// {...{ isSearchable: props.isSearchable }}
		/>
	);
};

export default SortSelect;