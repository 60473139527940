import { StylesConfig } from 'react-select';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SelectStyles: Partial<StylesConfig> = {
	control: (base) => ({
		...base,
		minHeight: '36px'
	}),

	clearIndicator: (provided) => ({
		...provided,
		padding: 0,
		marginRight: '-5px',
	}),

	dropdownIndicator: (provided) => ({
		...provided,
		padding: '5px',
	}),

	valueContainer: (provided) => ({
		...provided,
	}),

	placeholder: (provided) => ({
		...provided,
		paddingLeft: 10,
		fontSize: 15,
		fontFamily: 'Inter',
	}),

	singleValue: (provided) => ({
		...provided,
		paddingLeft: 10,
		fontWeight: 500,
	}),

	multiValue: (provided) => ({
		...provided,
		fontWeight: 500,
		color: '#121212',
		backgroundColor: '#f7fafc',
	}),

	multiValueLabel: (provided) => ({
		...provided,
		fontSize: 14,
		fontFamily: 'Inter',
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),
};

export default SelectStyles;
