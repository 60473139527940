import * as React from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { actionCreators } from '@store/admin/dictionaries/DictionariesStore';
import AdminMenu from '@scenes/admin/components/AdminMenu';
import i18n from 'i18next';
import PageHeader from '@components/PageHeader';

const DictionariesPage = () => {
  const dispatch = useAppDispatch();
  const { model } = useAppSelector(x => x.dictionaries);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actionCreators.get());
  }, []);

  const itemsTemplate = useMemo(() => {

    const description = t('admin.dictionaries.total');

    return [
      {
        name: t('admin.dictionaries.aircrafts'),
        iconClassName: 'icon-plane',
        total: model?.aircraftCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.airlines'),
        iconClassName: 'icon-globe-1',
        total: model?.airlineCount || 0,
        path: '/admin/dictionaries/airlines',
        description,
      },
      {
        name: t('admin.dictionaries.airplanes'),
        iconClassName: 'icon-airplanes',
        total: model?.airplaneCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.airportGroups'),
        iconClassName: 'icon-airport-group',
        total: model?.airportGroupCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.airports'),
        iconClassName: 'icon-airport',
        total: model?.airportCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.cargoTypes'),
        iconClassName: 'icon-box',
        total: model?.cargoTypeCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.cities'),
        iconClassName: 'icon-map-pin',
        total: model?.cityCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.countries'),
        iconClassName: 'icon-globe',
        total: model?.countryCount || 0,
        description,
      },
      {
        name: t('admin.dictionaries.tariffs'),
        iconClassName: 'icon-box',
        total: model?.tariffCount || 0,
        path: '/admin/dictionaries/tariffs',
        description,
      },
      {
        name: t('admin.dictionaries.users'),
        iconClassName: 'icon-box',
        total: model?.usersCount || 0,
        path: '/admin/dictionaries/users',
        description,
      },
      {
        name: t('admin.dictionaries.organizations'),
        iconClassName: 'icon-box',
        total: model?.organizationsCount || 0,
        path: '/admin/dictionaries/organizations',
        description,
      },
    ];
  }, [model, i18n.language]);

  const processedItems = useMemo(() => {
    return itemsTemplate.map(x => {
      return {
        ...x,
        description: x.description.replace('{0}', x.total.toString()),
      };
    });
  }, [itemsTemplate]);

  return <>
    <PageHeader title={t('admin.dictionaries.title')} backUrl='/admin/panel' />
    <AdminMenu items={processedItems} />
  </>;
};

export default DictionariesPage;