import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import TariffsService from '@services/TariffsService';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { TariffDictionaryItem } from '@models/tariffs';

export type State = {
    tariffs?: IPagingWrapper<TariffDictionaryItem>;
    isFetching: boolean;
};

export const getTariffsDictionary = createAppThunk(
    'tariffs/dictionary',
    async (nav: StandardNavigation) => {
        const tariffsService = new TariffsService();
        const { data } = await tariffsService.dictionary(nav);
        return data;
    }
);

const slice = createSlice({
    name: 'tariffs',
    initialState: {
        tariffs: null,
        isFetching: true,
    } as State,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTariffsDictionary.fulfilled, (state, action) => {
            state.isFetching = false;
            state.tariffs = action.payload;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getTariffsDictionary.pending, (state, action) => {
            state.isFetching = true;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getTariffsDictionary.rejected, (state, action) => {
            state.isFetching = false;
        });
    },
});

export const { reducer } = slice;
export const { actions } = slice;
