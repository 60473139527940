import React, {ChangeEvent} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import s from "../../filters.module.scss";
import { FilterItemDto, SaleFilterDto, SaleStatus } from '@models/customerRequests/customerRequestModels';

interface IFilterGroupProps {
    groupTitle: string;
    filter: string[] | SaleStatus[];
    checkChanged: (id: string, checked: boolean) => void;
    filterItems: FilterItemDto[];
}

const FilterGroup: React.FC<IFilterGroupProps> = ({filter, checkChanged, filterItems, groupTitle}) => {
    return (filterItems && filterItems.length > 0 && (
            <div>
                <h4 className={s.subHeader}>{groupTitle}</h4>
                {filterItems.map((item, index) => {
                    return (
                        <div key={index}>
                            <FormGroup check inline>
                                <Label check className="filter">
                                    <Input
                                        id={item.id}
                                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                            checkChanged(e.target.id, e.target.checked);
                                        }}
                                        type="checkbox"
                                        checked={filter.some((value) => value === item.id)}
                                    />
                                    <span>{item.name}</span>
                                </Label>
                            </FormGroup>
                        </div>
                    );
                })}
            </div>
        )
    );
};

export default FilterGroup;
