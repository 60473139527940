import React, { ChangeEvent, useEffect, useState } from 'react';
import s from './style.module.scss';
import { ReactComponent as IconSearch } from '@material-design-icons/svg/round/travel_explore.svg';
import { ReactComponent as IconClose } from '@material-design-icons/svg/round/close.svg';
import { Button, PageHeader } from '@root/components';
import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@root/store';
import { getTrackAndTraceInfoAsync, resetTrack } from '@store/trackAndTrace';
import TrackAlert from '@scenes/customerRequest/searchForm/components/TrackAlert';

const TrackAndTraceForm: React.FC = () => {
    const { t } = useTranslation();
    const { traceData, traceLoading, finished } = useAppSelector(s => s.tracking);
    const dispatch = useAppDispatch();
    const [airwayBillNumber, setAirwayBillNumber] = useState<string>('');
    const [isAlert, setIsAlert] = useState<boolean>(false);
    const [additionalErrorText, setAdditionalErrorText] = useState("");

    const searchHandler = () => {
        dispatch(getTrackAndTraceInfoAsync({ number: airwayBillNumber, force: true }));
    };

    const handleChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
        setAirwayBillNumber(e.target.value);
    };

    const resetHandler = () => {
        dispatch(resetTrack());
        setIsAlert(false);
        setAirwayBillNumber('');
    };

    useEffect(() => {
        setIsAlert(finished);
    }, [finished]);

    useEffect(() => {
        return () => {
            dispatch(resetTrack());
        };
    }, []);

    useEffect(() => {
        switch (traceData?.message) {
            case "L_CUSTOMER_APP_NOT_FOUND":
                setAdditionalErrorText(t('trackHistory.youCanTrackOnlyCargoBooking365'));
                break;
            case "L_NOT_CURRENT_ORG_APP":
                setAdditionalErrorText(t('trackHistory.youCanTrackOnlyOrgCargoes'));
                break;
            default:
                setAdditionalErrorText("");
                break;
        }
    }, [traceData?.message]);

    return (
        <div className={s.container}>
            <PageHeader
                title={t('trackHistory.title')}
                size='subHeader' />
            <Input
                disabled={traceLoading}
                placeholder={t('trackHistory.enterAwbNumber')}
                onChange={event => handleChangeNumber(event)}
                value={airwayBillNumber}
                tag={InputMask}
                mask='999-99999999'
            />
            {isAlert && !traceLoading &&
                <TrackAlert
                    status={traceData?.data?.traceInfo ? 'success' : 'error'}
                    text={
                        traceData?.data?.traceInfo
                            ? `${traceData?.data?.traceInfo?.trackAndTrace?.origin} - ${traceData?.data?.traceInfo?.trackAndTrace?.destination}`
                            : t('trackHistory.dataNotAvailable')
                    }
                    additionalInfo={additionalErrorText}
                    id={traceData?.data?.customerApplicationId} />
            }
            <div className={s.buttons}>
                <Button
                    icon={<IconSearch fill='currentColor' />}
                    type='fill'
                    variant='primary'
                    disabled={airwayBillNumber.length === 0}
                    onClick={searchHandler}
                    loading={traceLoading}
                >
                    {traceLoading ? t('trackHistory.searchExecuting') : t('trackHistory.requestInfo')}
                </Button>
                {isAlert &&
                    <Button
                        icon={<IconClose fill='currentColor' />}
                        type='text'
                        variant='secondary'
                        onClick={resetHandler}
                    >
                        {t('trackHistory.resetSearch')}
                    </Button>
                }
            </div>
        </div>
    );
};

export default TrackAndTraceForm;