import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {
    AirwaybillCopyType,
    AirwaybillDetailsDto,
    AirwaybillDto,
    AirwaybillListItemDto,
    AwbListQuery,
    CreateAirwaybill,
    FwbDto,
    Operation, TrackGeneralDto,
    UpdateAirwaybill,
} from '@models/awbs/awbsModels';
import { IPagingWrapper2 } from '@models/entityNavigation/IPagingWrapper';
import { HttpError, UploadProgress } from '@models/shared';
import qs from 'qs';

export default class AirwaybillsService {
    public recognizeImage = (customerApplicationId: string, file: File, onUploadProgress: UploadProgress, parentId?: string) => {
        const formData = new FormData();
        formData.append('file', file);
        if (parentId != null) {
            formData.append('parentId', parentId);
        }

        formData.append('customerApplicationId', customerApplicationId);

        return AuthorizedHttpClient.post<string & HttpError>(`api/airwaybills/file`, formData, {
            onUploadProgress,
        });
    };

    public create = (model: CreateAirwaybill) => {
        return AuthorizedHttpClient.post<string & HttpError>(`api/airwaybills`, model);
    };

    public update = (model: UpdateAirwaybill) => {
        return AuthorizedHttpClient.put<HttpError>(`api/airwaybills`, model);
    };

    public getFileUrl = (airwaybillId: string) => {
        return `api/airwaybills/${airwaybillId}/file`;
    };

    public getTrackAndTraceInfoAsync = async (awbNumber: string, force: boolean) => {
        const response = await AuthorizedHttpClient.get<Operation<TrackGeneralDto>>(`api/airwaybills/track-and-trace/${awbNumber}/${force}`);
        return response.data;
    };

    public get = (airwaybillId: string) => {
        return AuthorizedHttpClient.get<AirwaybillDto>(`api/airwaybills/${airwaybillId}`);
    };

    public getAirwaybillDetailsById = (airwaybillId: string) => {
        return AuthorizedHttpClient.get<AirwaybillDetailsDto>(`api/Airwaybills/airwaybill-details/${airwaybillId}`);
    };

    public list = (query: AwbListQuery) => {
        const _filters: string[] = [];
        _filters.push('ParentAirwaybillId eq null');
        if (query.awbNumber) {
            _filters.push(`(indexof(AirwaybillNumber, '${query.awbNumber}') gt 0 or indexof(AirwaybillNumber, '${query.awbNumber}') eq 0)`);
        }
        if (query.departure && query.departure.length) {
            _filters.push(
                '(' +
                query.departure
                    .map((x) => `AwbDepartureAirport\Id eq ${x.id}`)
                    .join(' or ') +
                ')',
            );
        }
        if (query.arrival && query.arrival.length) {
            _filters.push(
                '(' +
                query.arrival
                    .map((x) => `AirportOfDestination\Id eq ${x.id}`)
                    .join(' or ') +
                ')',
            );
        }

        return AuthorizedHttpClient.get<IPagingWrapper2<AirwaybillListItemDto>>(
            `api/airwaybills?$select=*
				&$orderby=CreateTime desc
				&$skip=${(query?.pageNumber || 0) * (query?.pageSize || 0)}
				&$top=${query?.pageSize || 0}
				&$expand=AirportOfDestination($select=CodeIata),RoutingDepartureAirport($select=CodeIata),ChildrenHouseAirwaybills($select=*)
				&$count=true
				${_filters.length ? ('&$filter=' + _filters.join(' and ')) : ''}
			`);
    };

    public getFwbCode = (airwaybillId: string) => {
        return AuthorizedHttpClient.get<string>(`api/airwaybills/${airwaybillId}/fwbCode`);
    };

    public remove = (airwaybillId: string) => {
        return AuthorizedHttpClient.delete<HttpError>(`api/airwaybills/${airwaybillId}`);
    };

    public sendFwb = (id: string, code: string) => {
        return AuthorizedHttpClient.post<HttpError>(`api/airwaybills/sendFwb`, { Id: id, 'FwbCode': code });
    };

    public importFromFwb = (model: FwbDto) => {
        return AuthorizedHttpClient.post<string & HttpError>(`api/airwaybills/fwb`, model);
    };

    public getPdfFileUrl = (
        airwaybillId: string,
        copyTypes?: AirwaybillCopyType[],
        extraCopyCount?: number,
    ): string => {
        const q = [];

        if (copyTypes != null && copyTypes.length > 0) {
            q.push(qs.stringify({ copyTypes }));
        }

        if (extraCopyCount != null && extraCopyCount > 0) {
            q.push(`extraCopyCount=${extraCopyCount}`);
        }

        return `api/airwaybills/${airwaybillId}/pdf?${q.join('&')}`;
    };
}
