import styles from '../CustomerApplicationPage.module.scss';
import listStyles from '@assets/list.module.scss';
import AirlineLogo from '@components/AirlineLogo';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Currency from '@components/Currency';
import clsx from 'clsx';
import PropCard from '@components/PropCard';
import { useAppDispatch, useAppSelector } from '@root/store';
import { BookingStatus } from '@models/enums';
import { fetchSale, fetchCustomerRequest } from '@store/customerApplications/customerApplicationsStore';

const CustomerApplicationInfoHeader: React.FC = () => {
    const {t} = useTranslation();
    const {sale, customerRequest, customerApplication} = useAppSelector((x) => x.customerApplications);
    const {traceData} = useAppSelector(s => s.tracking);
    const [bookingStatus, setBookingStatus] = useState<BookingStatus>(BookingStatus.Request);
    const [requestId, setRequestId] = useState<string>(null);
    const dispatch = useAppDispatch();


    useEffect(() => {
        dispatch(fetchSale(customerApplication?.saleId));
    }, [customerApplication]);

    useEffect(() => {
        setRequestId(customerApplication?.customerRequestId || customerRequest?.id);
    }, [customerApplication, customerRequest]);

    useEffect(() => {
        if (requestId != null) {
            dispatch(fetchCustomerRequest(requestId));
        }
    }, [requestId]);

    useEffect(() => {
        if (traceData) {
            if (traceData.isSuccess && traceData.data.traceInfo.trackAndTrace.events.length > 0) {
                const codes = traceData.data.traceInfo.trackAndTrace.events.map(event => event.code);

                if (codes.filter(code => ['CLD', 'DEP', 'TRA', 'RCT', 'ARR', 'ARV', 'ARE'].indexOf(code) >= 0).length > 0) {
                    setBookingStatus(BookingStatus.Execution);
                }
            }
        }
    }, [traceData?.data]);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerContainerCarrier}>
                <div className={styles.headerContainerCarrierLogo}>
                    <AirlineLogo image={sale?.airlineLogo} name={sale?.airlineName}/>
                </div>
                <div className={styles.headerContainerCarrierDirection}>
                    <PropCard label={t('customerApplication.form.where')}
                              value={`${sale?.departurePoint?.codeIata || '-'}`}/>
                    <div className={clsx(listStyles.arrowDirection)}>
                        <span className={`${listStyles.arrowRightIcon} icon-arrow_right_long`}/>
                    </div>
                    <PropCard label={t('customerApplication.form.to')}
                              value={`${sale?.destinationPoint?.codeIata || '-'}`}/>
                </div>
            </div>
            <div className={styles.propCards}>
                <PropCard label={t('customerApplication.form.cargoPlaces')}
                          value={`${customerApplication?.cargoTotalAmount || customerRequest?.totalPlaces || '-'}`}/>
                <PropCard label={t('customerApplication.form.cargoWeight')}
                          value={`${customerApplication?.actualCargoWeight || customerRequest?.totalWeight || '-'} ${t('units.kg')}`}/>
                <PropCard label={t('chargeableWeight')}
                          value={`${customerApplication?.paidCargoWeight || sale?.chargeableWeight || '-'} ${t('units.kg')}`}/>
                <PropCard label={t('customerApplication.form.cargoVolume')}
                          value={`${customerApplication?.cargoTotalVolume || customerRequest?.totalVolume || '-'} ${t('units.m3')}`}/>
                <PropCard label={t('request.natureOfCargo')}
                          value={customerRequest?.natureOfCargoTitle}/>
                <PropCard
                    label={bookingStatus == BookingStatus.Execution ? t('transportationCost') : t('transportationCostPrev')}>
                    <Currency
                        currencyCode={sale?.currencyCode}
                        amount={sale?.total}
                    />
                </PropCard>
            </div>
        </div>
    );
};

export default CustomerApplicationInfoHeader;