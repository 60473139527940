import s from './styles.module.scss';
import { Button } from '@root/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCreate } from '@material-design-icons/svg/round/add_box.svg';
import { ReactComponent as IconScan } from '@material-design-icons/svg/round/document_scanner.svg';
import { ReactComponent as IconCode } from '@material-design-icons/svg/round/code.svg';

const Awb = () => {
  const { t } = useTranslation();

  return (
    <div className={s.buttons}>
      <Button disabled icon={<IconCreate fill='currentColor' />} type='outline' variant='primary'>
        {t('awb.createNewEmptyAwb')}
      </Button>
      <Button disabled icon={<IconScan fill='currentColor' />} type='outline' variant='primary'>
        {t('awb.recognizeAwbFromFile')}
      </Button>
      <Button disabled icon={<IconCode fill='currentColor' />} type='outline' variant='primary'>
        {t('awb.importFromFwb')}
      </Button>
    </div>
  );
};

export default Awb;