import {StandardNavigation} from "@models/entityNavigation/StandardNavigation";
import {ApplicationEntityType} from "@models/ApplicationEntityType";
import { CustomerApplicationEventType } from '@models/admin/notificationsModels';

export type CustomerApplicationEventDto = {
	userDisplayName: string;
	organizationName: string;
	timestamp: string;
	entityType: ApplicationEntityType;
	eventType: CustomerApplicationEventType;
}

export class GetCustomerApplicationEventLog extends StandardNavigation{
	constructor(customerApplicationId: string) {
		super();

		this.customerApplicationId = customerApplicationId;
	}
	customerApplicationId: string;
}