import * as React from 'react';
import { Switch, RouteComponentProps } from 'react-router';
import {
    withTranslation,
    WithTranslation,
} from 'react-i18next';
import AwbAllItemsPage from './components/list/AwbAllItemsPage';
import AwbNewForm from './components/edit/AwbNewForm';
import AwbUpdateForm from './components/edit/AwbUpdateForm';
import AdminLayout from '@layouts/AdminLayout';

type Props = RouteComponentProps<{}> & WithTranslation;

const AwbRoutes = (props: Props) => {
    return (
        <>
            <Switch>
                <AdminLayout
                    exact
                    path={`${props.match.url}/list`}
                    component={AwbAllItemsPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/create/:parentId?`}
                    component={AwbNewForm}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/edit/:id`}
                    component={AwbUpdateForm}
                />
            </Switch>
        </>
    );
};

export default withTranslation()(AwbRoutes);