import {
  GetCustomerApplicationDto,
  CreateCustomerApplication,
  UpdateCustomerApplication,
  BookingConfirmationDto,
  CreateCustomerApplicationDto, UpdateCustomerApplicationResultDto,
} from '@models/customerApplications/customerApplicationModels';
import { HttpError } from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { Navigation, StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import {
  GetAirlinesForApplicationsByTerm,
  GetAirlinesForApplicationsByTermDto,
  IGetCustomerApplicationHistoryDto,
} from '@models/customerApplications/customerApplicationHistoryModels';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import {
  CustomerApplicationEventDto,
  GetCustomerApplicationEventLog,
} from '@models/customerApplications/customerApplicationEventsModels';
import { serialize } from 'object-to-formdata';
import { addFileToFormData } from '@helpers/filesHelpers';
import { CommentDto } from '@models/comment';
import { DateTime } from '@helpers/DateTime';
import SessionManager from '@root/SessionManager';
import { da } from 'date-fns/locale';

export default class CustomerApplicationsService {
  public create = async (model: CreateCustomerApplication, files: File[]) => {

    const formData = serialize({ cmd: model });

    addFileToFormData(formData, 'files', files);

    return await AuthorizedHttpClient.post<CreateCustomerApplicationDto & HttpError>(
      '/api/customerApplications',
      formData,
    );
  };

  public addComment  = async (data: {customerApplicationId: string, body: string}) => {
    const commentData: CommentDto = {
      id: "",
      authorName: "",
      body: data.body,
      authorEmail: SessionManager?.user?.email,
      customerApplicationId: data.customerApplicationId,
      created: DateTime.now(false).toISOString(),
    };
    return await AuthorizedHttpClient.post<CommentDto[] & HttpError>(
      '/api/comments/create',
      serialize(commentData),
    );
  };

  public getHistory = async (model: Navigation) => {
    return await AuthorizedHttpClient.post<IPagingWrapper<IGetCustomerApplicationHistoryDto> & HttpError>(
      '/api/customerApplications/history',
      model,
    );
  };

  public get = async (customerApplicationId: string) => {
    return await AuthorizedHttpClient.get<GetCustomerApplicationDto & HttpError>(
      `/api/customerApplications/${customerApplicationId}`,
    );
  };

	public getBookingConfirmation = async (customerApplicationId: string) => {
    return await AuthorizedHttpClient.get<BookingConfirmationDto & HttpError>(
			`/api/customerApplications/bookingConfirmation/${customerApplicationId}`
		);
	};

  public update = async (model: UpdateCustomerApplication, filesToUpload: File[]) => {

    const formData = serialize({ cmd: model });

    addFileToFormData(formData, 'files', filesToUpload);

    return await AuthorizedHttpClient.put<UpdateCustomerApplicationResultDto>('/api/customerApplications', formData);
  };

  public getAirlines = async (model: GetAirlinesForApplicationsByTerm) => {
    return await AuthorizedHttpClient.post<IPagingWrapper<GetAirlinesForApplicationsByTermDto> & HttpError>(
      '/api/customerApplications/history/airlines',
      model,
    );
  };

  public getEvents = async (nav: GetCustomerApplicationEventLog) => {
    return await AuthorizedHttpClient.post<IPagingWrapper<CustomerApplicationEventDto> & HttpError>(
      '/api/customerApplications/events',
      nav,
    );
  };
}