import { FilterConnection, PagingFilter } from '@models/entityNavigation/filtering';
import { PropertySorter, PropSorter } from '@models/entityNavigation/sorting';

export class StandardNavigation {

    filters: FilterConnection[] = [];
    sorters: PropertySorter[] = [];
    pagingFilter: PagingFilter = new PagingFilter(0, 5);
}

export type Navigation = {
    filters: FilterConnection[];
    sorters: PropSorter[];
    pagingFilter: PagingFilter;
}