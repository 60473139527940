import React, { useEffect, useState } from 'react';
import { AirwaybillDto, CreateAirwaybill, CreateHouseAirwaybill, IOtherCharge } from '@models/awbs/awbsModels';
import { useParams } from 'react-router';
import { createAwb, prepareNewAwb } from '@store/airwaybills/airwaybillsStore';
import { getValidationErrors } from '@helpers/responseHelpers';
import { useAppDispatch } from '@root/store';
import { unwrap } from '@helpers/reduxHelpers';
import { useTranslation } from 'react-i18next';
import BackToViewPanel from './components/BackToViewPanel';
import { GetCustomerApplicationDto } from '@models/customerApplications/customerApplicationModels';
import { AirlineDto, GetCustomerRequestDto, GetSaleDto } from '@models/customerRequests/customerRequestModels';
import { isoUtcDateToShortLocalString } from '@helpers/dateHelpers';
import { isNullOrEmptyString } from '@helpers/stringHelpers';
import { LocationType } from '@models/locations';
import { splitCode } from '@helpers/specialHandlingCodesHelpers';
import { createHawb } from '@store/airwaybills/houseAirwaybillsStore';
import AirlinesService from '@services/AirlinesService';
import { toast } from 'react-toastify';
import { localStorageService } from '@services/LocalStorageService';
import { AirWaybillEditor } from '@scenes/customerApplication/awb/components/airWaybillEditor';
import { useFormikContext } from 'formik';
import { nanoid } from '@reduxjs/toolkit';

type Props = {
    onClickBack: () => void;
    onSuccessSave: () => void;
    customerApplication?: GetCustomerApplicationDto;
    sale: GetSaleDto;
    customerRequest?: GetCustomerRequestDto;
    parentAirwaybillId?: string;
};

const AwbCreatePage = (props: Props) => {
    const { id: customerApplicationId } = useParams<{ id: string }>();
    const [initialData, setInitialData] = useState<AirwaybillDto>(null);
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const createHouseAirwaybill = async (model: AirwaybillDto) => {
        const request: CreateHouseAirwaybill = {
            houseAirwaybill: {
                ...model,
                id: null,
                parentAirwaybillId: props.parentAirwaybillId
            }
        };

        localStorageService.setItem('hawb', model);
        const promise = await dispatch(createHawb({ model: request }));
        const r = unwrap(promise);

        if (r.isError) {
            if (r.fields) {
                return getValidationErrors(r, true);
            }

            if (r.message) {
                toast.error(r.message);
            }
        } else {
            props.onSuccessSave();
            localStorageService.removeItem('hawb');
        }

        return [];
    };
    const createAirwaybill = async (model: AirwaybillDto) => {
        const request: CreateAirwaybill = {
            customerApplicationId: customerApplicationId,
            airwaybill: { ...model, id: null }
        };

        const promise = await dispatch(createAwb({ model: request }));

        const r = unwrap(promise);

        if (r.isError) {
            if (r.fields) {
                return getValidationErrors(r, true);
            }

            if (r.message) {
                toast.error(r.message);
            }
        } else {
            props.onSuccessSave();
        }

        return [];
    };

    const getOtherCharges = (sale: GetSaleDto): IOtherCharge[] => {
        if (sale == null) return [];

        if (sale.fees == null || sale.fees.length === 0) return [];

        return sale.fees.map(fee => ({ code: fee.code, amount: fee.cost }));
    };

    const getInitialData = (airline: AirlineDto): AirwaybillDto => {
        function getId() {
            return `new-awb-${nanoid()}`;
        }

        const minimalFilledAwb = {
            id: getId(),
            parentAirwaybillId: props.parentAirwaybillId || null,
            sender: null,
            recipient: null,
            airwaybillNumber: null,
            airportOfDestinationId: null
        } as AirwaybillDto;

        if (!props.customerApplication || !props.customerRequest || !props.sale) {
            return minimalFilledAwb;
        }

        const data: Partial<AirwaybillDto> = {
            ...minimalFilledAwb,
            airwaybillNumber: props.customerApplication.airwaybillNumber?.toUpperCase(),
            referenceNumber: props.customerApplication.referenceNumber?.toUpperCase(),
            currency: props.sale?.convertedCurrencyCode?.toUpperCase(),
            executionCityId:
                props.sale?.departurePoint?.type == LocationType.Airport
                    ? props.sale?.departurePoint?.parent?.id
                    : props.sale?.departurePoint?.id,
            isCargoGrossWeightUnitKg: true,
            cargoQuantity: props.customerRequest.totalPlaces?.toString(),
            requestedFlightAndDate: isoUtcDateToShortLocalString(props.customerRequest.dateStartPlan),
            executionDateStamp: new Date().toISOString(),
            cargoChargeableWeight: props.sale?.chargeableWeight?.toString(),
            cargoGrossWeight: props.customerRequest.totalWeight?.toString(),
            cargoRateCharge: props.sale?.tariffRate?.toString(),
            issuedBy: props.sale?.airlineName?.toUpperCase(),
            firstCarrierId: airline?.id,
            senderStateProvince: props.customerApplication.senderStateProvince?.toUpperCase(),
            senderCityId: props.customerApplication.senderCity?.id,
            recipientStateProvince: props.customerApplication.recipientStateProvince?.toUpperCase(),
            recipientCityId: props.customerApplication.recipientCity?.id,
            commodityItem: '1',
            declaredValueForCarriage: 'NVD',
            declaredValueForCustoms: 'NVC',
            amountOfInsurance: 'XXX',
            otherCharges: getOtherCharges(props.sale),
            isWeightOrValuationChargePrepaid: true,
            isOtherChargePrepaid: true,
            isDraft: false
        };

        if (props.sale?.departurePoint?.type == LocationType.Airport) {
            data.routingDepartureAirportId = props.sale?.departurePoint?.id;
            data.awbDepartureAirportId = props.sale?.departurePoint?.id;
        } else {
            data.routingDepartureCityId = props.sale?.departurePoint?.id;
        }

        if (props.sale?.transitPoint && props.sale?.transitPoint?.type == LocationType.Airport) {
            data.routingToFirstDestinationId = props.sale?.transitPoint?.id;
        }

        if (props.sale?.destinationPoint?.type == LocationType.Airport) {
            data.airportOfDestinationId = props.sale?.destinationPoint?.id;
        }

        let cargoDescription = `TTL VOL: ${props.customerRequest.totalVolume}`;

        cargoDescription += '\n' + props.customerRequest.specialHandlingCodes?.map((x) => splitCode(x.name).code).join(', ');

        if (!isNullOrEmptyString(props.customerApplication.cargoDangerClass)) {
            cargoDescription += ' - ' + props.customerApplication.cargoDangerClass?.toUpperCase();
        }

        if (!isNullOrEmptyString(props.customerApplication.cargoDetails)) {
            cargoDescription += ' - ' + props.customerApplication.cargoDetails?.toUpperCase();
        }

        if (!isNullOrEmptyString(props.customerApplication.specialConditions)) {
            cargoDescription += '\n' + props.customerApplication.specialConditions?.toUpperCase() + '; ';
        }

        data.cargoDescription = cargoDescription;

        if (props.customerRequest?.cargoDimensions?.length > 0) {
            const { cargoDimensions } = props.customerRequest;

            data.cargoDimensions = cargoDimensions.map(x => ({
                length: x.length,
                height: x.height,
                width: x.width,
                weight: x.weight,
                amount: x.amount
            }));

            // cargoDescription += '\nDIMS (CMS):';
            // cargoDescription += cargoDimensions.map((x) => `\n ${x.length}x${x.width}x${x.height}`).join();
        }

        data.harmonizedCommodityCodes = [];

        if (
            props.sale?.chargeableWeight != null &&
            props.sale?.chargeableWeight > 0 &&
            props.sale?.tariffRate != null &&
            props.sale?.tariffRate > 0
        ) {
            data.cargoTotalForRow = (props.sale?.chargeableWeight * props.sale?.tariffRate).toString();
        } else {
            data.cargoTotalForRow = 'AS AGREED';
        }

        return data as AirwaybillDto;
    };

    useEffect(() => {
        let isCurrent = true;

        if (props.parentAirwaybillId == null) {
            if (props.sale == null) {
                setInitialData(getInitialData(null));
                return;
            }

            const fetchAirline = async () => {
                if (props.sale.airlineCodeIata != null) {
                    const service = new AirlinesService();
                    const { data: airlines } = await service.findByCodeIata(props.sale.airlineCodeIata);
                    return airlines[0];
                }
            };

            fetchAirline()
                .then(airline => {
                    if (isCurrent) {
                        setInitialData(getInitialData(airline));
                    }
                });

            return () => {
                isCurrent = false;
            };
        }

        const savedData = localStorageService.getItem<AirwaybillDto>('hawb');
        if (savedData != null) {
            setInitialData(savedData);
            toast.success(t('awb.findSavedData'));
        } else {
            setInitialData(getInitialData(null));
        }
    }, [props.sale, props.parentAirwaybillId]);

    useEffect(() => {
        if (initialData) {
            dispatch(prepareNewAwb(initialData));
        }
    }, [initialData]);

    const CreateAwbButton = () => {
        const { t } = useTranslation();
        const context = useFormikContext();

        return <a
            className="btn btn-primary"
            onClick={(e) => {
                e.preventDefault();
                context.submitForm()
                    .then(() => {
                        const messages = document.querySelectorAll('.validationMessage');
                        if (messages.length > 0) {
                            messages[0].scrollIntoView({ block: 'center' });
                        }
                    });
            }}
        >
            {t('awb.create')}
        </a>;
    };

    const CancelAwbButton = () => {
        return <a
            className="link pl-4"
            onClick={(e) => {
                e.preventDefault();
                props.onClickBack();
                localStorageService.removeItem('hawb');
            }}
        >
            {t('awb.cancel')}
        </a>;
    };

    return <>
        <BackToViewPanel onClickBack={props.onClickBack} />

        <h2>{t(props.parentAirwaybillId ? 'awb.createNewHawb' : 'awb.createNewAwb')}</h2>

        {initialData && <AirWaybillEditor
            airwaybillId={initialData.id}
            airlineIataPrefix={props.sale?.airlineIataPrefix}
            onSubmit={props.parentAirwaybillId ? createHouseAirwaybill : createAirwaybill}>

            <div className="mt-4">
                <CreateAwbButton />
                <CancelAwbButton />
            </div>
        </AirWaybillEditor>}
    </>;
};

export default AwbCreatePage;