import { getValidationErrors } from '@helpers/responseHelpers';
import { HttpError, ValidationError } from '@models/shared';

export const submitFormAndShowErrorsAsync = async <TModel, TResult>(
	model: TModel,
	submitRequest: (model: TModel) => Promise<HttpError | TResult>,
	onErrorWithFields?: (msg) => void,
	removeFirstPropertyName = true
): Promise<HttpError | TResult> => {
	const serverResult = await submitRequest(model);
	const httpErrorResult = serverResult as HttpError;
	if (!httpErrorResult.isError) {
		return serverResult;
	}

	if (!httpErrorResult.fields) {
		return serverResult;
	}

	document.querySelectorAll('.validationMessage').forEach((x) => x.remove());

	const validationErrors: ValidationError[] = [];

	const serverValidationErrors = getValidationErrors(serverResult, removeFirstPropertyName);

	if (serverValidationErrors && serverValidationErrors.length > 0) {
		validationErrors.push(...serverValidationErrors);
	} else {
		onErrorWithFields(serverResult);
	}

	validationErrors.forEach((e) => {
		if (e.name.indexOf('.') > -1) {
			e.name = e.name
				.split('.')
				.map((x) => {
					return `${x[0].toLowerCase()}${x.slice(1, x.length)}`;
				})
				.join('.');
		}
	});

	validationErrors.forEach((f) => {
		let els: HTMLElement[] = Array.from(document.getElementsByName(f.name));
		if (els.length == 0) {
			els = Array.from(document.getElementsByClassName(`.name-${f.name}`)) as HTMLElement[];
		}
		if (els.length == 0) {
			return;
		}

		const el = els[0];

		el.classList.add('errorField');

		const msg = document.createElement('span');
		msg.classList.add('validationMessage');
		msg.dataset['name'] = f.name;
		msg.textContent = f.messages[0];

		el.parentNode.insertBefore(msg, el.nextSibling);

		const removeFn = () => {
			el.classList.remove('errorField');
			msg.remove();
		};

		el.addEventListener('keypress', removeFn);

		if (el.classList.contains('invisible')) {
			el.addEventListener('click', removeFn);
			el.addEventListener('change', removeFn);
		}
	});

	return serverResult;
};