import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import {AxiosResponse} from 'axios';
import {OrganizationDictionaryItem, OrganizationDto, UpdateOrganizationInfo} from '@models/organizations';
import {IPagingWrapper} from '@models/entityNavigation/IPagingWrapper';
import {StandardNavigation} from '@models/entityNavigation/StandardNavigation';
import {HttpError} from '@models/shared';
import {UpdateAccreditationList} from '@models/organizations/accreditationModels';
import {OrganizationSettingsDto} from '@models/settings/OrganizationSettings';
import {AttachedFile} from "@components/FileUploader";
import {serialize} from "object-to-formdata";
import {addFileToFormData} from "@helpers/filesHelpers";
import {InteractionType} from "@models/contacts/InteractionType";

export default class OrganizationsService {

	public dictionary = (model: StandardNavigation): Promise<AxiosResponse<IPagingWrapper<OrganizationDictionaryItem>>> => {
		return AuthorizedHttpClient.post<IPagingWrapper<OrganizationDictionaryItem>>(`api/organizations/dictionary`, model);
	}

	public updateAccreditationListFilledFlag = (organizationId: string, isFilled: boolean) => {
		return AuthorizedHttpClient.put<IPagingWrapper<OrganizationDictionaryItem>>(`api/organizations/${organizationId}/updateAccreditationListFilledFlag`, isFilled);
	}

	public fetchOne = (organizationId: string) => {
		return AuthorizedHttpClient.get<OrganizationDto | HttpError>(`api/organizations/${organizationId}`);
	}

	public update = (cmd: UpdateOrganizationInfo) => {
		return AuthorizedHttpClient.put<HttpError | null>(`api/organizations`, cmd);
	}

	public updateAccreditationList = (cmd: UpdateAccreditationList, attachedFiles?: AttachedFile[]) => {
		const formData = serialize({organization: cmd.organization});
		cmd.contacts
			.forEach((contact, index) => {
				Object.keys(contact)
					.forEach((key) => {
						if (key !== 'interactionTypes') {
							formData.append(`contacts[${index}].${key}`, contact[key]);
							return;
						}

						const interactionTypes = contact[key];
						interactionTypes.forEach((value, idx) => formData.append(`contacts[${index}].${key}[${idx}]`, `${value}`))
					});
			});

		const files = attachedFiles.filter(x => !x.isUploaded).map(x => x.blob);
		for (let i = 0; i < files.length; i ++) {
			formData.append(`files`, files[i]);
		}


		return AuthorizedHttpClient.put<HttpError | null>(`api/organizations/accreditationList`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	public fetchOrganizationSettings = () => {
		return AuthorizedHttpClient.get<OrganizationSettingsDto>(`api/organizations/settings`);
	};
}