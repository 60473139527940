import styles from "./index.module.scss";
import { AddressTokenDto, TokenType } from "@models/awbs/awbsModels";
import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

interface Props {
	onClick?: () => void;
	token: AddressTokenDto;
}

export const AddressParsedToken = ({onClick, token}: Props) => {
	const {t} = useTranslation();

	return <>
		<span
			onClick={(e) => e.ctrlKey && onClick()}
			className={clsx(styles.AddressToken, styles[TokenType[token.tokenType]])}
			title={t(`awb.tokenType.${TokenType[token.tokenType]}`)}>
			{token.value}
		</span>
	</>
}