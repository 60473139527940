import * as React from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router';
import s from './layout.module.scss';
import { ReactComponent as IconLogin } from '@material-design-icons/svg/round/login.svg';
import { ReactComponent as IconContactUs } from '@material-design-icons/svg/round/mail.svg';
import { useTranslation } from 'react-i18next';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { isMainTenant, logo } from '@helpers/tenantsHelper';
import useAccreditationListRedirection from '@helpers/routingHelpers';
import cn from 'classnames';
import { Button } from '@root/components';

type LayoutRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path?: string | string[];
  exact?: boolean;
};

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = (props: LayoutProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = (): void => setIsOpen(!isOpen);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <header className='header'>
        <Container fluid={true} className='welcomeContainer'>
          <Navbar light expand='md'>
            <NavbarBrand href='/' className={s.siteLogo}>
              <img src={logo} alt='Logo' />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className={cn(s.loginBtn, 'ml-auto')} navbar>
                {!isMainTenant &&
                  <>
                    <NavItem>
                      <Button icon={<IconLogin fill='currentColor' />} type='fill' variant='primary'
                              onClick={() => history.push(LOGIN_REDIRECT_URL)}>
                        <span>{t('welcomePage.login')}</span>
                      </Button>
                    </NavItem>
                    <NavItem>
                      <Button icon={<IconContactUs fill='currentColor' />} type='fill' variant='white'
                              onClick={() => history.push('/contact_us')}>
                        <span>{t('welcomePage.contactUs')}</span>
                      </Button>
                    </NavItem>
                  </>
                }
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </header>
      {props.children}
    </>
  );
};

const WelcomeLayout = ({ component: Component, ...rest }: LayoutRouteProps) => {
  useAccreditationListRedirection();

  const renderComponentFunc = (props) => (
    <Layout>
      <Component {...props} />
    </Layout>
  );
  return <Route {...rest} component={renderComponentFunc} />;
};

export default WelcomeLayout;