import { BCCargoRouteDto } from '@models/customerApplications/customerApplicationModels';
import { TrackEventDto } from '@models/awbs/awbsModels';
import { SendingStatus } from '@root/enum';

export const getSendingStatuses = (collection: BCCargoRouteDto[]): SendingStatus[] => {
    return collection?.map(r => r.sendingStatusId);
}

export const getEventCodes = (collection: TrackEventDto[]): string[] => {
    return collection?.map(e => e.code);
}

export const hasEventCodes = (collection: string[], codes: string[]): boolean => {
    return collection?.filter(code => codes?.indexOf(code) >= 0).length > 0;
}

export const hasSendingStatuses = (collection: SendingStatus[], codes: SendingStatus[]): boolean => {
    return collection?.filter(code => codes?.indexOf(code) >= 0).length > 0;
}