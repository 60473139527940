import React from "react";
import { connect } from "react-redux";
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = (): JSX.Element => {
    return (
        <>
            <section className={styles.ppHero}>
                <div className={styles.container}>
                    <div className="content">
                        <div className={styles.ppInner}>
                            <div className={styles.upperSubtitle}>legal</div>
                            <h1 className={`${styles.titleColor} ${styles.ppTitle}`}>Privacy рolicy</h1>
                            <p className={styles.description}>This document is Cargobooking365com’s <i>long-form</i> privacy policy</p>

                            <p className={`${styles.smallDescr}`}>Last updated: 14th June 2020</p>
                            <p className={`${styles.smallDescr}`}>Version: v1.1.0</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles.ppInner}>
                <p className={styles.description}>
                    CARGOBOOKING365COM LIMITED and its affiliates (hereinafter referred to as <strong>“CB365”</strong>) respect your
                    privacy and value the personal information you provide when visiting this website or using CB365’s services.
                    This Privacy Policy is designed in a Q&amp;A format, to guide you through the way CB365 collects and processes
                    your personal information for its own purposes and on behalf of its affiliates.
                </p>
                <hr />
                <h2>Why does CB365 collect my information?</h2>
                <p>Through this website, CB365 collects personal information about you for a variety of reasons, namely:</p>

                <ul>
                    <li>to enable you to access your online account with CB365;</li>
                    <li>to facilitate the performance of your agreement with CB365, by:</li>
                        <ul>
                            <li>registering you as a member;</li>
                            <li>processing your information;</li>
                            <li>processing orders;</li>
                            <li>and fulfilling payment processes.</li>
                        </ul>
                        <li>to meet our legal obligations, such as:</li>
                        <ul>
                            <li>for accounting and tax purposes; and</li>
                            <li>to respond to requests for information by competent public bodies and judicial authorities.</li>
                        </ul>
                </ul>
                <p>
                    CB365 will need to collect certain information, whether this is done from the use of this website,
                    or any form of communication between you and CB365 in order to:
                </p>
                <ul>
                    <li>provide our services and to protect the integrity and security of our services;</li>
                    <li>to improve the user experience of our website by making it more accessible and user friendly; and</li>
                    <li>to enforce our Terms of Use and our rights.</li>
                </ul>

                <h2>What information might be collected by this website?</h2>
                <p>CB365 may collect, use, store and transfer different kinds of information about you such as:</p>
                <ul>
                    <li>Technical data, such as your IP address, your login data, your use of the website and the options you chose to select, browser type and version, time zone and location, browser plug-in types and versions, operating system and platform amongst other technology on the devices you use in order to access this website.</li>
                    <li>Personal and identifiable data, such as your name, title, username, gender, date of birth, your contact details (including email address and telephone number).</li>
                    <li>Data related to transactions such as payment details and details of your orders. </li>
                </ul>
                <p>
                    Some of the content or functionality of the website is being provided by third parties, such as Facebook,
                    Google and Twitter plug-ins. These third parties receive some information about your use of our website,
                    including through the use of cookies and similar technologies (see section on cookies below).
                    We do not control these third-party websites and therefore we encourage you to consult the websites
                    of these third parties to understand how they use your information.
                </p>

                <h2>How will CB365 collect and store this information and for how long?</h2>
                <p>CB365 uses different methods to collect data from and about you, namely through:</p>
                <ul>
                    <li>Direct interactions, whereby you provide information by filling in your details to create an account and/or process an order, by getting in touch with us via email, phone, post or any other form of communication whether it is related to the services we provide or competitions/ promotions. </li>
                    <li>Automated interactions, whereby through the use of this website, we may automatically collect through the use of cookies and other similar technology, technical information about your device and your browsing.</li>
                    <li>Third parties, whereby we may receive data from various third parties.</li>
                </ul>
                <p>
                    CB365 will only store information about you which we require to store for the purpose of
                    providing services, accounting purposes and/or by law.
                </p>
                <p>
                    CB365 stores your information in a secure environment protected by a combination of physical and
                    technical measures. There is no general public access to this information.
                </p>

                <h2>With whom does CB365 share my personal information?</h2>
                <p>Relevant personal information will be shared with:</p>
                <ul>
                    <li>its affiliated or associated companies, and licensees of any of these companies;</li>
                    <li>service providers working for CB365, for example, companies that help us develop our website and keep it secure;</li>
                    <li>other parties as required by law, or to comply with a subpoena, legal proceedings, or similar legal or judicial process or arbitration, including disclosure to authorized third party auditors or governmental authorities, or to investigate or prevent fraud;</li>
                    <li>and other parties in the context of a corporate transaction, such as a merger, acquisition or bankruptcy procedure.</li>
                </ul>
                <h2>Where will my personal information be stored? </h2>
                <p>
                    We manage certain website functions from Cyprus, however due to CB365’s services being offered worldwide,
                    processing of information may occur by our affiliates, outside Europe. At CB365  we value your privacy and
                    the information you share with us and we take the necessary steps to protect your information
                    when it is transferred to regions within and outside of Europe.
                </p>
                <h2>What are cookies?</h2>
                <p>
                    A cookie is a text-only string of information that a website transfers to the cookie file of the browser
                    on your computer&apos;s hard disk so that the website can remember who you are. Cookies can help a website to
                    arrange content to match your preferred interests more quickly. Some cookies may allow us to recreate and
                    replay user sessions on our website Most major websites use cookies.
                </p>
                <p>
                    A cookie will typically contain the name of the domain from which the cookie has originated; the &quot;lifetime&quot;
                    of the cookie; and a value, usually a randomly generated unique number.
                </p>
                <p>Filuet uses two types of cookies on this website: </p>
                <ul>
                    <li>Session Cookies, which are temporary cookies that remain in the cookie file of your browser until you leave the site.</li>
                    <li>Persistent Cookies, which remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie).</li>
                </ul>

                <h2>How are cookies used on this website and what information is collected? </h2>
                <h3>Session Cookies</h3>
                <p>Session cookies are used for the following purposes: </p>
                <ul>
                    <li>to allow you to carry information across pages of our website and avoid having to re-enter information; and </li>
                    <li>within registration to allow you to access stored information. </li>
                </ul>
                <h3>Persistent Cookies</h3>
                <p>Persistent are used for the following purposes:</p>
                <ul>
                    <li>to help us recognise you as a unique visitor (using a number) when you return to our website;</li>
                    <li>to allow us to tailor content or advertisements to match your preferred interests or to avoid showing you the same adverts repeatedly;</li>
                    <li>to identify and resolve user experience issues and improve website operations; and</li>
                    <li>to resolve member inquiries and to assist in observing compliance with Herbalife’s member rules.</li>
                </ul>
                <h3>Third Party Cookies </h3>
                <p>Third parties may also serve cookies via the website. Third Party cookies are used for the following purposes: </p>
                <ul>
                    <li>to tailor content to your preferences;</li>
                    <li>to count the number of users of our website; and </li>
                    <li>to provide security within shopping baskets or transactions. </li>
                </ul>
                <p>We have no access to third party cookies.</p>
                <h3>Web Beacons </h3>
                <p>
                    Our website may contain electronic images known as Web Beacons (sometimes known as clear gifs) that allow
                    us to count users who have visited the website. Web Beacons collect only limited information which includes
                    a cookie number; time and date of a page view; and a description of the page on which the Web Beacon resides.
                    We may also carry Web Beacons placed by third party advertisers. These Beacons are only used to track the
                    effectiveness of a particular campaign.
                </p>

                <h2>How do I disable and enable cookies?</h2>
                <p>
                    You have the ability to accept or decline cookies by modifying the settings in your browser. However,
                    you may not be able to use all the interactive features of our website if cookies are disabled.
                    You can set your browser to refuse all or some browser cookies, or to alert you when websites have cookies.
                </p>
                <p>Installed cookies may easily be deleted from the cookie folder of your browser.</p>

                <h2>What are my rights with respect to my personal information?</h2>
                <p>
                    Depending on the applicable law, you may have various rights in respect to your personal information,
                    such as a right of access, rectification, restriction of or, objection to processing of your personal
                    data (including processing for direct marketing), and portability to another controller and erasure.
                    If you wish to review and update your personal information or deactivate your account,
                    please contact us on <a href="mailto:info@cargobooking365.com" className={styles.inlineLink}>[email]</a>.
                </p>
                <p>Please note that these rights are subject to limitations set out in law.</p>
                <p>
                    If you have additional questions about this policy and our practices or if you have complaints
                    about CB365’s use of your personal information, please contact us at:
                </p>
                <address>
                    <p>CARGOBOOKING365COM LIMITED</p>
                    <p>1 Siafi str. Porto Bello, office 105, 3042, Limassol, Cyprus</p>
                    <a href="mailto:info@cargobooking365.com" className={styles.inlineLink}>[email]</a>
                </address>
                <p>
                    For users and members in the European Union, if you have concerns about CB365’s collection and use of your personal
                    information that CB365 cannot solve to your satisfaction, you have the right to file a complaint with the
                    competent Supervisory Authority in your jurisdiction.
                </p>

                <h2>What about Internet &amp; Website Security?</h2>
                <p>
                    The Internet is not a secure system, and you should always be cautious about the information you disclose online.
                    Personal Information collected by CB365 is stored in secure operating environments that are not available to the public.
                    Where necessary, the personal information is encrypted before you conduct your transaction,
                    using appropriate secure technology.
                </p>
                <h2>Can CB365 change this website policy?</h2>
                <p>
                    Privacy laws, guidelines and case law change continuously. CB365 therefore reserves the right to amend this policy
                    from time to time. We recommend that you visit our website periodically to be aware of the latest version of our policy.
                </p>
            </div>
        </>
    );
};

export default connect()(PrivacyPolicy);
