/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import NotificationsService from '@services/NotificationsService';
import {
	CreateNotificationSettings,
	NotificationSettingsEntryDto,
	UpdateNotificationSettings,
} from '@models/admin/notificationsModels';

export type State = {
	items: NotificationSettingsEntryDto[];
	isFetching: boolean;
	isEditing: boolean;
};

export const fetchAllNotifications = createAppThunk('fetchAllNotifications', async () => {
	const { data } = await new NotificationsService().fetchAll();
	return data;
});

export const createNotificationsEntry = createAppThunk(
	'createNotificationsEntry',
	async (arg: CreateNotificationSettings) => {
		const { data: id } = await new NotificationsService().create(arg);
		return id;
	}
);

export const updateNotificationsEntry = createAppThunk(
	'updateNotificationsEntry',
	async (arg: UpdateNotificationSettings) => {
		const { data } = await new NotificationsService().update(arg);
		return data;
	}
);

export const deleteNotificationsEntry = createAppThunk('deleteNotificationsEntry', async (arg: { id: string }) => {
	await new NotificationsService().delete(arg.id);
	return arg;
});

const slice = createSlice({
	name: 'notificationsSettings',
	initialState: {
		isFetching: false,
		isEditing: false,
		items: [],
	} as State,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchAllNotifications.fulfilled, (state, action) => {
			state.items = action.payload;
			state.isFetching = false;
		});
		builder.addCase(fetchAllNotifications.rejected, (state, action) => {
			state.isFetching = false;
		});
		builder.addCase(fetchAllNotifications.pending, (state, action) => {
			state.isFetching = true;
		});

		builder.addCase(createNotificationsEntry.fulfilled, (state, action) => {
			state.items.push({ ...action.meta.arg, id: action.payload as string });
			state.isEditing = false;
		});
		builder.addCase(createNotificationsEntry.rejected, (state, action) => {
			state.isEditing = false;
		});
		builder.addCase(createNotificationsEntry.pending, (state, action) => {
			state.isEditing = true;
		});

		builder.addCase(updateNotificationsEntry.fulfilled, (state, action) => {
			state.isEditing = false;

			const u = { ...action.meta.arg };

			const m = state.items.find((x) => x.id == action.meta.arg.id);

			m.email = u.email;
			m.eventGroupTypes = u.eventGroupTypes;
			m.eventTypeName = u.eventTypeName;
			m.eventTypeValues = u.eventTypeValues;
			m.languageTypes = u.languageTypes;
		});
		builder.addCase(updateNotificationsEntry.pending, (state, action) => {
			state.isEditing = true;
		});
		builder.addCase(updateNotificationsEntry.rejected, (state, action) => {
			state.isEditing = false;
		});

		builder.addCase(deleteNotificationsEntry.fulfilled, (state, action) => {
			state.isEditing = false;
			state.items = state.items.filter((x) => x.id != action.meta.arg.id);
		});
		builder.addCase(deleteNotificationsEntry.rejected, (state, action) => {
			state.isEditing = false;
		});
		builder.addCase(deleteNotificationsEntry.pending, (state, action) => {
			state.isEditing = true;
		});
	},
});

export const { reducer } = slice;
export const { actions } = slice;