import { createAppThunk } from '@helpers/reduxHelpers';
import { createSlice } from '@reduxjs/toolkit';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { GetNatureOfCargoDto } from '@models/cargoes';
import CargoesService from '@services/CargoesService';

export type State = {
	isFetching: boolean;
	natureOfCargoList: GetNatureOfCargoDto[];
};

export const fetchNatureOfCargoList = createAppThunk('natureOfCargo/fetchNatureOfCargoList', async () => {
	const nav = new StandardNavigation();
	nav.pagingFilter.pageSize = 999; // Load all codes.
	const { data } = await new CargoesService().getNatureOfCargoList(nav);

	const items = [...data.items];
	const genItem = items.find((x) => x.name.indexOf('GENERAL') > -1);
	if (genItem) {
		return [genItem, ...items.filter(x => x != genItem)];
	}

	return data.items;
});

const slice = createSlice({
	name: 'natureOfCargo',
	initialState: {
		isFetching: false,
		natureOfCargoList: [],
	} as State,
	reducers: {},
	extraReducers: (builder) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchNatureOfCargoList.pending, (state, action) => {
			state.isFetching = true;
		});
		builder.addCase(fetchNatureOfCargoList.fulfilled, (state, action) => {
			state.isFetching = false;
			state.natureOfCargoList = action.payload;
		});
	},
});

export const { reducer } = slice;
export const { actions } = slice;
