/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Nav,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Col,
  NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import MoonIcon from '../MoonIcon';
import s from './NavMenu.module.scss';
import { useHistory } from 'react-router';
import { LOGIN_REDIRECT_URL } from '@config/ApiAuthorizationConstants';
import { useTranslation } from 'react-i18next';
import SessionManager from '../../SessionManager';
import AccountService from '@services/AccountService';
import { useAppDispatch, useAppSelector } from '@store/index';
import { changeLanguage } from '@helpers/localizationHelpers';
import { useEffect, useMemo, useState } from 'react';
import { fetchLanguages } from '@store/languagesStore';
import { actionCreators, fetchCurrencies } from '@store/currencyStore';
import { CurrencyDto } from '@models/currency';
import i18n from '@i18n';

type MenuProps = {
  style?: any;
};

export default function NavMenu(props: MenuProps) {
  const [isOpen, toggle] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { languages } = useAppSelector(x => x.languages);
  const { availableCurrencies, isFetching: isCurrenciesFetching } = useAppSelector(x => x.currencies);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (languages?.length > 0) {
      return;
    }
    dispatch(fetchLanguages());
  }, [languages]);

  useEffect(() => {
    if (availableCurrencies?.length > 0 || isCurrenciesFetching) {
      return;
    }
    dispatch(fetchCurrencies());
  }, [availableCurrencies, isCurrenciesFetching]);

  const onChangeLanguage = async (iso: string) => {
    await changeLanguage(iso);
    toggle(false);

    // TODO reload required to refresh Airport names in Sale details
    window.location.reload();
  };

  const onChangeCurrency = async (currency: CurrencyDto) => {
    await dispatch((actionCreators.updateSelectedCurrency(currency.code)));
    window.location.reload();
  };

  const userInfo = useMemo(() => {
    const user = SessionManager.user;

    if (!user) {
      return <Col className={s.userInfo} />;
    }

    return (
      <div className={s.userInfo}>
        <div>{user.userName}</div>
        <div className={s.userIcon}>
          <span>{user.initials}</span>
        </div>
      </div>
    );
  }, [SessionManager.user]);

  const subMenu = useMemo(() => {
    return (
      <Nav
        vertical
        className={s['sub-menu']}
        onMouseLeave={() => {
          toggle(false);
        }}
        {...(props as any)}
      >
        <NavItem className={`${s['nav-item']} mt-2`}>
          <NavLink to='/account'>
            <MoonIcon icon='icon-settings' className='moon-1x mr-2' />
            {t('accountSettings')}
          </NavLink>
        </NavItem>
        <hr />
        <NavItem className={`${s['nav-item']} mt-2`}>
          <UncontrolledDropdown className={s['btn-lang']} direction='left'>
            <DropdownToggle caret>
              <MoonIcon icon='icon-language' className='moon-1x mr-2' />
              {t('navMenu.changeLang')}
            </DropdownToggle>
            <DropdownMenu>
              {languages.map((language, i) => (
                <DropdownItem
                  key={i}
                  onClick={async () => {
                    await onChangeLanguage(language.iso);
                    toggle(false);
                  }}
                >
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavItem>
        <hr />
        <NavItem className={`${s['nav-item']} mt-2`}>
          <UncontrolledDropdown className={s['btn-lang']} direction='left'>
            <DropdownToggle caret>
              <MoonIcon icon='icon-globe' className='moon-1x mr-2' />
              {t('navMenu.changeCurrency')}
            </DropdownToggle>
            <DropdownMenu>
              {availableCurrencies.map((currency, i) => (
                <DropdownItem
                  key={i}
                  onClick={async () => {
                    await onChangeCurrency(currency);
                  }}
                >
                  {currency.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavItem>
        <hr />
        <NavItem className={`${s['nav-item']} mt-2`} onClick={() => onClickLogout()}>
          <MoonIcon icon='icon-log-out' className='moon-1x mr-2' />
          {t('navMenu.signOut')}
        </NavItem>
      </Nav>);
  }, [props, languages, availableCurrencies, i18n.language, userInfo, isOpen]);

  const onClickLogout = () => {
    try {
      new AccountService().logout().then(() => {
        SessionManager.clearUser();
        history.push(LOGIN_REDIRECT_URL);
      });
    } catch (e) {
      SessionManager.clearUser();
      history.push(LOGIN_REDIRECT_URL);
    }
  };

  return (
    <div className={s.container}>
      {userInfo}
      <div className={s.menu}>
        <a onClick={() => toggle(true)} className={s.pointer}>
          <MoonIcon icon='icon-menu' />
        </a>
        {isOpen && subMenu}
      </div>
    </div>
  );
}