import styles from './index.module.scss';
import React, { ReactElement } from 'react';

interface TextInputProps {
	children?: React.ReactElement;
	isReadOnly?: boolean;
	label: string;
	name: string;
	onChange?: (value: string) => void;
	onFocus?: () => void;
	placeholder?: string;
	type?: 'text' | 'date' | 'number' | 'email' | 'tel';
	value: string;
}

const TextInput = (
	{
		children = null,
		isReadOnly = false,
		label,
		name,
		onChange = (): void => void 0,
		onFocus = (): void => void 0,
		placeholder = '',
		value,
		type = 'text'
	}: TextInputProps): ReactElement => {
	return <label className={styles.label}>
		<span className={styles.label}>{label}</span>
		<input type={type}
			   readOnly={isReadOnly}
			   id={name}
			   name={name}
			   placeholder={placeholder}
			   value={value || ''}
			   onFocus={(): void => onFocus()}
			   onChange={(event): void => onChange(event.currentTarget.value)}
		/>
		{children}
	</label>;

};

export default TextInput;