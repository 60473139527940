import { AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

export async function getPromise<TReturned, TArg>(dispatchedFn: AsyncThunkAction<TReturned, TArg, {}>) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return ((await dispatchedFn) as any).payload;
}

export function createAppThunk<TReturned, TArg = void>(name: string, fn: (arg?: TArg) => Promise<TReturned>) {
	return createAsyncThunk(name, (arg: TArg, thunkAPI) => {
		return fn(arg)
			.then((r) => r)
			.catch((e) => thunkAPI.rejectWithValue({ ...e }));
	});
}

// export type ActionTypeWithPayload<T>  = {
//     payload: T;
// };

declare type ActionTypesWithOptionalErrorAction =
	| {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			error: any;
	  }
	| {
			error?: never;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			payload: any;
	  };

declare type PayloadForActionTypesExcludingErrorActions<T> = T extends {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
}
	? never
	: T extends {
			payload: infer P;
	  }
	? P
	: never;

export function unwrap<R extends ActionTypesWithOptionalErrorAction>(
	returned: R
): PayloadForActionTypesExcludingErrorActions<R> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (returned as any).payload;
}