import { createSlice } from '@reduxjs/toolkit';
import { Operation, TrackGeneralDto } from '@models/awbs/awbsModels';
import { createAppThunk } from '@helpers/reduxHelpers';
import AirwaybillsService from '@services/AirwaybillsService';
import AirportsService from '@services/AirportsService';
import { AirportDictionaryItemDto } from '@models/airports/AirportDictionaryItemDto';

interface IInitState {
    traceData: Operation<TrackGeneralDto>;
    loading: boolean;
    traceLoading: boolean;
    finished: boolean;
    locations: AirportDictionaryItemDto[];
}

const initialState: IInitState = {
    traceData: null,
    loading: false,
    traceLoading: false,
    locations: null,
    finished: false
};

export const getTrackAndTraceInfoAsync = createAppThunk(
    'tracking/getTrackAndTraceInfoAsync',
    async (arg: { number: string, force: boolean }) => {
        return await new AirwaybillsService().getTrackAndTraceInfoAsync(arg.number, arg.force);
    });

export const findByCodeIata = createAppThunk(
    'tracking/findByCodeIata',
    async (names: string[]) => {
        const data = await new AirportsService().findByCodeIata(names);
        return data.data.items;
    },
);

const slice = createSlice({
    name: 'tracking',
    initialState: initialState,
    reducers: {
        resetTrack: (state) => {
          state.traceData = null;
          state.traceLoading = false;
          state.locations = null;
          state.finished = false;
          state.loading = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(getTrackAndTraceInfoAsync.pending,
            (state) => {
                state.traceData = null;
                state.traceLoading = true;
            },
        );

        builder.addCase(getTrackAndTraceInfoAsync.fulfilled,
            (state, action) => {
                state.traceData = action.payload;
                state.traceLoading = false;
                state.finished = true;
            },
        );

        builder.addCase(getTrackAndTraceInfoAsync.rejected,
            (state) => {
                state.traceData = null;
                state.traceLoading = false;
                state.finished = true;
            },
        );

        builder.addCase(findByCodeIata.pending, (state) => {
                state.loading = true;
            },
        );

        builder.addCase(findByCodeIata.fulfilled, (state, action) => {
                state.locations = action.payload;
                state.loading = false;
            },
        );

        builder.addCase(findByCodeIata.rejected, (state) => {
                state.locations = [];
                state.loading = false;
            },
        );
    },
});

export const { resetTrack } = slice.actions;
export const reducer = slice.reducer;