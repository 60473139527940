import {useDispatch, useSelector} from 'react-redux';
import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import initializeStore from '@store/initializeStore';
import getRootReducer from '@store/getRootReducer';

export type ApplicationState = ReturnType<ReturnType<typeof getRootReducer>>;

// this type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState
    ): void;
}

export type AppThunk = ThunkAction<void, ApplicationState, undefined, Action<string>>;

export type AppDispatch = ReturnType<typeof initializeStore>['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();

export function useAppSelector<TState> (storeSelector: ((appState: ApplicationState) => TState)) {
   return useSelector(storeSelector);
}

export default initializeStore;