import { createAppThunk } from '@helpers/reduxHelpers';
import DemoBookingService from '@services/DemoBookingService';
import { createSlice } from '@reduxjs/toolkit';
import { AvailableSlotsDto } from '@models/demoBooking/availableSlotsDto';

export type State = {
	timeslotMap: Record<string, string[]>;
	timezoneMap: Record<string, string>;
	isFetched: boolean;
	isFetching: boolean;
}

export type GetTimeslotArgs = { serviceId: string; staffId: string; date: Date };

export const getTimeslots = createAppThunk<AvailableSlotsDto, GetTimeslotArgs>('demo-booking-services/timeslots',
	async ({ serviceId, staffId, date }) => {
		const { data } = await new DemoBookingService().getAvailableTimeslots(serviceId, staffId, date);
		return data;
	});

export const getTimeslotKey = (serviceId: string, staffId: string, date: Date): string => `${serviceId}|${staffId}|${date}`;

const slice = createSlice({
	name: 'demo-booking-services-timeslot',
	initialState: {
		timeslotMap: {},
		timezoneMap: {},
		isFetched: false,
		isFetching: false
	} as State,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getTimeslots.pending, (state, action) => {
			const args = action.meta.arg;
			const timeslotKey = getTimeslotKey(args.serviceId, args.staffId, args.date);
			state.timeslotMap[timeslotKey] = [];
			state.timezoneMap[timeslotKey] = "";
			state.isFetched = false;
			state.isFetching = true;
		});

		builder.addCase(getTimeslots.fulfilled, (state, action) => {
			const args = action.meta.arg;
			const timeslotKey = getTimeslotKey(args.serviceId, args.staffId, args.date);
			state.timeslotMap[timeslotKey] = action.payload.availableSlots;
			state.timezoneMap[timeslotKey] = action.payload.timeZone;
			state.isFetched = true;
			state.isFetching = false;
		});

		builder.addCase(getTimeslots.rejected, (state, action) => {
			const args = action.meta.arg;
			const timeslotKey = getTimeslotKey(args.serviceId, args.staffId, args.date);
			state.timeslotMap[timeslotKey] = [];
			state.timezoneMap[timeslotKey] = "";
			state.isFetched = false;
			state.isFetching = false;
		});

	}
});

export const { reducer } = slice;
export const { actions } = slice;