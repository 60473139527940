import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'reactstrap';
import styles from '@scenes/welcome/WelcomePage.module.scss';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LayoutProps = {
	children?: React.ReactNode;
};

const NotFound = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<>
			<section>
				<Container fluid={true} className={styles.welcomeContainer}>
					<Row>
						<Col>
							<div className={styles.mainCell}>
								<h3 style={{ display: "none" }}>{t('welcomePage.mainHeader')}</h3>
								<h1>{t('pageNotFound')}</h1>

							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<footer>
				<Container fluid={true} className={styles.welcomeContainer}>
					<Row
						className={`justify-content-between align-items-center ${styles.footerRow}`}
					>
						<p>{t('welcomePage.copyright')}</p>
						<ul>
							<li>
								<Link to="/privacy_policy">
									{t('welcomePage.policy')}
								</Link>
							</li>
							<li>
								<Link to="/terms_of_use">
									{t('welcomePage.terms')}
								</Link>
							</li>
						</ul>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default NotFound;