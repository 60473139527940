import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';
import clsx from "clsx";

interface PopupProps {
	className?: string;
	setShow: (show: boolean) => void;
	show: boolean;
}

const Popup: React.FC<PropsWithChildren<PopupProps>> = (
	{
		children,
		className,
		setShow = (): void => void 0,
		show = false,
	}) => {


	return <>
		{show && <div className={styles.block} onClick={(): void => setShow(false)} />}
		<div className={clsx( styles.popup, show ? styles.show : '', className)}>
			{children}
		</div>
	</>;
};

export default Popup;