export function downloadBlob(blob: Blob, fileName: string) {
	const anchor = document.createElement('a');
	const objectUrl = window.URL.createObjectURL(blob);
	anchor.href = objectUrl;
	anchor.download = fileName;
	anchor.click();
	anchor.remove();
	window.URL.revokeObjectURL(objectUrl);
}

export function addFileToFormData(formData: FormData, fieldName: string, data: File[] | File) {

	if (data == null) {
		return;
	}

	if (Array.isArray(data)) {
		data.forEach((x) => formData.append(fieldName, x));
		return;
	}

	formData.append(fieldName, data);
}