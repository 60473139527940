import s from './dataRow.module.scss';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { isNullOrEmptyString } from '@helpers/stringHelpers';
import { toast } from 'react-toastify';
import TYPE = toast.TYPE;

interface IDataRow {
  name: string;
  value?: unknown;
}

const DataRow: React.FC<PropsWithChildren<IDataRow>> = ({ name, value, children }) => {
  const { t } = useTranslation();

  const valueType = () => {
    switch (typeof (value)) {
      case 'boolean':
        return (
          <>
            {t(`customerApplication.form.${value ? 'yes' : 'no'}`)}
          </>
        );
      case 'string':
        return (
          <>
            {value || '-'}
          </>
        );
      case 'object':
        return (
          <>{!value && '-'}</>
        );
    }
  };

  return (
    <div className={s.row}>
      <div className={s.rowParam}>
        {name}
      </div>
      <div className={s.rowValue}>
        {children ? (children) : valueType()}
      </div>
    </div>
  );
};

export default DataRow;