import { CurrencyDto } from '@models/currency';
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { localStorageService } from '@services/LocalStorageService';
import { createAppThunk } from '@helpers/reduxHelpers';
import CurrenciesService from '@services/CurrenciesService';

export type State = {
	selectedCode: string;
	availableCurrencies: CurrencyDto[];
	isFetching: boolean;
};

const selectedCodeKey = 'cb365_currency';
export const defaultCurrencyCode = 'RUB';

export const fetchCurrencies = createAppThunk(
	'currencies/fetchCurrencies',
	async () => {
		const { data } = await new CurrenciesService().fetch();
		return data;
	}
);

const slice = createSlice({
	name: 'currencies',
	initialState: {
		selectedCode: localStorageService.getItem<string>(selectedCodeKey) || defaultCurrencyCode,
		availableCurrencies: [],
		isFetching: false
	} as State,
	reducers: {
		setPreferred: (state, action: PayloadAction<string>) => {
			state.selectedCode = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(
			fetchCurrencies.fulfilled,
			(state, action) => {
				state.isFetching = false;
				state.availableCurrencies = action.payload;
			}
		);
		builder.addCase(
			fetchCurrencies.rejected,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			(state, action) => {
				state.isFetching = false;
			}
		);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		builder.addCase(fetchCurrencies.pending, (state, action) => {
			state.isFetching = true;
		});
	}
});

export const { reducer } = slice;

export const actionCreators = {
	updateSelectedCurrency: (code: string) => async (dispatch: Dispatch) => {
		localStorageService.setItem(selectedCodeKey, code);
		dispatch(slice.actions.setPreferred(code));
	},
};