import React, { useMemo } from 'react';
import i18n from '@i18n';

import DatePicker from 'reactstrap-date-picker';
import { useField } from "formik";
import { localDateToUtcDate } from "@helpers/dateHelpers";

const KnownLocales = {
	ru: {
		placeholder: 'ДД.ММ.ГГГГ',
		dateFormat: 'DD.MM.YYYY',
		dayLabels: [ 'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб' ],
		monthLabels: [
			'Январь',
			'Февраль',
			'Март',
			'Апрель',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь',
		],
	},
	en: {
		placeholder: 'MM/DD/YYYY',
		format: 'MM/DD/YYYY',
		dayLabels: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
		monthLabels: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
	},
};

type Props = {
	name: string;
	autoComplete?: string;
	minDate?: string;
	maxDate?: string;
	showClearButton?: boolean;
	disabled?: boolean;
};

const LocalizableDatePicker = (props: Props): JSX.Element => {
	const [ field, meta, helpers ] = useField(props.name);

	const {placeholder, monthLabels, dayLabels, dateFormat} = useMemo(() => {
		return {
			placeholder: KnownLocales[i18n.language].placeholder,
			monthLabels: KnownLocales[i18n.language].monthLabels,
			dayLabels: KnownLocales[i18n.language].dayLabels,
			dateFormat: KnownLocales[i18n.language].dateFormat,
		};
	}, [ i18n.language ]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const {minDate, maxDate, showClearButton, autoComplete} = props;

	return (
		<>
			<DatePicker
				disabled={props.disabled}
				id={props.name.replace('.', '_')}
				autoComplete={autoComplete || 'off'}
				showClearButton={showClearButton}
				placeholder={placeholder}
				dayLabels={dayLabels}
				monthLabels={monthLabels}
				value={field.value}
				minDate={minDate}
				maxDate={maxDate}
				onChange={(value: string): void => {
					const date = value != null ? localDateToUtcDate(new Date(value)).toISOString() : null;
					helpers.setValue(date);
				}}
				dateFormat={dateFormat}
			/>
			{meta?.error && <div><span className="validationMessage">{meta.error}</span></div>}
		</>
	);
};

export default LocalizableDatePicker;