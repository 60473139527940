import LocationSelect from "@components/select/LocationSelect";
import LocationsService from "@services/LocationsService";
import { LocationDto, LocationType } from "@models/locations";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

interface Props {
	placeholder?: string;
	disabled?: boolean;
	locationFieldName: string;
	subLocationFieldName?: string;
	locationType?: LocationType;
	subLocationType?: LocationType;
}

export const LocationSelectField = (props: Props) => {
	const [ locationType ] = useState(props.locationType == null ? LocationType.City : props.locationType);
	const [ subLocationType ] = useState(props.subLocationType == null ? LocationType.Airport :  props.subLocationType);
	const [ field, meta, helpers ] = useField(props.locationFieldName);
	const {errors, values, setFieldValue} = useFormikContext();
    const [locationId, setLocationId] = useState('');
	const [ location, setLocation ] = useState<LocationDto>(null);
	const [ subLocationId, setSubLocationId ] = useState<string>(null);

	useEffect(() => {
		let id: string = null;

		if (values != null && props.subLocationFieldName != null && values[props.subLocationFieldName] != null) {
			id = values[props.subLocationFieldName];
		}

		setSubLocationId(id);
	}, [ values ]);

	useEffect(() => {
        setLocationId(subLocationId || field.value);
	}, [ field.value, subLocationId ]);

    useEffect(() => {
        let isCurrent = true;

        const fetchLocation = async () => {
            if (locationId == null) {
                setLocation(null);
                return null;
            }

            const {data} = await new LocationsService().getLocationById(locationId);
            return data;
        };

        fetchLocation()
            .then(location => {
                if (isCurrent) {
                    setLocation(location);
                }
            });

        return () => {
            isCurrent = false;
        };

    }, [locationId]);

	const renderErrors = () => {
		const result = [];
		if (meta?.error) {
			result.push(meta.error);
		}

		if (props.subLocationFieldName && errors[props.subLocationFieldName]) {
			result.push(errors[props.subLocationFieldName]);
		}

		if (result.length == 0) {
			return null;
		}

		return <div>{result.map((error, key) => <span key={key} className="validationMessage">{error}</span>)}</div>
	}

	return <>
		<LocationSelect
			disabled={props.disabled}
			isClearable={true}
			name={field.name}
			onChange={(locations) => {
				const val = locations.length > 0 ? locations[0] : null;
				let ids = {locationId: val?.id, subLocationId: null};

				if (props.subLocationFieldName != null) {
					if (val?.type == subLocationType) {
						ids = {locationId: null, subLocationId: val?.id};
					}

					setTimeout(() => setFieldValue(props.subLocationFieldName, ids.subLocationId), 0);
				}

				helpers.setValue(ids.locationId);
				setLocation(val);
			}}
			selectedOptions={[ location ]}
			fetch={(term) => {
				return new LocationsService().getLocations(
					term,
					props.subLocationFieldName == null ? locationType : null
				);
			}
			}
			isMulti={false}
			placeholder={props.placeholder}
			onFocus={(e) => field.onChange(e)}
			onBlur={(e) => field.onBlur(e)}
			required
		/>
		{renderErrors()}
	</>;
}