import {ReactComponent as UploadCloudSvg} from "@assets/svg/upload-cloud.svg";
import AppModal from "@components/AppModal";
import styles from "@components/Upload.module.scss";
import React, {useCallback} from "react";
import {DropzoneOptions, useDropzone} from "react-dropzone";
import clsx from "clsx";
import {Progress} from "reactstrap";
import {useTranslation} from "react-i18next";
import {AirlineDictionaryItemDto} from "@models/airlines";

type Props = {
    isOpen: boolean;
    onUpload: (file: File) => void;
    onClickCloseButton: () => void;
    uploadProgress: number;
    mode: UploadingMode;
    airline?: AirlineDictionaryItemDto;
};

export enum UploadingMode {
    None,
    Uploading,
    Completed
}

const UploadImageModal = (props: Props) => {

    const {t} = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
    }, []);

    const dropzoneOpts: DropzoneOptions = {
        onDrop,
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        multiple: false,
        onDropAccepted: (files: File[]) => {
            props.onUpload(files[0]);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onDropRejected: fileRejections => {
            alert(t('upload.cantUploadFile'))
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone(dropzoneOpts);

    const renderByMode = () => {
        switch (props.mode){
            case UploadingMode.None:
                return isDragActive ?
                    <>
                        <div className={clsx(styles.text, isDragActive ? styles.textDraggable : null)}>
                            {t('upload.dropFilesHere')}
                        </div>
                    </> :
                    <>
                        <div className={styles.fileFormats}>
                            .jpg .png .gif
                        </div>
                        <div className={styles.text}>
                            {t('upload.dragAndDropFileHere')}
                        </div>
                    </>
            case UploadingMode.Uploading:
                return <>
                    <div className="text-center">{props.uploadProgress}%</div>
                    <Progress value={props.uploadProgress} />
                </>;
            case UploadingMode.Completed:
                return <>
                    <div className={styles.text}>{t('upload.uploadSuccessful')}</div>
                </>;
        }
    }

    return <AppModal
        isOpen={props.isOpen}
        body={<div className={styles.modalBody}>

            <div className={styles.title}>{t('admin.airlines.uploadLogoModalTitle')}: {props.airline?.name}</div>

            <div className={clsx(styles.uploadField, isDragActive ? styles.uploadFieldDraggable : null)} {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={styles.imgWrapper}>

                    <UploadCloudSvg/>

                    {renderByMode()}

                </div>
            </div>

        </div>}
        onClickCloseButton={props.onClickCloseButton}
    />;
};

export default UploadImageModal;