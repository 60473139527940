import React, { useEffect, useState } from "react";
import { SingleDatePicker, SingleDatePickerPhrases } from "react-dates";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import styles from './index.module.scss';

interface Props {
	name: string,
	value?: Date,
	minDate?: Date,
	onChange: (date: Date) => void
}

export const DateTimePicker = ({name, value, minDate, onChange}: Props) => {
	const {t} = useTranslation('', {keyPrefix: 'SingleDatePickerPhrases'});
	const phrases: SingleDatePickerPhrases = {
		calendarLabel: t('calendarLabel'),
	};

	const [ date, setDate ] = useState<Moment>(null);
	const [ showCalendar, setShowCalendar ] = useState<boolean>(false);
	const [ hours, setHours ] = useState(0);
	const [ minutes, setMinutes ] = useState(0);

	useEffect(() => {
		if (value == null) {
			setDate(null);
			return;
		}

		setDate(moment(value));
	}, [ value ]);

	useEffect(() => {
		if (date == null) {
			setHours(0);
			setMinutes(0);
			return;
		}

		setHours(date.hour());
		setMinutes(date.minute());
	}, [ date ]);
	const isOutsideRange = (date: any) => {
		if (minDate == null) {
			return false;
		}

		const minDateMoment = moment(new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()));
		const dateValue = moment(date);

		return dateValue < minDateMoment;
	}

	const changeDate = (date: Moment) => {
		setDate(date);
		onChange(date?.toDate() || null);
	}

	const numbers = Array
		.from({length: 60}, (_, n) => ({label: n.toString(10).padStart(2, '0'), value: n}));

	return <div className={styles.dateTimePicker}>
		<SingleDatePicker
			id={name}
			date={date}
			isOutsideRange={date => isOutsideRange(date)}
			placeholder={t('calendarLabel')}
			focused={showCalendar}
			onDateChange={date => changeDate(date)}
			onFocusChange={(focused) => {
				setShowCalendar(focused.focused);
			}}
			phrases={phrases}
		/>
		{date &&
		<select
			value={hours}
			onChange={e => changeDate(date.hours(numbers[e.currentTarget.selectedIndex].value))}>
			{numbers.filter(o => o.value < 24).map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
		</select>}
		{date &&
			<select
			value={minutes}
			onChange={e => changeDate(date.minute(numbers[e.currentTarget.selectedIndex].value))}>
			{numbers.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
		</select>}
	</div>
}