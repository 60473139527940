import { object, string, TestContext } from "yup";
import { AddressDatumDto, AirwaybillDto } from "@models/awbs/awbsModels";
import { t } from "i18next";
import nameof from "ts-nameof.macro";
import { isInn } from "@helpers/patterns";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@root/store";
import { useEffect, useMemo, useState } from "react";
import { fetchCountries } from "@store/countryStore";

export const useAddressValidation = (showAccountNumber: boolean) => {
	const [ russiaId, setRussiaId ] = useState('');
	const {allCountries} = useAppSelector(x => x.allCountries);
	const dispatch = useDispatch();

	useEffect(() => {
		if (allCountries?.length == 0) {
			dispatch(fetchCountries());
		}
	}, []);

	useEffect(() => {
		setRussiaId(allCountries?.find(c => c.codeIso2 == 'RU')?.id);
	}, [allCountries]);

	const getAllowEmptyInn = (ctx: TestContext<AirwaybillDto>) => {
		return !ctx.parent.isRequiredAccountNumber;
	};

	return useMemo(() => object<AddressDatumDto>()
		.nullable()
		.shape({
			accountNumber: string<string,AirwaybillDto>()
				.nullable()
				.test('accountNumber', t('validation.required'), (value, ctx) => !showAccountNumber || getAllowEmptyInn(ctx) || (value || '').length > 0)
				.when(nameof<AddressDatumDto>((x) => x.countryId),
					{
						is: russiaId,
						then: schema => {
							return schema.test('organizationUniqueNumber', t('validation.organizationUniqueNumber'),
								(value, ctx) => {
									return !showAccountNumber || getAllowEmptyInn(ctx) || isInn(value);
								});
						},
						otherwise: schema => schema.test('organizationUniqueNumber', t('validation.numberType'), (value, ctx) => !showAccountNumber || getAllowEmptyInn(ctx) || value == null || /^\d+$/.test(value.trim()))
					}),
			name: string()
				.required(t('validation.required'))
				.test('name', t('validation.lettersExpected'), value => /\D/.test(value.trim())),
			address: string().required(t('validation.required')),
			postCode: string().required(t('validation.required')),
			cityId: string().nullable()
                .test('cityId', t('validation.required'), (value, ctx) => {
                    const cityName = ctx.parent.cityName;
                    return value != null && value != '' || cityName != null && cityName != '';
                }),
		}), [ showAccountNumber, russiaId ]);
}