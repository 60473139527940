import React from 'react';
import { ReactComponent as IconPlane } from '@material-design-icons/svg/round/connecting_airports.svg';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import styles from './WelcomePage.module.scss';
import AirplaneAnimation from '@scenes/welcome/components/AirplaneAnimation';
import Button from '@components/Button';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { isMainTenant } from '@helpers/tenantsHelper';

const WelcomePage = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <section>
        <Container fluid={true} className={styles.welcomeContainer}>
          <Row>
            <Col>
              <div className={styles.mainCell}>
                <h3 style={{ display: 'none' }}>{t('welcomePage.mainHeader')}</h3>
                <h1>{t('welcomePage.header')}</h1>
                {!isMainTenant &&
                  <Button icon={<IconPlane fill='currentColor' />} type='fill' variant='primary'
                          onClick={() => history.push('/request')}>
                    {t('welcomePage.start')}
                  </Button>
                }
              </div>
              <div className={styles.welcomeAnimation}>
                <AirplaneAnimation />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <footer>
        <Container fluid={true} className={styles.welcomeContainer}>
          <Row
            className={`justify-content-between align-items-center ${styles.footerRow}`}
          >
            <p>{t('welcomePage.copyright')}</p>
            <ul>
              <li>
                <Link to={'/privacy_policy'}>
                  {t('welcomePage.policy')}
                </Link>
              </li>
              <li>
                <Link to={'/terms_of_use'}>
                  {t('welcomePage.terms')}
                </Link>
              </li>
            </ul>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default WelcomePage;
