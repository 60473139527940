import { LanguageType } from '@models/LanguageType';

export enum EventGroupType {
	Registration = 1 << 0,
	PersonalArea = 1 << 1,
	Requests = 1 << 2,
	Applications = 1 << 3
}

export enum PersonalAreaEventType {
	OrganizationUpdated = 1 << 0,
	ContactCreated = 1 << 1,
	ContactUpdated = 1 << 2,
	CanLoginFlagChanged = 1 << 3,
	ActivationFlagChanged = 1 << 4,
}

export enum CustomerApplicationEventType {
	Created = 1 << 0,
	Updated = 1 << 1,
	AwbCreated = 1 << 2,
	AwbRemoved = 1 << 3,
	AwbUpdated = 1 << 4,
	AwbImportedFromFile = 1 << 5,
	AwbImportedFromCode = 1 << 6,
	BookingRequested = 1 << 7,
	FwbSended = 1 << 8,
}

export enum CustomerRequestEventType {
	Created = 1 << 0,
	QuotationRequested = 1 << 1,
	DiscountRequested = 1 << 2,
	ErrorDuringRequest = 1 << 3,
}

export enum RegistrationEventType {
	Registered = 1 << 0,
	//Updated = 1 << 1,
	AccreditationInfoUpdated = 1 << 2,
}

export type CreateNotificationSettings = {
	email: string;
	languageTypes: LanguageType[];
	eventGroupTypes: EventGroupType[];
	eventTypeName: string;
	eventTypeValues: number[];
	airlineId?: string;
	locationId?: string;
};

export type UpdateNotificationSettings = {
	id: string;
	email: string;
	languageTypes: LanguageType[];
	eventGroupTypes: EventGroupType[];
	eventTypeName: string;
	eventTypeValues: number[];
	airlineId?: string;
	locationId?: string;
};

export type NotificationSettingsEntryDto = {
	id: string;
	email: string;
	languageTypes: LanguageType[];
	eventGroupTypes: EventGroupType[];
	eventTypeName: string;
	eventTypeValues: number[];
	airlineName?: string;
	airlineId?: string;
	locationName?: string;
	locationId?: string;
	senderEmailId?: string;
};