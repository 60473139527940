import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import DateInput from '@components/demoForm/inputs/DateInput/dateInput';
import { useAppDispatch, useAppSelector } from '@root/store';
import { getServices } from '@store/demoBooking/demoBookingServiceStore';
import Combobox from '@components/demoForm/inputs/Combobox';
import { ServiceDto } from '@models/demoBooking/ServiceDto';
import { StaffDto } from '@models/demoBooking/StaffDto';
import { getStaffs } from '@store/demoBooking/staffStore';
import { getTimeslotKey, getTimeslots } from '@store/demoBooking/timeslotStore';
import moment from 'moment';
import TextInput from '@components/demoForm/inputs/TextInput';
import DemoBookingService from '@services/DemoBookingService';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type TimeslotDto = {
	id: string;
	name: string;
};

enum DisplayMode {
	booking,
	contactForm,
	result
}

const DemoForm = (): JSX.Element => {
	const cx = classNames.bind(styles);
	const [bookingDate, setBookingDate] = useState<Date>(null);
	const [currentService, setCurrentService] = useState<ServiceDto>(null);
	const [currentStaff, setCurrentStaff] = useState<StaffDto>(null);
	const [staffList, setStaffList] = useState<StaffDto[]>([]);
	const [timeslots, setTimeslots] = useState<TimeslotDto[]>([]);
	const [selectedTimeslot, setSelectedTimeslot] = useState<TimeslotDto>(null);
	const [displayMode, setDisplayMode] = useState(DisplayMode.booking);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [comment, setComment] = useState('');
	const [isContactFormSendingEnabled, setIsContactFormSendingEnabled] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [noTimeslots, setNoTimeslots] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const refForm = useRef<HTMLElement>(null);

	const {services, isFetched, isFetching} = useAppSelector(appState => appState.demoBookingService);
	const {staffMap} = useAppSelector(appState => appState.staffMap);
	const timeslotStore = useAppSelector(appState => appState.timeslotMap);
	const timeslotMap = timeslotStore.timeslotMap;
	const timezoneMap = timeslotStore.timezoneMap;
	const isTimeslotFetched = timeslotStore.isFetched;
	const {t} = useTranslation();
	const {hash} = useLocation();

	useEffect(() => {
		if (hash == null || refForm.current == null) {
			return;
		}

		if(hash == '#demo') {
			refForm.current.scrollIntoView();
		}

	}, [hash, refForm]);

	const dispatch = useAppDispatch();
	useEffect(() => {
		if (!isFetched && !isFetching) {
			dispatch(getServices());
		}
	});

	useEffect(() => {
		if (currentService != null) {
			dispatch(getStaffs(currentService.id));
		}
	}, [currentService]);

	useEffect(() => {
		const staffs = staffMap != null ? staffMap[currentService?.id] : [];
		setStaffList(staffs || []);

	}, [staffMap]);

	const fetchTimeslots = () => {
		const momentDate = moment(bookingDate);
		setSelectedTimeslot(null);
		if (currentService != null && currentStaff != null
			&& momentDate.isValid() && !moment(bookingDate).isBefore(moment(), 'day')) {
			setNoTimeslots(false);
			dispatch(getTimeslots({date: bookingDate, staffId: currentStaff.id, serviceId: currentService.id}));
		}
	};

	useEffect(() => {
		fetchTimeslots();
	}, [currentService, currentStaff, bookingDate]);

	useEffect(() => {
		if (currentService == null || currentStaff == null || bookingDate == null) {
			return;
		}

		const items = timeslotMap != null
			? timeslotMap[getTimeslotKey(currentService.id, currentStaff.id, bookingDate)]
			: [];

		setTimeslots((items || []).map(value => {
			const date = new Date(value);
			const hours = date.getHours().toString(10).padStart(2, '0');
			const minutes = date.getMinutes().toString(10).padStart(2, '0');
			return ({id: date.valueOf().toString(10), name: `${hours}:${minutes}`});
		}));
	}, [timeslotMap]);

	useEffect(() => {
		if (displayMode != DisplayMode.contactForm) {
			setIsContactFormSendingEnabled(false);
			return;
		}

		setIsContactFormSendingEnabled(name.trim().length > 0 && phone.trim().length > 0 && email.trim().length > 0);
	}, [name, email, phone]);
	const isAvailableDate = (value: Date): boolean => {
		const momentDate = moment(value).utc(true);
		const today = moment().utc(true).startOf('day');

		return !momentDate.isBefore(today);
	};

	useEffect(() => {
		if (!isTimeslotFetched
			|| timeslotMap == null
			|| currentService == null
			|| currentStaff == null
			|| !moment(bookingDate).isValid()
			|| moment(bookingDate).isBefore(moment(), 'day')) {
			setNoTimeslots(false);
			return;
		}

		const timeslotKey = getTimeslotKey(currentService.id, currentStaff.id, bookingDate);
		const hasData = timeslotMap[timeslotKey].length > 0;
		setNoTimeslots(!hasData);
	}, [isTimeslotFetched]);

	const sendData = async (): Promise<void> => {
		const demoBookingService = new DemoBookingService();
		setIsContactFormSendingEnabled(false);
		setIsSending(true);

		const result = await demoBookingService.bookAppointmentAsync(currentService.id, currentStaff.id, new Date(+ selectedTimeslot.id),
			name.trim(), phone.trim(), email.trim(), comment, timezoneMap[getTimeslotKey(currentService.id, currentStaff.id, bookingDate)]);

		setIsSending(false);
		let errorMessage = '';
		if (result.data.status == 'success' || result.data.status == 'upcoming') {
			setDisplayMode(DisplayMode.result);
		} else {
			setIsContactFormSendingEnabled(true);
			errorMessage = result.data.message || 'general_error';
		}

		setErrorMessage(t(`zohoBooking.Messages.${errorMessage}`));
	};

	return <section ref={refForm} id='demo' className={styles['lets-start']}>
		<div className={styles.container}>
			<div className={styles['start-form']}
				 style={{display: displayMode == DisplayMode.booking ? 'block' : 'none'}}>
				<div
					className={classNames(styles['title-line'], styles['content-center'], styles.column, styles['line-large'])}>
					<h2 className={classNames(styles['t-l'], styles['t-center'])}>Запросите демо
						Cargobooking365.com</h2>
					<p className={classNames(styles['text-l'], styles['t-center'])}>Мы проведем показ возможностей
						платформы в удобное время
					</p>
				</div>

				<div className='form-content'>
					<form className={styles.request}>
						<Combobox<ServiceDto> values={services}
											  label='Демо'
											  placeholder='Сервис'
											  name='service'
											  setSelected={(value): void => setCurrentService(value)}/>

						<Combobox<StaffDto> label='Менеджер'
											name='manager'
											values={staffList}
											setSelected={(value): void => setCurrentStaff(value)}
											placeholder='Нет доступных менеджеров'/>

						<DateInput label={'Дата'}
								   name='booking-date'
								   value={bookingDate}
								   onChange={(value): void => setBookingDate(value)}
								   isAvailable={(value): boolean => isAvailableDate(value)}/>

						<Combobox<TimeslotDto> label='Время'
											   name='timeslot'
											   values={timeslots}
											   value={selectedTimeslot}
											   setSelected={(value): void => setSelectedTimeslot(value)}/>

						<div className={classNames(styles['content-center'], styles['submit-data'], cx({
							'no-timeslots-error': noTimeslots
						}))}>
							{noTimeslots &&
								<div className={styles['error-message']}>Нет доступных слотов. Попробуйте сменить
									менеджера или дату</div>}
							<button className={classNames(styles.btn, styles.main)}
									disabled={selectedTimeslot == null}
									onClick={(e): void => {
										e.preventDefault();
										setDisplayMode(DisplayMode.contactForm);
									}}>Забронировать звонок
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className={styles['start-form']}
				 style={{display: displayMode == DisplayMode.contactForm ? 'block' : 'none'}}>
				<div
					className={classNames(styles['title-line'], styles['content-center'], styles.column, styles['line-large'])}>
					<h2 className={classNames(styles['t-l'], styles['t-center'])}>Контактные данные</h2>
				</div>

				<div className='form-content'>
					<TextInput label='Имя'
							   name='name'
							   value={name}
							   onChange={(value): void => setName(value)}
							   placeholder='Имя'/>

					<TextInput label='email'
							   name='email'
							   value={email}
							   onChange={(value): void => setEmail(value)}
							   type='email'
							   placeholder='email'/>
					<TextInput label='Контактный номер'
							   name='phone'
							   value={phone}
							   onChange={(value): void => setPhone(value)}
							   placeholder='Телефон'/>
					<label className={styles.label}>
						<span>Комментарий</span>
						<textarea onChange={(event): void => setComment(event.currentTarget.value)} value={comment}/>
					</label>
					{errorMessage && <div className='validationMessage'>{errorMessage}</div>}
					<div className={classNames(styles['content-center'], styles['submit-data'])}>
						{displayMode == DisplayMode.contactForm && <button className={classNames(styles.btn)}
								 disabled={isSending}
								 onClick={async (e): Promise<void> => {
									 e.preventDefault();
									 fetchTimeslots();
									 setDisplayMode(DisplayMode.booking);
								 }}>{t('zohoBooking.Back')}
						</button>}
						<button className={classNames(styles.btn, styles.main)}
								disabled={!isContactFormSendingEnabled}
								onClick={async (e): Promise<void> => {
									e.preventDefault();
									return sendData();
								}}>{t('zohoBooking.Order')}
						</button>
					</div>
				</div>
			</div>
			<div className={styles['start-form']}
				 style={{display: displayMode == DisplayMode.result ? 'block' : 'none'}}>
				<div
					className={classNames(styles['title-line'], styles['content-center'], styles.column, styles['line-large'])}>
					<h2 className={classNames(styles['t-l'], styles['t-center'])}>Спасибо! Ваше сообщение
						отправлено</h2>
				</div>
			</div>
		</div>
	</section>;
};

export default DemoForm;