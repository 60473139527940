import * as React from 'react';
import { Switch, RouteComponentProps, useHistory } from 'react-router';
import DictionariesPage from './dictionaries/main/DictionariesPage';
import AdminLayout from '@layouts/AdminLayout';
import {
    useTranslation,
    withTranslation,
    WithTranslation,
} from 'react-i18next';
import adminStyles from './Admin.module.scss';
import AdminMainPage from './AdminMainPage';
import AirlinesPage from '@scenes/admin/dictionaries/airlines/AirlinesPage';
import TariffsPage from '@scenes/admin/dictionaries/tariffs/TariffsPage';
import SettingsPage from '@scenes/admin/SettingsPage';
import UsersPage from '@scenes/admin/dictionaries/users/UsersPage';
import OrganizationsPage from '@scenes/admin/dictionaries/organizations/OrganizationsPage';
import { Row } from 'reactstrap';
import { AdminTopNavBlock } from '@scenes/admin/components/AdminTopNavBlock';
import NotificationsMainPage from '@scenes/admin/notifications/NotificationsMainPage';
import SessionManager from '@root/SessionManager';
import { UserRoles } from '@config/ApiAuthorizationConstants';
import { ServiceModePage } from '@scenes/admin/serviceModePage';
import { useAppSelector } from "@root/store";
import { OrganizationAccessLevel } from "@models/organizations";

type Props = RouteComponentProps<{}> & WithTranslation;

const DictionariesGoBackLink = (): JSX.Element => {
    const history = useHistory();
    const {t} = useTranslation();

    return (
        <Row className='mb-3'>
            <AdminTopNavBlock
                backButtonText={t('admin.dictionaries.goBack')}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onClickBackButton={(e) => history.push('/admin/dictionaries')}
            />
        </Row>
    );
};

const AdminRoutes = (props: Props) => {
    const {organizationSettings, isOrganizationSettingsFetching} = useAppSelector(x => x.organizationSettingsStore);
    if (isOrganizationSettingsFetching || organizationSettings.accessLevel !== OrganizationAccessLevel.Granted &&
        organizationSettings.accessLevel !== OrganizationAccessLevel.GrantedNoBooking) {
        return null;
    }

    return (
        <>
            <Switch>
                <AdminLayout
                    exact
                    path={`${props.match.url}/panel`}
                    component={AdminMainPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/dictionaries`}
                    component={DictionariesPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/dictionaries/airlines`}
                    component={AirlinesPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/dictionaries/tariffs`}
                    component={TariffsPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/settings`}
                    component={SettingsPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/notifications`}
                    component={NotificationsMainPage}
                />
                {SessionManager.user.hasAnyRole(UserRoles.serviceMode) &&
                    <AdminLayout
                        exact
                        path={`${props.match.url}/service-mode`}
                        component={ServiceModePage}
                    />
                }
                <AdminLayout
                    exact
                    path={`${props.match.url}/dictionaries/users/:organizationId?`}
                    component={UsersPage}
                />
                <AdminLayout
                    exact
                    path={`${props.match.url}/dictionaries/organizations`}
                    component={OrganizationsPage}
                />
            </Switch>
        </>
    );
};

export default withTranslation()(AdminRoutes);