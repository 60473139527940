import { useTranslation } from 'react-i18next';
import { ReactComponent as ComingSoon } from '@material-design-icons/svg/round/hourglass_top.svg';
import React from 'react';
import s from './styles.module.scss';

const Awb = () => {
  const { t } = useTranslation();

  return(
    <div className={s.comingSoon}>
      <ComingSoon />
      <span>Feature coming soon</span>
    </div>
  );
}

export default Awb;