import React, { useEffect, useState } from 'react';
import LanguageSelect from '@components/select/LanguageSelect';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchLanguages } from '@store/languagesStore';
import { changeLanguage } from '@helpers/localizationHelpers';
import AccountService from '@services/AccountService';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export default function LanguageEditor() {

	const {t} = useTranslation();

	const { languages } = useAppSelector((x) => x.languages);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchLanguages());
	}, []);

	// const activeLanguage = useMemo(() => {
	//
	// 	if(!(languages?.length > 0) || props.preferredLanguageId == null){
	// 		return;
	// 	}
	//
	// 	languages.find()
	//
	// }, [props.preferredLanguageId, languages])

	const [selectedLanguageIso, setSelectedLanguageIso] = useState<string>(i18n.language);

	const onClickSave = async () => {
		const selectedLanguage = languages.find(x => x.iso == selectedLanguageIso);
		await Promise.all([
			new AccountService().changePreferredLanguage(selectedLanguage.id),
			changeLanguage(selectedLanguageIso)
		]);
	};

	return (
		<>
			<label className="label">{t('preferredLanguage')}</label>
			<LanguageSelect
				availableOptions={languages}
				selectedOption={languages.find((x) => x.iso === selectedLanguageIso)}
				onChange={(x) => {
					setSelectedLanguageIso(x.iso);
				}}
				placeholder={t('selectLanguage')}
				isClearable={false}
			/>
			<a
				className={'btn btn-primary mt-4'}
				onClick={(e) => {
					e.preventDefault();
					onClickSave();
				}}
			>
				{t('save')}
			</a>
		</>
	);
}