import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import CustomerApplicationsService from '@services/CustomerApplicationsService';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { IGetCustomerApplicationHistoryDto } from '@models/customerApplications/customerApplicationHistoryModels';
import { Navigation, StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { getRequestHistoryData } from '@store/customerRequests/customerRequestsHistoryStore';

export interface IState {
    isFetching: boolean;
    pagingWrapper: IPagingWrapper<IGetCustomerApplicationHistoryDto>;
}

// Create the slice.
const slice = createSlice({
    name: 'customerApplicationsHistory',
    initialState: {
        isFetching: true,
        pagingWrapper: {
            items: [],
            totalCount: 0,
        },
    } as IState,
    reducers: {
        setFetching: (state: IState, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
        setData: (state: IState, action: PayloadAction<{
            pagingWrapper: IPagingWrapper<IGetCustomerApplicationHistoryDto>
        }>) => {
            state.pagingWrapper = action.payload.pagingWrapper;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getRequestHistoryData.pending,
                state => {
                    state.isFetching = true;
                    state.pagingWrapper = null;
                })
            .addCase(getApplicationHistoryData.rejected,
                state => {
                    state.isFetching = false;
                })
            .addCase(getApplicationHistoryData.fulfilled,
                (state, action) => {
                    state.isFetching = false;
                    state.pagingWrapper = action.payload;
                });
    },
});

// Export reducer from the slice.
export const { reducer } = slice;

export const actionCreators = {
    getData: (nav: StandardNavigation) => async (dispatch: Dispatch) => {
        dispatch(slice.actions.setFetching(true));
        const r = await new CustomerApplicationsService().getHistory(nav);

        if (r.data) {
            dispatch(slice.actions.setData({ pagingWrapper: r.data }));
            dispatch(slice.actions.setFetching(false));
        }
    },
};

export const getApplicationHistoryData = createAsyncThunk('customerRequestsHistory/getHistoryData', async (nav: Navigation) => {
    const {data: pagingWrapper} = await new CustomerApplicationsService().getHistory(nav);
    return pagingWrapper;
})