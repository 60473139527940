import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import commonRu from './translations/ru/common';
import commonEn from './translations/en/common';
import { localStorageService } from '@services/LocalStorageService';
import moment from 'moment';
import 'moment/locale/ru';
import SettingsService from '@services/SettingsService';

const savedLocale = localStorageService.getItem<string>('locale');

i18n.use(ICU)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				common: commonEn,
			},
			ru: {
				common: commonRu,
			},
		},
		lng: savedLocale || 'ru',
		debug: process.env.NODE_ENV != 'production',
		defaultNS: 'common',
		ns: ['common', 'settings'],
		keySeparator: '.',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

if (!i18n.hasResourceBundle(i18n.language, 'settings')) {
	new SettingsService().getLocalizedSettingsJson(i18n.language).then((x) => {
		i18n.addResources(i18n.language, 'settings', x.data);
	});
}

moment.locale(i18n.language);

export default i18n;