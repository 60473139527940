import React from 'react';
import s from './commentsList.module.scss';
import CommentItem from '../../../../components/CommentItem';
import { CommentDto } from '@models/comment';

export type ICommentsListProps = {
  comments: Array<CommentDto>;
};

const CommentsList: React.FC<ICommentsListProps> = ({ comments }) => {
  return (
    <div className={s.commentsList}>
      {comments && comments.length > 0 &&
        comments.map((c) => (
          <CommentItem comment={c} key={c.id} />
        ))}
    </div>
  );
};

export default CommentsList;
