//import { JobPositionType, JobPositionTypeDto } from './JobPositionType';
import { InteractionType, InteractionTypeDto } from './InteractionType';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

export type ContactDto = {
	id: string;
	phone: string;
	canLogin?: boolean;
	email: string;
	firstName: string;
	lastName: string;
	hasPassword: boolean;
	inactive: boolean;
	//jobPositionType?: JobPositionType;
	jobTitle: string;
	interactionTypes?: InteractionType[];
};

export type ContactListItemDto = {
	id: string;
	canLogin: boolean;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	inactive: boolean;
	//jobPositionType?: JobPositionTypeDto;
	jobTitle: string;
	interactionTypes?: InteractionTypeDto[];
};

export class GetContacts extends StandardNavigation {
	organizationId?: string;
}

export type UpdateContact = {
	id: string;
	phone: string;
	canLogin: boolean;
	email: string;
	firstName: string;
	lastName: string;
	//jobPositionType?: JobPositionType;
	jobTitle: string;
	interactionTypes?: InteractionType[];
};

export type CreateContact = {
	organizationId: string;
	phone: string;
	canLogin: boolean;
	email: string;
	firstName: string;
	lastName: string;
	//jobPositionType?: JobPositionType;
	jobTitle: string;
	interactionTypes: InteractionType[];
};

export type UpdateContactsPermissions = {
	permissions: { contactId: string; permissions: ContactPermissionType[] }[];
}

export type ContactPermissionListItemDto = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	permissions: ContactPermissionType[];
}

export type ToggleContactActivation = {
	id: string;
	isActivated: boolean;
	deactivateReason?: string;
}