import StaticSelect from '@components/select/StaticSelect';
import React from 'react';

const Select = function <TObject, TValue>(props: {
	availableObjects: TObject[];
	disabledSelector?: (obj: TObject) => boolean;
	fixedSelector?: (obj: TObject) => boolean;
	values: TValue[];
	valueSelector: (obj: TObject) => TValue;
	placeholder: string;
	optionLabelSelector: (selectedObject: TObject) => JSX.Element;
	selectedLabelSelector?: (selectedObject: TObject) => JSX.Element;
	onChange: (selectedObjects: TObject[], selectedValues: TValue[]) => void;
	isMulti?: boolean;
	isClearable?: boolean;
}) {

	const getSelectedObjects = (vals: TValue[]): TObject[] => {
		if (vals == null) {
			return [];
		}

		const selectedObj: TObject[] = [];

		if (Array.isArray(vals)) {
			props.availableObjects.forEach((obj) => {
				const fieldValue = props.valueSelector(obj);
				if (vals.includes(fieldValue)) {
					selectedObj.push(obj);
				}
			});
		}

		return selectedObj;
	};

	return (
		<StaticSelect<TObject>
			isMulti={props.isMulti}
			value={getSelectedObjects(props.values)}
			checkboxes={props.isMulti}
			availableOptions={props.availableObjects}
			onChange={(x) => {
				props.onChange(
					x,
					x.map((c) => props.valueSelector(c))
				);
			}}
			optionLabel={props.optionLabelSelector}
			selectedLabel={props.selectedLabelSelector}
			isSearchable={false}
			placeholder={props.placeholder}
			disabledSelector={props.disabledSelector}
			fixedSelector={props.fixedSelector}
			isClearable={props.isClearable}
		/>
	);
};

export default Select;