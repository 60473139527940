import * as React from 'react';
import { CreateCustomerApplication } from '@models/customerApplications/customerApplicationModels';
import { Formik, Field, FormikProps } from 'formik';
import nameof from 'ts-nameof.macro';
import styles from '../CustomerApplicationPage.module.scss';
import '@icomoon/style.css';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { airwaybillNumberMask, isNullOrEmptyString, validateAirwaybillNumber } from '@helpers/stringHelpers';
import { useEffect, useRef, useState } from 'react';
import Inputmask from 'inputmask';
import clsx from 'clsx';
import LocationSelect from '@components/select/LocationSelect';
import LocationsService from '@services/LocationsService';
import { LocationDto, LocationType } from '@models/locations';
import ValidationMessage from '@components/ValidationMessage';
import Tippy from '@tippyjs/react';
import { ReactComponent as Info } from '@assets/svg/legacy/info.svg';
import FileUploader, { AttachedFile } from '@components/FileUploader';
import FilesService from '@services/FilesService';
import { object, string } from "yup";

type Model = CreateCustomerApplication;

export interface IProps {
	data: Model;
	airlineIataPrefix: string;
	viewData?: { dateStartPlan: string };
	onSubmit: (data: Model, files: File[]) => void;
	requestQuotation: boolean;
	children: (renderEditor: () => JSX.Element, submitButtonClickEvent: () => void) => JSX.Element;
}

const CustomerApplicationCreateForm: React.FC<IProps> = (props: IProps) => {
	const [ senderCity, setSenderCity ] = useState<LocationDto>();
	const [ recipientCity, setRecipientCity ] = useState<LocationDto>();
	const [ attachedFiles, setAttachedFiles ] = useState<AttachedFile[]>([]);

	const {t} = useTranslation();

	const validationSchema = object<Partial<Model>>().shape({
		airwaybillNumber: string()
			.nullable()
			.test('airwaybillNumber',
				t('validation.invalidAwbNumberChecksum'), (x) => {
					if (isNullOrEmptyString(x)) {
						return true;
					}
					const number = x?.replace(/[\s+_-]/gm, '');
					const hasOnlyIataPrefix = number == props.airlineIataPrefix;
					if (hasOnlyIataPrefix) {
						return true;
					}

					return validateAirwaybillNumber(x);
				})
			.test('airWaybillNumber', t('validation.invalidAwbNumberAirlineIata'), (x) => {
				if (x == null) {
					return true;
				}
				if (x?.startsWith(props.airlineIataPrefix) == true) {
					return true;
				}
				return false;
			}),
		senderAddress: !props.requestQuotation ? string().nullable().required(t('validation.required')) : null,
		recipientAddress: !props.requestQuotation ? string().nullable().required(t('validation.required')) : null,
		email: string().nullable().email(t('validation.email')),
		additionalEmail: string().nullable().email(t('validation.email')),
	});

	const awbRef = useRef();

	useEffect(() => {
		if (awbRef.current) {
			const im = new Inputmask(airwaybillNumberMask);
			im.mask(awbRef.current);
		}

		if (props.data?.senderCity != null) {
			setSenderCity(props.data?.senderCity);
		}

		if (props.data?.recipientCity != null) {
			setRecipientCity(props.data?.recipientCity);
		}
	}, [ props.data?.senderCity, props.data?.recipientCity ]);

	const onSubmitForm = (values: Model) => {
		const data = {...values};
		if (data.airwaybillNumber == props.airlineIataPrefix) {
			data.airwaybillNumber = null;
		}
		props.onSubmit(
			data,
			attachedFiles.map((x) => x.blob)
		);
	};

	const renderEditor = (formikProps: FormikProps<Model>) => {
		const {isCargoDangerous} = formikProps.values;

		return (
			<div className="form">
				<div className={styles.block}>
					<Row>
						<Col className="form-group" md={8}>
							<Field name={nameof.full<Model>((x) => x.airwaybillNumber)}>
								{({field}) => (
									<>
										<label
											className={`${styles.formLabel} control-label`}
											htmlFor="form__awbNumber"
										>
											{t('customerApplication.form.awbNumber')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__awbNumber"
											name={field.name}
											value={field.value || ''}
											onChange={field.onChange}
											ref={awbRef}
											onFocus={() => {
												if (isNullOrEmptyString(field.value)) {
													formikProps.setFieldValue(field.name, props.airlineIataPrefix);
												}
											}}
										/>
										<ValidationMessage name={field.name} errors={formikProps.errors}/>
									</>
								)}
							</Field>
						</Col>
						<Col className="form-group" md={4}>
							<label className={`${styles.formLabel} control-label`} htmlFor="form__date">
								{t('customerApplication.form.date')}
							</label>
							<div className={styles.dateInputWrapper}>
								<i className={`${styles.calendarIcon} icon-calendar`}/>
								<input
									type="text"
									className={`${styles.formInput} ${styles.dateInput} form-control`}
									id="form__date"
									disabled={true}
									value={props.viewData?.dateStartPlan}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<div className={clsx('d-flex')}>
					<div className={clsx('d-flex flex-row w-100')}>
						<div className={clsx([ styles.block, 'w-50 mr-2' ])}>
							<Row>
								<Col className="form-group" md={12}>
									<Field name={nameof.full<Model>((x) => x.cargoDetails)}>
										{(x) => (
											<>
												<label
													className={`${styles.formLabel} control-label`}
													htmlFor="form__cargoDetails"
												>
													{t('customerApplication.form.cargoDetails')}
												</label>
												<textarea
													className={`${styles.formInput} form-control`}
													id="form__cargoDetails"
													name={x.field.name}
													value={x.field.value || ''}
													onChange={x.field.onChange}
													style={{height: 132}}
												/>
											</>
										)}
									</Field>
								</Col>

								<Col md={12}>
									<div className="form-check-inline mr-5">
										<Field name={nameof.full<Model>((x) => x.isCargoStacked)}>
											{({field}) => (
												<div className="checkbox">
													<label className={`${styles.formLabel}`}>
														<input
															type="checkbox"
															checked={field.value}
															onChange={({target}) => {
																field.onChange({
																	target: {
																		name: field.name,
																		value: target.checked,
																	},
																});
															}}
														/>
														&nbsp;
														<span>
															{t('customerApplication.form.stacked')}{' '}
															<Tippy content={t('customerApplication.form.stackedHint')}>
																<i style={{cursor: 'pointer'}}>
																	{' '}
																	<Info/>
																</i>
															</Tippy>
														</span>
													</label>
												</div>
											)}
										</Field>
									</div>

									<div className="form-check-inline mr-5">
										<Field name={nameof.full<Model>((x) => x.isCargoTurnover)}>
											{({field}) => (
												<div className="checkbox">
													<label className={`${styles.formLabel}`}>
														<input
															type="checkbox"
															checked={field.value}
															onChange={({target}) => {
																field.onChange({
																	target: {
																		name: field.name,
																		value: target.checked,
																	},
																});
															}}
														/>
														&nbsp;
														<span>
															{t('customerApplication.form.turnover')}{' '}
															<Tippy content={t('customerApplication.form.turnoverHint')}>
																<i style={{cursor: 'pointer'}}>
																	{' '}
																	<Info/>
																</i>
															</Tippy>
														</span>
													</label>
												</div>
											)}
										</Field>
									</div>

									<div className="form-check-inline mr-5">
										<Field name={nameof.full<Model>((x) => x.allowPackingWithOtherGoods)}>
											{({field}) => (
												<div className="checkbox">
													<label className={`${styles.formLabel}`}>
														<input
															type="checkbox"
															checked={field.value}
															onChange={({target}) => {
																field.onChange({
																	target: {
																		name: field.name,
																		value: target.checked,
																	},
																});
															}}
														/>
														&nbsp;
														<span>
															{t('customerApplication.form.packingWithOtherGoods')}{' '}
															<Tippy
																content={t(
																	'customerApplication.form.packingWithOtherGoodsHint'
																)}
															>
																<i style={{cursor: 'pointer'}}>
																	{' '}
																	<Info/>
																</i>
															</Tippy>
														</span>
													</label>
												</div>
											)}
										</Field>
									</div>
								</Col>
							</Row>
						</div>
						<div className={clsx([ styles.block, 'w-50' ])}>
							<Row>
								<Col className="form-group">
									<Field name={nameof.full<Model>((x) => x.specialConditions)}>
										{({field}) => (
											<>
												<label
													className={`${styles.formLabel} control-label`}
													htmlFor="form__specialConditions"
												>
													{t('customerApplication.form.carriageSpecialTerms')}
												</label>
												<textarea
													className={`${styles.formInput} form-control`}
													id="form__specialConditions"
													name={field.name}
													value={field.value || ''}
													onChange={field.onChange}
													style={{minHeight: 132}}
												/>
											</>
										)}
									</Field>
								</Col>
							</Row>

							<Row>
								<Col className="form-group mb-0">
									<Field name={nameof.full<Model>((x) => x.isCargoDangerous)}>
										{(x) => (
											<div className={`${styles.cargoNonDangerous} checkbox`}>
												<label className={`${styles.formLabel}`}>
													<input
														type="checkbox"
														checked={x.field.value}
														onChange={({target}) => {
															x.field.onChange({
																target: {
																	name: x.field.name,
																	value: target.checked,
																},
															});
														}}
													/>
													&nbsp;
													{t('customerApplication.form.isCargoDangerous')}
												</label>
											</div>
										)}
									</Field>
								</Col>
							</Row>

							{isCargoDangerous && (
								<Row>
									<Col className="form-group align-self-end" md={4}>
										<Field name={nameof.full<Model>((x) => x.cargoDangerClass)}>
											{(x) => (
												<>
													<label
														className={`${styles.formLabel} control-label`}
														htmlFor="form__cargoDangerClass"
													>
														{t('customerApplication.form.hazardClass')}
													</label>
													<input
														type="text"
														className={`${styles.formInput} form-control`}
														id="form__cargoDangerClass"
														name={x.field.name}
														value={x.field.value || ''}
														onChange={x.field.onChange}
													/>
												</>
											)}
										</Field>
									</Col>
									<Col className="form-group align-self-end" md={4}>
										<Field name={nameof.full<Model>((x) => x.cargoUnNumber)}>
											{(x) => (
												<>
													<label
														className={`${styles.formLabel} control-label`}
														htmlFor="form__cargoUnNumber"
													>
														{t('customerApplication.form.cargoUnNumber')}
													</label>
													<input
														type="text"
														className={`${styles.formInput} form-control`}
														id="form__cargoUnNumber"
														name={x.field.name}
														value={x.field.value || ''}
														onChange={x.field.onChange}
													/>
												</>
											)}
										</Field>
									</Col>
									<Col className="form-group align-self-end" md={4}>
										<Field name={nameof.full<Model>((x) => x.cargoPackagedForPlaneType)}>
											{({field}) => (
												<>
													<label
														className={`${styles.formLabel} control-label`}
														htmlFor="form__cargoPackagedForPlaneType"
													>
														{t('customerApplication.form.cargoPackagedForPlaneType')}
													</label>
													<select
														className={`${styles.formInput} form-control`}
														id="form__cargoPackagedForPlaneType"
														name={field.name}
														value={parseInt(field.value || '0')}
														onChange={({target}) => {
															field.onChange({
																target: {
																	name: field.name,
																	value: parseInt(target.value),
																},
															});
														}}
													>
														<option value={0}>
															{t('customerApplication.form.cargoPlane')}
														</option>
														<option value={1}>
															{t('customerApplication.form.passengerPlane')}
														</option>
													</select>
												</>
											)}
										</Field>
									</Col>
								</Row>
							)}
						</div>
					</div>
				</div>

				<div className={clsx('d-flex')}>
					<div className={clsx('d-flex flex-row w-100')}>
						<div className={clsx([ styles.block, 'w-50 mr-2' ])}>
							<Row>
								<Col className="form-group">
									<Field name={nameof.full<Model>((x) => x.senderAddress)}>
										{(x) => (
											<>
												<label
													className={clsx(
														styles.formLabel,
														'control-label',
														!props.requestQuotation ? 'required' : null
													)}
													htmlFor="form__senderAddress"
												>
													{t('customerApplication.form.senderAddress')}
												</label>
												<textarea
													className={`${styles.formInput} form-control`}
													id="form__senderAddress"
													name={x.field.name}
													value={x.field.value || ''}
													onChange={x.field.onChange}
													style={{minHeight: 80}}
												/>
												<ValidationMessage name={x.field.name} errors={formikProps.errors}/>
											</>
										)}
									</Field>
								</Col>
							</Row>

							<Row className={'pb-3'}>
								<Col>
									<Field name={nameof.full<Model>((x) => x.senderStateProvince)}>
										{(x) => (
											<>
												<label
													className={clsx(styles.formLabel, 'control-label')}
													htmlFor="form__senderStateProvince"
												>
													{t('customerApplication.form.senderStateProvince')}
												</label>
												<input
													type={'text'}
													className={`${styles.formInput} form-control`}
													id="form__senderStateProvince"
													name={x.field.name}
													value={x.field.value || ''}
													onChange={x.field.onChange}
												/>
											</>
										)}
									</Field>
								</Col>
							</Row>

							<Row>
								<Col className="pb-3">
									<Field name={nameof.full<Model>((x) => x.senderCity.id)}>
										{(x) => {
											return (
												<>
													<label
														className={clsx(styles.formLabel, 'control-label')}
														htmlFor="form__senderCity"
													>
														{t('customerApplication.form.senderCity')}
													</label>
													<LocationSelect
														isClearable={true}
														name={x.field.name}
														onChange={(locations) => {
															const val = locations.length > 0 ? locations[0] : null;
															x.form.setFieldValue(x.field.name, val?.id);
															setSenderCity(val);
														}}
														selectedOptions={[ senderCity ]}
														fetch={(term) =>
															new LocationsService().getLocations(term, LocationType.City)
														}
														isMulti={false}
														placeholder={t('customerApplication.form.senderCity')}
														onFocus={x.field.onFocus}
														onBlur={x.field.onBlur}
													/>
													<span className={styles.fielsDescr}>
														{t('customerApplication.form.startTypingHint')}
													</span>
												</>
											);
										}}
									</Field>
								</Col>
							</Row>
						</div>
						<div className={clsx([ styles.block, 'w-50' ])}>
							<Row>
								<Col className="form-group">
									<Field name={nameof.full<Model>((x) => x.recipientAddress)}>
										{(x) => (
											<>
												<label
													className={clsx(
														styles.formLabel,
														'control-label',
														!props.requestQuotation ? 'required' : null
													)}
													htmlFor="form__recipientAddress"
												>
													{t('customerApplication.form.recipientAddress')}
												</label>
												<textarea
													className={`${styles.formInput} form-control`}
													id="form__recipientAddress"
													name={x.field.name}
													value={x.field.value || ''}
													onChange={x.field.onChange}
													style={{minHeight: 80}}
												/>
												<ValidationMessage name={x.field.name} errors={formikProps.errors}/>
											</>
										)}
									</Field>
								</Col>
							</Row>

							<Row>
								<Col className="pb-3">
									<Field name={nameof.full<Model>((x) => x.recipientStateProvince)}>
										{(x) => (
											<>
												<label
													className={clsx(styles.formLabel, 'control-label')}
													htmlFor="form__recipientStateProvince"
												>
													{t('customerApplication.form.recipientStateProvince')}
												</label>
												<input
													type={'text'}
													className={`${styles.formInput} form-control`}
													id="form__recipientStateProvince"
													name={x.field.name}
													value={x.field.value || ''}
													onChange={x.field.onChange}
												/>
											</>
										)}
									</Field>
								</Col>
							</Row>

							<Row>
								<Col className="pb-3">
									<Field name={nameof.full<Model>((x) => x.recipientCity.id)}>
										{(x) => {
											return (
												<>
													<label
														className={clsx(styles.formLabel, 'control-label')}
														htmlFor="form__recipientCity"
													>
														{t('customerApplication.form.recipientCity')}
													</label>
													<LocationSelect
														isClearable={true}
														name={x.field.name}
														onChange={(locations) => {
															const val = locations.length > 0 ? locations[0] : null;
															x.form.setFieldValue(x.field.name, val?.id);
															setRecipientCity(val);
														}}
														selectedOptions={[ recipientCity ]}
														fetch={(term) =>
															new LocationsService().getLocations(term, LocationType.City)
														}
														isMulti={false}
														placeholder={t('customerApplication.form.recipientCity')}
														onFocus={x.field.onFocus}
														onBlur={x.field.onBlur}
													/>
													<span className={styles.fielsDescr}>
														{t('customerApplication.form.startTypingHint')}
													</span>
												</>
											);
										}}
									</Field>
								</Col>
							</Row>
						</div>
					</div>
				</div>

				<div className={styles.block}>
					<Row>
						<Col>
							<span className={styles.formLabel}>{t('customerApplication.form.specialPrice')}</span>
							<br/>
							<br/>
						</Col>
					</Row>

					<Row>
						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.referenceNumber)}>
								{({field}) => (
									<>
										<label
											className={`${styles.formLabel} control-label`}
											htmlFor="form__refNumber"
										>
											{t('customerApplication.form.refNumber')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__refNumber"
											name={field.name}
											value={field.value || ''}
											onChange={field.onChange}
										/>
									</>
								)}
							</Field>
						</Col>
						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.awbProvidedBy)}>
								{({field}) => (
									<>
										<label
											className={`${styles.formLabel} control-label`}
											htmlFor="form__awbProvidedBy"
										>
											{t('customerApplication.form.awbProvidedBy')}
										</label>
										<select
											className={`${styles.formInput} form-control`}
											id="form__awbProvidedBy"
											name={field.name}
											value={parseInt(field.value || '0')}
											onChange={({target}) => {
												field.onChange({
													target: {
														name: field.name,
														value: parseInt(target.value),
													},
												});
											}}
										>
											<option value={0}>{t('customerApplication.form.fromSender')}</option>
											<option value={1}>{t('customerApplication.form.fromProvider')}</option>
										</select>
									</>
								)}
							</Field>
						</Col>
					</Row>
				</div>

				<div className={styles.block}>
					<Row>
						<Col>
							<span className={styles.formLabel}>{t('customerApplication.form.contactInformation')}</span>
							<br/>
							<br/>
						</Col>
					</Row>

					<Row>
						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.email)}>
								{({field}) => (
									<>
										<label className={`${styles.formLabel} control-label`} htmlFor="form__email">
											{t('customerApplication.form.email')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__email"
											name={field.name}
											readOnly
											value={field.value || ''}
											onChange={field.onChange}
										/>
										<ValidationMessage name={field.name} errors={formikProps.errors}/>
									</>
								)}
							</Field>
						</Col>

						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.firstName)}>
								{({field}) => (
									<>
										<label
											className={`${styles.formLabel} control-label`}
											htmlFor="form__firstName"
										>
											{t('customerApplication.form.firstName')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__firstName"
											name={field.name}
											value={field.value || ''}
											onChange={field.onChange}
										/>
									</>
								)}
							</Field>
						</Col>
						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.lastName)}>
								{({field}) => (
									<>
										<label className={`${styles.formLabel} control-label`} htmlFor="form__lastName">
											{t('customerApplication.form.lastName')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__lastName"
											name={field.name}
											value={field.value || ''}
											onChange={field.onChange}
										/>
									</>
								)}
							</Field>
						</Col>

						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.phoneNumber)}>
								{({field}) => (
									<>
										<label
											className={`${styles.formLabel} control-label`}
											htmlFor="form__phoneNumber"
										>
											{t('customerApplication.form.phoneNumber')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__phoneNumber"
											name={field.name}
											value={field.value || ''}
											onChange={field.onChange}
										/>
									</>
								)}
							</Field>
						</Col>

						<Col className="form-group">
							<Field name={nameof.full<Model>((x) => x.additionalEmail)}>
								{({field}) => (
									<>
										<label
											className={`${styles.formLabel} control-label`}
											htmlFor="form__additionalEmail"
										>
											{t('customerApplication.form.additionalEmail')}
										</label>
										<input
											type="text"
											className={`${styles.formInput} form-control`}
											id="form__additionalEmail"
											name={field.name}
											value={field.value || ''}
											onChange={field.onChange}
										/>
										<ValidationMessage name={field.name} errors={formikProps.errors}/>
									</>
								)}
							</Field>
						</Col>
					</Row>
				</div>

				{!props.requestQuotation && (
					<div className={styles.block}>
						<Row className="pb-3">
							<Col>
								<span className={styles.formLabel}>{t('upload.attachedFiles')}</span>
							</Col>
						</Row>
						<Row>
							<Col className="form-group">
								<FileUploader
									maxFileCount={5}
									value={attachedFiles}
									onChange={(files) => setAttachedFiles(files)}
									downloadPreviewBlob={(file) => {
										return new FilesService().getAttachment(file.id);
									}}
								/>
							</Col>
						</Row>
					</div>
				)}
			</div>
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={props.data}
			validationSchema={validationSchema}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onSubmit={(values, {setSubmitting}) => {
				onSubmitForm(values);
			}}
		>
			{(formikProps) => {
				return props.children(() => renderEditor(formikProps), formikProps.handleSubmit);
			}}
		</Formik>
	);
};

export default CustomerApplicationCreateForm;