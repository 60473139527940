import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import {
	CreateSettingsEntry,
	ServiceStateEntry,
	SettingsEntryDto,
	UpdateSettingsEntry
} from '@models/admin/settingsModels';
import SettingsService from '@services/SettingsService';

export type State = {
	isFetching: boolean;
	models: SettingsEntryDto[];
	startServiceMode?: string;
	endServiceMode?: string;
};

export const fetchSettings = createAppThunk(
	'fetchSettings',
	async () => {
		const {data} = await new SettingsService().get();
		return data;
	});

export const createSettingsEntry = createAppThunk(
	'createSettingsEntry',
	async (arg: CreateSettingsEntry) => {
		const {data: id} = await new SettingsService().create(arg);
		return id;
	});

export const updateSettingsEntry = createAppThunk(
	'updateSettingsEntry',
	async (arg: UpdateSettingsEntry) => {
		const {data} = await new SettingsService().update(arg);
		return data;
	});

export const deleteSettingsEntry = createAppThunk(
	'deleteSettingsEntry',
	async (arg: string) => {
		await new SettingsService().delete(arg);
		return arg;
	});

export const getServiceModeState = createAppThunk(
	'getServiceModeState',
	async () => {
		const {data} = await new SettingsService().getServiceModeState();
		if (data == null || data.start == null || data.end == null) {
			return null;
		}

		return data;
	}
);

export const updateServiceModeState = createAppThunk(
	'updateServiceModeState',
	async (serviceState: ServiceStateEntry) => {
		const {data} = await new SettingsService().updateServiceModeState(serviceState);
		return data;
	}
);

const slice = createSlice({
	name: 'settings',
	initialState: {
		isFetching: true,
		models: [],
		startServiceMode: null,
		endServiceMode: null
	} as State,
	reducers: {},
	extraReducers: builder => {

		builder.addCase(fetchSettings.fulfilled, (state, action) => {
			state.models = action.payload;
		});

		builder.addCase(createSettingsEntry.fulfilled, (state, action) => {
			state.models.push({...action.meta.arg, id: action.payload as string});
		});

		builder.addCase(updateSettingsEntry.fulfilled, (state, action) => {

			const updatedModel = {...action.meta.arg};

			const model = state.models.find(x => x.id == action.meta.arg.id);

			model.value = updatedModel.value;
			model.key = updatedModel.key;
			model.category = updatedModel.category;
			model.inactive = updatedModel.inactive;
			model.languageId = updatedModel.languageId;
		});

		builder
			.addCase(deleteSettingsEntry.fulfilled, (state, action) => {
				state.models = state.models.filter(x => x.id != action.meta.arg);
			});

		builder
			.addCase(getServiceModeState.pending,
				(state) => {
					state.startServiceMode = null;
					state.endServiceMode = null;
					state.isFetching = true;
				})
			.addCase(getServiceModeState.fulfilled,
				(state, action) => {
					state.isFetching = false;
					if (action.payload) {
						state.startServiceMode = action.payload.start;
						state.endServiceMode = action.payload.end;
					}
				});

		builder
			.addCase(updateServiceModeState.pending,
				state => {
					state.startServiceMode = null;
					state.endServiceMode = null;
					state.isFetching = true;
				})
			.addCase(updateServiceModeState.fulfilled,
				(state, action) => {
					state.isFetching = false;
					if (action.payload) {
						state.startServiceMode = action.payload.start;
						state.endServiceMode = action.payload.end;
					}
				});
	}
});

export const {reducer} = slice;
export const {actions} = slice;