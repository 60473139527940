import * as React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import styles from '../CustomerApplicationPage.module.scss';
import {useHistory} from "react-router";
import {CreateCustomerApplicationDto} from "@models/customerApplications/customerApplicationModels";
import { isNullOrEmptyString } from '@helpers/stringHelpers';

type Props = {
    createResult: CreateCustomerApplicationDto;
    className?: string;
    isOpen: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClickHomeButton: (e: any) => void;
    onClickCloseButton: () => void;
}

const CustomerApplicationSuccessModal = (props: Props) => {

    const {t} = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const h = useHistory();

    return (
        <Modal isOpen={props.isOpen} toggle={() => props.onClickCloseButton()} className={styles.modal}>
            <ModalHeader toggle={() => props.onClickCloseButton()}/>
            <ModalBody>

                <div className={`${styles.modalSuccessIcon} successIcon`}/>

                <div className={styles.modalSuccessTitle}>
                    {t(props.createResult ? 'customerApplication.modal.successApplicationTitle' : 'customerApplication.modal.successQuatationTitle')}
                </div>

				{
					!isNullOrEmptyString(props.createResult?.airwaybillNumber) && <>
						<div className={styles.modalFollowNextSteps}>{t('customerApplication.modal.followNextSteps')}</div>
						<div className={styles.modalCustomerApplicationNumber}>{props.createResult?.airwaybillNumber}</div>
					</>
				}

            </ModalBody>
            <ModalFooter>
                <Button color="primary"
                        onClick={() => props.onClickCloseButton()}>{t('customerApplication.modal.homePage')}</Button>{' '}
                <a href="#"
                   className={styles.activeRequestsButton}
                   onClick={e => props.onClickHomeButton(e)}
                >{t('customerApplication.modal.activeRequests')}<i
                    className={`${styles.activeRequestsArrowIcon} icon-arrow-right`}/></a>
            </ModalFooter>
        </Modal>
    );
}

export default CustomerApplicationSuccessModal;