import * as React from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { fetchLanguages } from '@store/languagesStore';
import { LanguageDto } from '@models/languagesModels';
import { Button, Input, FormGroup, Label, Alert, Col } from 'reactstrap';
import loginStyle from './Login.module.scss';
import { RegisterModel, RegistrationResultType } from '@models/accounts/accountModels';
import { useTranslation } from 'react-i18next';
import AccountService from '@services/AccountService';
import { Form, Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import nameof from 'ts-nameof.macro';
import { useEffect, useMemo, useState } from 'react';
import { isInn } from '@helpers/patterns';
import LanguageSelect from '@components/select/LanguageSelect';
import i18n from '@i18n';
import { number, object, string } from 'yup';
import { changeLanguage } from "@helpers/localizationHelpers";
import Loader from "@components/Loader";
import CountryService from '@services/CountryService';
import Select from 'react-select';
import { CountryDictionaryItem } from "@models/countries";

type InputTextProps = {
	name: string;
	classes: string;
	placeholder: string;
	hasError?: boolean;
	formikProps: FormikProps<RegisterModel>;
};

const InputText = (props: InputTextProps): JSX.Element => {
	const hasError = props.formikProps.errors[props.name] != null && props.formikProps.touched[props.name] != null;

	const classes = props.classes + (hasError ? ' ' + loginStyle.hasError : '');

	return (
		<>
			<div className="w-100">
				<Input
					autoComplete="off"
					type="text"
					name={props.name}
					placeholder={props.placeholder}
					className={classes}
					onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
						props.formikProps.setFieldValue(props.name, event.target.value);
					}}
					onBlur={(): void => {
						props.formikProps.setFieldTouched(props.name, true);
					}}
				/>
				{props.formikProps.touched[props.name] &&
					props.formikProps.errors[props.name] &&
					props.formikProps.errors[props.name] !== 'required' && (
						<div style={{color: 'red'}}>{props.formikProps.errors[props.name]}</div>
					)}
			</div>
		</>
	);
};

export default function RegisterPage() {

	const dispatch = useAppDispatch();

	const {languages} = useAppSelector((x) => x.languages);

	const {
		//isJobPositionTypesFetching,
		isInteractionTypesFetching,
		//jobPositionTypes
	} = useAppSelector((x) => x.contacts);

	const [ isCustomActivity, setIsCustomActivity ] = useState<boolean>(false);
	const [ error, setError ] = useState<string>();
	const [ options, setOptions ] = useState<CountryDictionaryItem[]>([]);
	const [ country, setCountry ] = useState<CountryDictionaryItem>(null);
	const [ preferredLanguage, setPreferredLanguage ] = useState('');
	const [ russiaId, setRussiaId ] = useState('');

	const {t} = useTranslation();

	const [ successResult, setSuccessResult ] = useState<RegistrationResultType>(null);

	const initialValues: RegisterModel = {
		organizationName: '',
		organizationUniqueNumber: '',
		firstName: '',
		lastName: '',
		jobTitle: '', //jobPositionType: null,
		email: '',
		phone: '',
		forwarding: 'Forwarding',
		activityType: '',
		countryId: ''
	};

	const changeActivity = (customActivity: boolean): void => {
		setIsCustomActivity(customActivity);
	};

	const [ showForm, setShowForm ] = useState(false);

	const onLanguageChanged = async (value?: LanguageDto) => {
		if (!value) return setShowForm(false);

		setShowForm(value && country != null);

		await changeLanguage(value.iso);
		setPreferredLanguage(value.iso);
		new CountryService().getAllCountries()
			.then(countries => {
				if (value.iso == 'ru') {
					countries = countries.sort((a, b) => {
						if (a.codeIso2 == 'RU') {
							return - 1;
						}

						if (b.codeIso2 == 'RU') {
							return 1;
						}

						if (a.name < b.name) {
							return - 1;
						}

						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
				}

				setOptions(countries);
				setRussiaId(countries.find(c => c.codeIso2 == 'RU')?.id);
			});
	};

	useEffect(() => {
		if (!(languages?.length > 0)) {
			dispatch(fetchLanguages());
		}
		// if(!(jobPositionTypes?.length > 0)){
		// 	dispatch(fetchJobPositionTypes());
		// }
	}, []);

	const validationSchema = useMemo(
		() =>
			object<RegisterModel>().shape({
				organizationName: string().required(t('validation.required')),
				countryId: string().required(t('validation.required')),
				organizationUniqueNumber: string()
					.required(t('validation.required'))
					.when(nameof.full<RegisterModel>((x) => x.countryId),
						{
							is: russiaId,
							then: schema => schema.test('organizationUniqueNumber', t('validation.organizationUniqueNumber'), isInn),
							otherwise: schema => schema.test('organizationUniqueNumber', t('validation.numberType'), value => value != null && /^\d+$/.test(value.trim()))
						}),
				firstName: string().required(t('validation.required')),
				lastName: string().required(t('validation.required')),
				jobTitle: string().required(t('validation.required')),
				//jobPositionType: number().nullable().required(t('validation.required')),
				email: string().required(t('validation.required')).email(t('validation.email')),
				phone: string().required(t('validation.required')),
				forwarding: string().required(t('validation.required')),
				activityType: string().when(
					nameof.full<RegisterModel>((x) => x.forwarding),
					{
						is: 'Other',
						then: schema => schema.required(t('validation.required')),
					}
				),
			}),
		[ i18n.language, russiaId ]
	);

	useEffect(() => {
		setShowForm(country != null && preferredLanguage != '');
	}, [ country, preferredLanguage ]);
	const renderSuccessMessage = () => {

		if (successResult == null) {
			return null;
		}

		let text;

		switch (successResult) {
			case RegistrationResultType.UserAllowedToLogin:
				text = t('register.success');
				break;
			case RegistrationResultType.UserOnProcess:
				text = t('register.successShouldModerated');
				break;
		}

		if (text == null) {
			return null;
		}

		return (
			<FormGroup row className='mt-5'>
				<Alert color="success" className="w-100" style={{whiteSpace: 'pre-line'}}>
					{text}
				</Alert>
			</FormGroup>
		);
	};

	const renderErrorMessage = () => {

		if (error == null) {
			return null;
		}

		return (
			<FormGroup row>
				<Alert color="danger" className="w-100" style={{whiteSpace: 'pre-line'}}>
					{error}
				</Alert>
			</FormGroup>
		);
	};

	return (
		isInteractionTypesFetching ? <Loader/> : <Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values: RegisterModel, {
				setSubmitting,
				setErrors,
				setFieldError
			}: FormikHelpers<RegisterModel>): Promise<void> => {
				setError(null);
				try {
					const accountService = new AccountService();
					const response = await accountService.register(values);
					setSuccessResult(response.data.registrationResult);
				} catch (error) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const data = error.response.data;
					setError(t(`validation.${data.message}`));
					// @ts-ignore
					for (const field of data.fields) {
						setFieldError(field.name, field.messages.join(','));
					}
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({isSubmitting}: FormikProps<RegisterModel>): JSX.Element => (
				<div className="simple-layout-content-container">

					{successResult == null &&
						<Form className={loginStyle.form}>
							<FormGroup row>
								<h1 className="header-30">{t('register.header')}</h1>
							</FormGroup>
							<FormGroup row>
								<p className="paragraph">{t('register.language')}</p>
							</FormGroup>
							<div className="mb-3">
								<Field name={nameof.full<RegisterModel>((x) => x.preferredLanguageId)}>
									{({field, form}: FieldProps): JSX.Element => (
										<LanguageSelect
											availableOptions={languages}
											selectedOption={languages.find((x) => x.id === field.value)}
											onChange={(value): void => {
												form.setFieldValue(field.name, value?.id ?? null);
												onLanguageChanged(value);
											}}
											placeholder={t('register.languagePlaceholder')}
											isClearable={true}
										/>
									)}
								</Field>
								<FormGroup row className={'mt-4'}>
									<Col>
										<Field name={nameof.full<RegisterModel>((x) => x.countryId)}>
											{({field, form}: FieldProps<RegisterModel>): JSX.Element => (
												<>
													<Select
														isClearable
														isSearchable
														options={options}
														placeholder={t('register.country')}
														getOptionLabel={option => option.translatedName}
														getOptionValue={option => option.id}
														value={country}
														onChange={value => {
															form.setFieldValue(field.name, value?.id);
															setCountry(value);
														}}
													/>
													{form.touched[field.name] &&
														form.errors[field.name] &&
														form.errors[field.name] !== 'required' && (
															<div style={{color: 'red'}}>{form.errors[field.name]}</div>
														)}
												</>
											)}
										</Field>
									</Col>
								</FormGroup>
							</div>
							<div style={{display: showForm ? 'block' : 'none'}}>
								<FormGroup row>
									<p className="paragraph">{t('register.subHeader')}</p>
								</FormGroup>
								<FormGroup row>
									<Field name={nameof.full<RegisterModel>((x) => x.organizationName)}>
										{({field, form}: FieldProps<RegisterModel>): JSX.Element => (
											<InputText
												name={field.name}
												classes="w-100 input-text-primary"
												placeholder={t('register.organizationName')}
												formikProps={form}
											/>
										)}
									</Field>
								</FormGroup>
								<FormGroup row>
									<Field name={nameof.full<RegisterModel>((x) => x.organizationUniqueNumber)}>
										{({field, form}: FieldProps): JSX.Element => (
											<InputText
												name={field.name}
												classes="w-100 input-text-primary"
												placeholder={t('register.inn')}
												formikProps={form}
											/>
										)}
									</Field>
								</FormGroup>
								<FormGroup row className={'justify-content-between ' + loginStyle['two-field-row']}>
									<Field name={nameof.full<RegisterModel>((x) => x.firstName)}>
										{({field, form}: FieldProps): JSX.Element => (
											<InputText
												name={field.name}
												classes="w-100 input-text-primary"
												placeholder={t('register.firstName')}
												formikProps={form}
											/>
										)}
									</Field>
									<Field name={nameof.full<RegisterModel>((x) => x.lastName)}>
										{({field, form}: FieldProps): JSX.Element => (
											<InputText
												name={field.name}
												classes="w-100 input-text-primary"
												placeholder={t('register.lastName')}
												formikProps={form}
											/>
										)}
									</Field>
								</FormGroup>
								<FormGroup row>
									<Field name={nameof.full<RegisterModel>((x) => x.jobTitle)}>
										{({field, form}: FieldProps): JSX.Element => (
											<InputText
												name={field.name}
												classes="w-100 input-text-primary"
												placeholder={t('jobPosition')}
												formikProps={form}
											/>
										)}
									</Field>

									{/*<Field name={nameof.full<RegisterModel>((x) => x.jobPositionType)}>*/}
									{/*	{({ field, form }: FieldProps): JSX.Element => (*/}
									{/*		<InputText*/}
									{/*			name={field.name}*/}
									{/*			classes="w-100 input-text-primary"*/}
									{/*			placeholder={t('jobPosition')}*/}
									{/*			formikProps={form}*/}
									{/*		/>*/}
									{/*	)}*/}
									{/*</Field>*/}
								</FormGroup>
								<FormGroup row className={'justify-content-between ' + loginStyle['two-field-row']}>
									<Field name={nameof.full<RegisterModel>((x) => x.email)}>
										{({field, form}: FieldProps): JSX.Element => (
											<InputText
												name={field.name}
												classes="input-text-primary"
												placeholder={t('register.email')}
												formikProps={form}
											/>
										)}
									</Field>
									<Field name={nameof.full<RegisterModel>((x) => x.phone)}>
										{({field, form}: FieldProps): JSX.Element => (
											<InputText
												name={field.name}
												classes="input-text-primary"
												placeholder={t('register.phone')}
												formikProps={form}
											/>
										)}
									</Field>
								</FormGroup>
								<FormGroup>
									<FormGroup check>
										<Label check>
											<Field name={nameof.full<RegisterModel>((x) => x.forwarding)}>
												{({field, form}): JSX.Element => (
													<Input
														type="radio"
														name="Forwarding"
														defaultChecked
														value="Forwarding"
														onClick={(): void => {
															changeActivity(false);
															form.setFieldValue(field.name, 'Forwarding');
															form.setFieldValue(
																nameof.full<RegisterModel>((x) => x.activityType),
																''
															);
														}}
													/>
												)}
											</Field>
											{t('register.forwarding')}
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check>
											<Field name={nameof.full<RegisterModel>((x) => x.forwarding)}>
												{({field, form}: FieldProps): JSX.Element => (
													<Input
														type="radio"
														name="Forwarding"
														value="Custom activity"
														onClick={(): void => {
															changeActivity(true);
															form.setFieldValue(field.name, 'Custom activity');
														}}
													/>
												)}
											</Field>
											{t('register.other')}
										</Label>
									</FormGroup>
								</FormGroup>
								{isCustomActivity ? (
									<FormGroup row>
										<Field name={nameof.full<RegisterModel>((x) => x.activityType)}>
											{({field, form}: FieldProps): JSX.Element => (
												<InputText
													name={field.name}
													classes="input-text-primary"
													placeholder={t('register.activityType')}
													formikProps={form}
												/>
											)}
										</Field>
									</FormGroup>
								) : null}
								<FormGroup row>
									<Button disabled={isSubmitting} color="primary" className="w-100" type="submit">
										{' '}
										{t('register.signUp')}
									</Button>
								</FormGroup>
								{renderErrorMessage()}
							</div>
						</Form>
					}

					{renderSuccessMessage()}

				</div>
			)}
		</Formik>
	);
}