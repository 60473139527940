import React from 'react';
import { connect } from 'react-redux';
import styles from './RequestHeader.module.scss';

type Props = {
    title: string;
    subtitle: string;
    link: string;
    linkText: string;
};

class RequestHeaderComponent extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <div className={styles.requestTop}>
                <h3 className={styles.title}>{this.props.title}</h3>
                <div className={styles.subtitle}>
                    <span>
                        {this.props.subtitle}
                    </span>
                    {/* <a href={this.props.link}>{this.props.linkText}</a>
                    <MoonIcon
                        icon="icon-arrow-right"
                        className="moon-1x ml-2"
                    ></MoonIcon> */}
                </div>
            </div>
        );
    }
}

export default connect()(RequestHeaderComponent);