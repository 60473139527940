import React, { ChangeEvent, useRef } from "react";
import cn from "classnames";
import { randomString } from "@helpers/stringHelpers";
import s from "./check.module.scss";

interface ICheckBox {
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  onCheck?(checked: boolean): void;
  className?: string;
  label?: string;
}

const CheckBox: React.FC<ICheckBox> = ({ className, onCheck, label, ...props }) => {
  const id = useRef<string>(randomString(6));
  const checkbox = useRef<HTMLInputElement>(null);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => onCheck(e.target.checked);

  const handleClick = (e: any) => {
    if (checkbox.current && !checkbox.current.contains(e.target)) checkbox.current.click();
  };

  return (
    <label
      onClick={handleClick}
      className={cn([s.container, { [s.disable]: props.disabled }, className])}
      htmlFor={id.current}
    >
      <input
        ref={checkbox}
        onChange={onCheck ? handleCheck : undefined}
        className={s.checkBox__input}
        type="checkbox"
        {...props}
      />
      <span className={s.checkBox} />
      {label && <span>{label}</span>}
    </label>
  );
};

export default CheckBox;
