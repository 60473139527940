import React from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { createContact } from '@store/contactsStore';
import PersonalInfoEditor, { EditorMode } from '@scenes/accountSettings/components/PersonalInfoEditor';
import { CreateContact } from '@models/contacts';
import { unwrap } from '@helpers/reduxHelpers';
import { toast } from 'react-toastify';
import styles from './ContactEditPage.module.scss';
import { HttpError } from '@models/shared';

const ContactCreatePage = (props: { organizationId: string; onExit() }) => {
	const { t } = useTranslation();
	const { t: tContact } = useTranslation('', { keyPrefix: 'contact' });

	const { organizationId, onExit } = props;

	const dispatch = useAppDispatch();

	const { isContactEditFetching } = useAppSelector((x) => x.contacts);

	const onSubmitEditPersonalInfo = async (model: CreateContact) => {
		model.organizationId = organizationId;
		const result = await dispatch(createContact(model));
		return unwrap(result) as HttpError;
	};

	return (
		<Row>
			<Col>
				<Row>
					<Col>
						<div className={styles.topBar}>
							<a className={styles.backBtn} onClick={onExit}>
								<i className={`icon-chevron-left`} />
								<span>{t('backToEmployees')}</span>
							</a>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<h4>{tContact('create')}</h4>
					</Col>
				</Row>

				<Row>
					<Col>
						<PersonalInfoEditor<CreateContact>
							data={{
								interactionTypes: [],
							}}
							showCanLogin={true}
							mode={EditorMode.Create}
							onSubmit={onSubmitEditPersonalInfo}
							onSuccess={() => {
								toast.success(t('changesSaved'));
								onExit();
							}}
							onError={(msg) => toast.error(msg)}
						>
							{(renderEditor, onClickSubmit) => {
								return (
									<>
										{renderEditor()}

										<Row>
											<Col className={'text-center mb-3 mt-4'}>
												<button
													type={'button'}
													className={'btn btn-primary'}
													onClick={(e) => {
														e.preventDefault();
														onClickSubmit();
													}}
													disabled={isContactEditFetching}
												>
													{t('create')}
												</button>
											</Col>
										</Row>
									</>
								);
							}}
						</PersonalInfoEditor>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ContactCreatePage;