import { ContactPermissionType } from '@models/contacts/ContactPermissionType';

export type ChangePasswordResponse = {
	succeeded: boolean;
	errors: { code: string; description: string }[];
};

export interface RegisterModel {
	organizationName?: string;
	organizationUniqueNumber?: string;
	firstName?: string;
	lastName?: string;
	jobTitle?: string; //jobPositionType?: number;
	email?: string;
	phone?: string;
	forwarding?: string;
	activityType?: string;
	preferredLanguageId?: string;
	countryId?: string;
}

export interface RegisterModelErrors {
	organizationName?: string;
	organizationUniqueNumber?: string;
	firstName?: string;
	lastName?: string;
	jobTitle?: string; //jobPositionType?: number;
	email?: string;
	phone?: string;
	activityType?: string;
}

export type SuccessLoginDto = {
	id: string;
	token: string;
	refreshToken: string;
	/* Token expiration UTC date. */
	expiration: string;
	roles: string[];
	contactId: string;
	organizationId: string;
};

export type RefreshTokenDto = {
	token: string;
	refreshToken: string;
	/* Token expiration UTC date. */
	expiration: string;
	roles: string[];
	permissions: ContactPermissionType[];
};

export enum RegistrationResultType {
	UserAllowedToLogin = 1,
	UserOnProcess = 2,
}

export type RegistrationResultDto = {
	registrationResult: RegistrationResultType;
};

export type LoginToAccount = {
	userName: string;
	password: string;
	remeberMe: string;
};