import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import CustomerApplicationCreateForm from './components/CustomerApplicationCreateForm';
import CustomerApplicationSuccessModal from './components/CustomerApplicationSuccessModal';
import styles from './CustomerApplicationPage.module.scss';
import { useAppDispatch, useAppSelector } from '@root/store';
import { createCustomerApplication, fetchAuxData } from '@store/customerApplications/customerApplicationsStore';
import { CreateCustomerApplication } from '@models/customerApplications/customerApplicationModels';
import { GetCustomerRequestDto, GetSaleDto } from '@models/customerRequests/customerRequestModels';
import { toDDMMYYYYformat } from '@helpers/dateHelpers';
import { useTranslation } from 'react-i18next';
import CustomerApplicationInfoHeader from './components/CustomerApplicationInfoHeader';
import { LocationType } from '@models/locations';
import { DateTime } from '@helpers/DateTime';
import qs from 'qs';
import { ContactDto } from '@models/contacts';
import { createQuotationRequest } from '@store/customerRequests/customerRequestsStore';
import { FormGroup, Input, Label } from 'reactstrap';
import { Loader } from '@components/index';
import { useEffect, useState } from "react";

interface IParams {
	requestId: string,
	saleId: string,
	routeStartDate: string,
	routeId: string;
	requestQuotation: string;
}

const CreateCustomerApplicationPage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [userAgreed, setUserAgreed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const {requestId, saleId, routeStartDate, routeId, requestQuotation} = useParams<IParams>();
	const [isQuotation, setIsQuotation] = useState(false);

	const auxData = useAppSelector(appState => appState.customerApplications.auxData);
	const createResult = useAppSelector(appState => appState.customerApplications.createResult);

	const dispatch = useAppDispatch();
	const history = useHistory();
	const {t} = useTranslation();

	useEffect(() => {
		setIsQuotation(requestQuotation == 'true');
	}, [requestQuotation]);

	useEffect(() => {
		dispatch(fetchAuxData({customerRequestId: requestId, saleId}));
	}, [requestId, saleId]);

	const getNewCustomerApplication = (
		request: GetCustomerRequestDto,
		sale: GetSaleDto,
		profileInfo: ContactDto,
	): CreateCustomerApplication => {
		if (!request || !sale || !profileInfo) {
			return {} as CreateCustomerApplication;
		}

		const senderCityId =
			sale.departurePoint?.type == LocationType.City
				? sale.departurePoint.id
				: sale.departurePoint?.parent?.id;

		const recipientCityId =
			sale.destinationPoint?.type == LocationType.City
				? sale.destinationPoint.id
				: sale.destinationPoint?.parent?.id;

		const {firstName, lastName, email, phone} = profileInfo;
		const _request = auxData?.customerRequest;

		return {
			dateStartPlan: null,
			airwaybillNumber: _request?.airwaybillNumber || null,
			saleId: sale.id,
			senderCityId: senderCityId, // !!!
			recipientCityId: recipientCityId, // !!!
			isCargoDangerous: _request?.isCargoDangerous,
			isCargoStacked: _request?.isCargoStacked === null ? true : _request?.isCargoStacked,
			isCargoTurnover: _request?.isCargoTurnover === null ? true : _request?.isCargoTurnover,
			allowPackingWithOtherGoods:
				_request?.allowPackingWithOtherGoods === null ? true : _request?.allowPackingWithOtherGoods,
			cargoDetails: _request?.cargoDetails || null,
			cargoDangerClass: _request?.cargoDangerClass || null,
			senderAddress: _request?.senderAddress || null,
			senderStateProvince: _request?.senderStateProvince || null,
			recipientAddress: _request?.recipientAddress || null,
			recipientStateProvince: _request?.recipientStateProvince || null,
			referenceNumber: _request?.referenceNumber || null,
			awbProvidedBy: _request?.awbProvidedBy || 0,
			cargoUnNumber: _request?.cargoUnNumber || null,
			cargoPackagedForPlaneType: _request?.cargoPackagedForPlaneType || 0,
			specialConditions: (_request?.specialHandlingCodes || []).map((x) => x.name).join('; '),
			firstName: _request?.firstName || firstName,
			lastName: _request?.lastName || lastName,
			email,
			additionalEmail: _request?.additionalEmail || '',
			phoneNumber: phone,
		} as CreateCustomerApplication;
	};

	const onSubmit = async (data: CreateCustomerApplication, files: File[]) => {
		const dataToSave = {...data};

		setIsLoading(true);
		const {sale, customerRequest} = auxData;

		dataToSave.saleId = sale?.id;
		dataToSave.customerRequestId = customerRequest.id;

		dataToSave.dateStartPlan = routeStartDate
			? new DateTime(routeStartDate).toISOString()
			: auxData.customerRequest.dateStartPlan;

		dataToSave.routeId = routeId;

		let task: Promise<any>;

		if (isQuotation) {
			task = dispatch(createQuotationRequest({model: dataToSave}));
		} else {
			task = dispatch(createCustomerApplication({model: dataToSave, files}));
		}

		task
			.then(() => {
			setIsModalOpen(true);
			setIsLoading(false);
		});
	};

	return (
		<div className={styles.customerApplicationWrapper}>
			<CustomerApplicationInfoHeader/>

			<CustomerApplicationCreateForm
				data={getNewCustomerApplication(
					auxData?.customerRequest,
					auxData?.sale,
					auxData?.contact,
				)}
				airlineIataPrefix={auxData?.sale?.airlineIataPrefix}
				viewData={{
					dateStartPlan:
						auxData?.customerRequest?.dateStartPlan != null
							? toDDMMYYYYformat(
								new Date(
									routeStartDate ||
									auxData?.customerRequest?.dateStartPlan,
								),
							)
							: '',
				}}
				onSubmit={(data, files) => {
					onSubmit(data as CreateCustomerApplication, files);
				}}
				requestQuotation={isQuotation}
			>
				{(renderEditor, submitButtonClickEvent) => (
					<>
						{renderEditor()}
						{isLoading ? (
							<Loader/>
						) : (
							<>
								<div className='mt-4'>
									<FormGroup check>
										<Label check>
											<Input
												type='checkbox'
												name='agreement'
												// eslint-disable-next-line @typescript-eslint/no-unused-vars
												onChange={() =>
													setUserAgreed(!userAgreed)
												}
											/>{' '}
											{t('customerApplication.form.aggrementTxt')}
										</Label>
									</FormGroup>
								</div>
								<div className='text-center mb-5 mt-4 startContainer'>
									<button
										className={`${styles.submitButton} btn btn-primary`}
										type='submit'
										disabled={
											isLoading ||
											(!isQuotation && !userAgreed)
										}
										onClick={() => {
											submitButtonClickEvent();

											setTimeout(() => {
												const messages = document.querySelectorAll('.validationMessage');
												if (messages.length > 0) {
													messages[0].scrollIntoView({block: 'center'});
												}
											}, 300);
										}}
									>
											<span className={styles.submitButtonIcon}>
												<i
													className={
														styles.airplaneInnerIcon +
														` moon-icon icon-airoplane-solid-inner`
													}
												/>
											</span>
										<span className='pl-1 startButton'>
												{t('customerApplication.form.sendRequest')}
											</span>
									</button>
									<button
										disabled={isLoading}
										className={`${styles.cancelButton} btn btn-secondary`}
										onClick={() => {
											const queryString = window.location.search.slice(1);
											const obj = qs.parse(queryString);
											delete obj.requestQuotation;

											history.push(`/request/search?${qs.stringify(obj)}`);
										}}
									>
										{t('customerApplication.form.cancel')}
									</button>
								</div>
							</>
						)}
					</>
				)}
			</CustomerApplicationCreateForm>
			<CustomerApplicationSuccessModal
				createResult={createResult}
				isOpen={isModalOpen}
				onClickCloseButton={() => history.push('/request')}
				onClickHomeButton={(e) => {
					e.preventDefault();
					const redirectUrl: string =
						!isQuotation && createResult
							? `/bookingHistory#${createResult?.id}`
							: `/requestsHistory#${auxData?.customerRequest?.id}`;
					history.push(redirectUrl);
				}}
			/>
		</div>
	);
};

export default CreateCustomerApplicationPage;