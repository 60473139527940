import { HttpError } from '@models/shared';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { TariffDictionaryItem } from '@models/tariffs';

export default class AirlinesService {
    public dictionary = (nav: StandardNavigation) => {
        return AuthorizedHttpClient.post<IPagingWrapper<TariffDictionaryItem> & HttpError>(
            `api/tariff/dictionary`,
            nav
        );
    };
}
