import * as React from 'react';
import {useTranslation} from "react-i18next";
import {ApplicationState, useAppDispatch} from "@root/store";
import {useSelector} from "react-redux";
import {getFwbCode, sendFwb, State} from "@store/airwaybills/airwaybillsStore";
import {useEffect} from "react";
import {Col, Row} from "reactstrap";
import Tippy from "@tippyjs/react";
import { useClipboard } from 'use-clipboard-copy';
import clsx from "clsx";

type Props = {
    airwaybillId: string;
    onSendFwbCompleted?: () => void;
}

const FwbPage = (props: Props) => {

    const {fwbCode} = useSelector<ApplicationState, State>(x => x.airwaybills);

    const dispatch = useAppDispatch();

    const clipboard = useClipboard({ copiedTimeout: 1000 });

    const {t} = useTranslation();

    useEffect(() => {
        dispatch(getFwbCode(props.airwaybillId));
    }, [props.airwaybillId]);

    const sendFwbCode = (e) => {
        dispatch(sendFwb({id: props.airwaybillId, fwbCode: fwbCode}))
            .then(_ => {
                if (props.onSendFwbCompleted) {
                    props.onSendFwbCompleted();
                }
            });
    }

    return <>
        <h2>{t('awb.fwb')}</h2>
        <Row className="mb-3">
            <Col>
                <textarea disabled={true} ref={clipboard.target} className="form-control" style={{height: 200}} value={fwbCode} />
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <button
                    onClick={clipboard.copy}
                    className={clsx("btn", !clipboard.copied ? "btn-primary" : "btn-secondary")}>
                    {clipboard.copied ? t('awb.copied') : <><i className="icon-inline icon-copy" /> {t('awb.copyCode')}</>}
                </button>
                {"  "}
                <button className="btn btn-primary"
                        onClick={sendFwbCode}>
                    <i className="icon-inline icon-mail" /> {t('awb.send')}
                </button>
            </Col>
        </Row>
    </>;
};

export default FwbPage;