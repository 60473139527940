import React, { useEffect, useState } from 'react';

import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { getOrganizationsDictionary, updateAccreditationListFilledFlag } from '@store/organizationsStore';

import {
	FilterConnection,
	FilterOperator,
	FilterValue,
	FilterValueCondition,
	PagingFilter,
} from '@models/entityNavigation/filtering';
import nameof from 'ts-nameof.macro';

import { useAppDispatch, useAppSelector } from '@root/store';
import { Col, Row } from 'reactstrap';
import dictionaryStyles from '../../components/DictionaryStyles.module.scss';
import clsx from 'clsx';
import debounce from 'awesome-debounce-promise';
import Paginator from '@components/paginator/Paginator';
import { Loader } from '@components/index';
import { OrganizationDictionaryItem } from '@models/organizations';

import OrganizationItem from '@scenes/admin/dictionaries/organizations/components/OrganizationItem';
import { useTranslation } from 'react-i18next';
import PageHeader from '@components/PageHeader';

const createTermConnection = (term?: string): FilterConnection => {
	const termFilterConnection = new FilterConnection(FilterOperator.Or);

	const nameFilterValue = new FilterValue(
		nameof.full<OrganizationDictionaryItem>((x) => x.name),
		FilterValueCondition.Contains,
		term
	);

	const phoneFilterValue = new FilterValue(
		nameof.full<OrganizationDictionaryItem>((x) => x.phone),
		FilterValueCondition.Contains,
		term
	);

	const emailFilterValue = new FilterValue(
		nameof.full<OrganizationDictionaryItem>((x) => x.email),
		FilterValueCondition.Contains,
		term
	);

	const uniqueNumberFilterValue = new FilterValue(
		nameof.full<OrganizationDictionaryItem>((x) => x.uniqueNumber),
		FilterValueCondition.Contains,
		term
	);

	const vatNumberFilterValue = new FilterValue(
		nameof.full<OrganizationDictionaryItem>((x) => x.vatNumber),
		FilterValueCondition.Contains,
		term
	);

	const regNumberFilterValue = new FilterValue(
		nameof.full<OrganizationDictionaryItem>((x) => x.regNumber),
		FilterValueCondition.Contains,
		term
	);

	termFilterConnection.values.push(nameFilterValue);
	termFilterConnection.values.push(phoneFilterValue);
	termFilterConnection.values.push(emailFilterValue);
	termFilterConnection.values.push(uniqueNumberFilterValue);
	termFilterConnection.values.push(vatNumberFilterValue);
	termFilterConnection.values.push(regNumberFilterValue);

	return termFilterConnection;
};

const createPagingFilter = (pageNumber?: number): PagingFilter => {
	return new PagingFilter(pageNumber ?? 0, 10);
};

const createNavigation = (term?: string, pageNumber?: number): StandardNavigation => {
	const navigation = new StandardNavigation();

	navigation.pagingFilter = createPagingFilter(pageNumber);

	const termFilterConnection = createTermConnection(term);

	navigation.filters.push(termFilterConnection);

	return navigation;
};

const OrganizationsPage = (): JSX.Element => {
	const { isOrganizationsFetching, updateInProgress, organizations } = useAppSelector((x) => x.organizations);

	const [term, setTerm] = useState('');

	const [navigation, setNavigation] = useState<StandardNavigation>(createNavigation());

	const debouncedTermChanged = debounce((term: string) => {
		setTerm(term);
		setNavigation(createNavigation(term, 0));
	}, 800);

	const onSearchByTerm = (term: string): void => {
		term = term?.trim() ?? '';
		debouncedTermChanged(term);
	};

	const onChangePage = (pageNumber: number): void => {
		setNavigation(createNavigation(term, pageNumber));
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getOrganizationsDictionary(navigation));
	}, [navigation]);

	const { t } = useTranslation();

	const onChangeAccreditationListFilled = async (isFilled: boolean, organizationId: string) => {
		await dispatch(
			updateAccreditationListFilledFlag({
				organizationId,
				isFilled,
			})
		);
	};

	return (
		<>
			<PageHeader title={t('admin.dictionaries.organizations')} backUrl="/admin/dictionaries" />
			<Row className="mb-3">
				<Col md={12}>
					<input
						type="text"
						className={clsx('form-control', dictionaryStyles.termFilter)}
						placeholder={t('admin.users.search')}
						onKeyUp={(e): void => onSearchByTerm(e.currentTarget.value)}
					/>
				</Col>
			</Row>

			{updateInProgress && <div style={{position: 'fixed', top: '50%', left: '45%', zIndex: 5}}><Loader /></div>}

			{isOrganizationsFetching ? (
				<Loader />
			) : (
				<>
					<Row>
						<Col>
							<table className={clsx('table table-hover table-responsive-sm', dictionaryStyles.table)}>
								<thead>
									<tr>
										<th>{t('admin.organizations.name')}</th>
										<th>{t('admin.organizations.email')}</th>
										<th>{t('admin.organizations.phone')}</th>
										<th>{t('admin.organizations.uniqueNumber')}</th>
										<th>{t('admin.organizations.vatNumber')}</th>
										<th>{t('admin.organizations.regNumber')}</th>
										<th>{t('admin.organizations.hierarchyLevel')}</th>
										{/* <th>{t('isAccreditationListFilled')}</th> */}
									</tr>
								</thead>
								<tbody>
									{organizations?.items?.map((organization) => (
										<OrganizationItem
											key={organization.id}
											organization={organization}
											onChangeAccreditationListFilled={async (isFilled) =>
												await onChangeAccreditationListFilled(isFilled, organization.id)
											}
										/>
									))}
								</tbody>
							</table>
						</Col>
					</Row>
					<Row>
						<Col>
							<Paginator
								totalResults={organizations?.totalCount}
								limitPerPage={navigation?.pagingFilter.pageSize}
								currentPage={navigation?.pagingFilter.pageNumber + 1}
								onChangePage={(pageNumber): void => onChangePage(pageNumber)}
								pageNeighbours={4}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default OrganizationsPage;