import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { Col, Row } from 'reactstrap';
import { fetchContactPager } from '@store/contactsStore';
import SessionManager from '@root/SessionManager';
import { Loader } from '@components/index';
import { GetContacts } from '@models/contacts';
import ContactCard from '@scenes/accountSettings/contacts/components/ContactCard';

const ContactsPage = () => {
	const dispatch = useAppDispatch();


	const { isContactPagerFetching, contacts } = useAppSelector((x) => x.contacts);

	useEffect(() => {
		if (SessionManager.user?.organizationId == null) {
			return;
		}
		const q = new GetContacts();
		q.organizationId = SessionManager.user.organizationId;
		q.pagingFilter.pageSize = 1000;
		dispatch(fetchContactPager(q));
	}, [SessionManager.user?.organizationId]);

	return (
		<>
			{isContactPagerFetching ? (
				<Loader />
			) : (
				<Row>
					{contacts?.items?.map((x, i) => {
						return (
							<Col md={6} key={i} className={'pb-3'}>
								<ContactCard key={i} data={x} />
							</Col>
						);
					})}
				</Row>
			)}
		</>
	);
};

export default ContactsPage;