import * as React from 'react';
import styles from './AdminTopNavBlock.module.scss';

export interface IAdminTopBlockProps {
  backButtonText: string;
  onClickBackButton: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export class AdminTopNavBlock extends React.Component<IAdminTopBlockProps, {}> {

  constructor(props) {
    super(props);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return <div className={styles.adminTopBlock}>
      <a className={styles.adminNavLink} onClick={this.props.onClickBackButton}>
        <i className={styles.adminNavLinkIcon + ` icon-chevron-left`} />
        <span>{this.props.backButtonText}</span>
      </a>
    </div>;
  }
}