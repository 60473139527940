import * as React from "react";
import styles from "./Switcher.module.scss";
import { useTranslation } from "react-i18next";

type ISwitchProps = React.InputHTMLAttributes<HTMLInputElement> & {
    onTextRes: string;
    offTextRes: string;
}

const Switcher = (props: ISwitchProps): JSX.Element => {
    const { checked, onTextRes, offTextRes } = props;
    const { t } = useTranslation();

    return (
        <label className={styles.switch}>
            <input type="checkbox" {...props} />
            <span className={`${styles.slider} ${checked ? "" : styles.no}`}>
                {t(checked ? onTextRes : offTextRes)}
            </span>
        </label>
    );
};

export default Switcher;
