import styles from "./BackToViewPanel.module.scss";
import React, { PropsWithChildren } from "react";
import {useTranslation} from "react-i18next";

interface Props {
    onClickBack: () => void;
}

const BackToViewPanel: React.FC<PropsWithChildren<Props>> = ({children, onClickBack}) => {

    const {t} = useTranslation();

    return <div className={styles.breadcrumbs}>
        <a
            onClick={(e) => {
                e.preventDefault();
                onClickBack();
            }}
        >
            <i className={`icon-chevron-left`}/>
            <span>{t('awb.backToView')}</span>
        </a>
		{children}
    </div>;
}

export default BackToViewPanel;