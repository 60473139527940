/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { createAppThunk } from '@helpers/reduxHelpers';
import OrganizationService from '@services/OrganizationsService';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { OrganizationDictionaryItem, OrganizationDto, UpdateOrganizationInfo } from '@models/organizations';

export type State = {
    organizations?: IPagingWrapper<OrganizationDictionaryItem>;
    isOrganizationsFetching: boolean;
	organization?: OrganizationDto;
	isOrganizationFetching: boolean;
	updateInProgress: boolean;
};

export const getOrganizationsDictionary = createAppThunk(
    'organizations/getOrganizationsDictionary',
    async (navigation: StandardNavigation) => {
        const service = new OrganizationService();
        const { data } = await service.dictionary(navigation);
        return data;
    }
);

export const updateAccreditationListFilledFlag = createAppThunk(
	'organizations/updateAccreditationListFilledFlag',
	async (arg: {organizationId: string; isFilled: boolean}) => {
		const service = new OrganizationService();
		const { data } = await service.updateAccreditationListFilledFlag(arg.organizationId, arg.isFilled);
		return data;
	}
);

export const fetchOrganization = createAppThunk(
	'organizations/fetchOrganization',
	async (arg: {organizationId: string}) => {
		const service = new OrganizationService();
		const { data } = await service.fetchOne(arg.organizationId);
		return data;
	}
);

export const updateOrganization = createAppThunk(
	'organizations/updateOrganization',
	async (arg: UpdateOrganizationInfo) => {
		const service = new OrganizationService();
		const { data } = await service.update(arg);
		return data;
	}
);

const slice = createSlice({
    name: 'organizations',
    initialState: {
		isOrganizationFetching: false,
        isOrganizationsFetching: false,
		updateInProgress: false,
		organizations: { totalCount: 0, items: [] },
		isAccreditationListUpdating: false,
		organizationSettings: null,
		isOrganizationSettingsFetching: false
    } as State,
    reducers: {
	},
    extraReducers: (builder) => {
        builder.addCase(
            getOrganizationsDictionary.fulfilled,
            (state, action) => {
                state.isOrganizationsFetching = false;
                state.organizations = action.payload;
            }
        );
        builder.addCase(
            getOrganizationsDictionary.rejected,
            (state, action) => {
                state.isOrganizationsFetching = false;
            }
        );
		builder.addCase(getOrganizationsDictionary.pending, (state, action) => {
			state.isOrganizationsFetching = true;
		});

		builder.addCase(fetchOrganization.pending, (state, action) => {
			state.isOrganizationFetching = true;
			state.organization = null;
		});
		builder.addCase(fetchOrganization.rejected,
			(state, action) => {
				state.isOrganizationFetching = false;
				state.organization = null;
			}
		);
		builder.addCase(fetchOrganization.fulfilled,
			(state, action) => {
				state.isOrganizationFetching = false;
				state.organization = action.payload as OrganizationDto;
			}
		);

		builder.addCase(updateOrganization.pending, (state, action) => {
			state.updateInProgress = true;
		});
		builder.addCase(updateOrganization.rejected,
			(state, action) => {
				state.updateInProgress = false;
			}
		);
		builder.addCase(updateOrganization.fulfilled,
			(state, action) => {
				state.updateInProgress = false;
				state.organization = {...state.organization, ...action.meta.arg};
			}
		);

		builder.addCase(updateAccreditationListFilledFlag.pending, (state, action) => {
			state.updateInProgress = true;
		});
		builder.addCase(updateAccreditationListFilledFlag.rejected,
			(state, action) => {
				state.updateInProgress = false;
			}
		);
		builder.addCase(updateAccreditationListFilledFlag.fulfilled,
			(state, action) => {
				state.updateInProgress = false;

				const {organizationId, isFilled} = action.meta.arg;
				const org = state.organizations.items.find(x => x.id == organizationId);
				// org.isAccreditationListFilled = isFilled;
			}
		);
    },
});

export const { reducer } = slice;
export const { actions } = slice;