import * as React from 'react';
import AwbCreatePage from '@scenes/customerApplication/awb/AwbCreatePage';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';

const AwbNewForm = () => {
	const history = useHistory();
	const { parentId } = useParams<{ parentId: string }>();

	return (
		<Row className="mb-3">
			<Col md={12}>
				<AwbCreatePage
					onClickBack={() => history.push(parentId ? `/awb/edit/${parentId}` : '/awb/list')}
					onSuccessSave={() => history.push(parentId ? `/awb/edit/${parentId}` : '/awb/list')}
					customerApplication={null}
					sale={null}
					customerRequest={null}
					parentAirwaybillId={parentId}
				/>
			</Col>
		</Row>
	);
};

export default AwbNewForm;