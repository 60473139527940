import User from '@models/accounts/User';
import { localStorageService } from '@services/LocalStorageService';
import { IUser } from '@models/accounts/IUser';
import moment from 'moment';
import SettingsService from '@services/SettingsService';
import { Dictionary } from '@core/models';
import { resetStore, store } from './enhancements';
import { actions, fetchOrganizationSettings } from '@store/organizationSettingsStore';
const userStorageKey = 'USER';

export default class SessionManager {
	public static user: User;

	private static _settings: Dictionary<string> = {};
	private static _isInitialized: boolean;

	public static get isInitialized() {
		return SessionManager._isInitialized || false;
	}

	public static get settings() {
		return SessionManager._settings;
	}

	public static async initAsync() {

		SessionManager._isInitialized = true;

		const userJson = localStorageService.getItem<IUser>(userStorageKey);

		if (userJson?.token != null) {
			this.user = User.create(userJson);
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const promises: Promise<any>[] = [];

		promises.push(SessionManager.initSettingsAsync());

		if (userJson?.organizationId != null && store.getState()?.organizations?.isAccreditationListUpdating != true) {
			promises.push(store.dispatch(fetchOrganizationSettings()));
		}

		await Promise.all(promises);
	}

	public static async initSettingsAsync() {
		const { data } = await new SettingsService().getNeutralSettingsJson();
		SessionManager._settings = data;
	}

	public static async setUserAsync(json: IUser) {
		if (json === null) {
			throw Error('Json data is null.');
		}

		const prevOrganizationId = this.user?.organizationId;

		localStorageService.setItem(userStorageKey, json);
		this.user = User.create(json);

		if (prevOrganizationId != this.user.organizationId) {
			await store.dispatch(fetchOrganizationSettings());
		}
	}

	public static clearUser() {
		this.user = null;
		store.dispatch(actions.resetOrganizationSettings());
		localStorageService.removeItem(userStorageKey);
		resetStore();
	}

	public static isAuthenticated(): boolean {
		return this.user?.token != null;
	}

	public static isExpired(): boolean {
		if (this.user?.token != null) {
			const expiration = this.user?.expiration;
			if (expiration == null) {
				return true;
			}

			const nowDate = moment().utc();
			const expirationDate = moment.utc(expiration);
			return nowDate >= expirationDate;
		}

		return true;
	}

	public static getAccessToken(): string {
		return this.user?.token;
	}
}