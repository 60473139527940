import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

export interface IModalDialogProps {
	contentClassName?: string;
	children?: React.ReactNode;
	isBodyLocked?: boolean;
	show: boolean;
	onClose?: () => void;
}

export const ModalDialog: React.FC<IModalDialogProps> = (
	{
		contentClassName = '',
		children,
		isBodyLocked = false,
		show = false,
		onClose = (): void => void 0
	}) => {

	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(show);
		if (!isBodyLocked) {
			return;
		}

		const lockedClassName = styles['body-lock'];
		if (show) {
			document.body.classList.add(lockedClassName);
		} else {
			document.body.classList.remove(lockedClassName);
		}
	}, [show]);

	const cx = classNames.bind(styles);

	const dialogClasses = cx({
		'modal-overlay': true,
		'active': true
	});

	const hideDialog = (): void => {
		setIsVisible(false);
		onClose();
	};

	return isVisible && (<>
		<div className={dialogClasses} />

		<div className={classNames(styles.modal, styles['video-modal'], styles['modal-open'])} data-modal='video'>
			<button className={styles['close-modal']} onClick={(): void => hideDialog()}>
				<span className={classNames(styles['icon'], styles['icon-s'], styles['c-c single'])}>
					<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M18 6L6 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
								<path d='M6 6L18 18' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
							</svg>
						</span>
			</button>
			<div className={classNames(contentClassName, styles['modal-dialog'])}>
				<div className={styles['modal-content']}>
					{children}
				</div>
			</div>
		</div>
	</>);
};